import Vue from 'vue';
import Vuex from 'vuex';

import { Player } from '@/assets/js/world/models/Player';
import { initProvider, IRestApiInitOptions, IRestApiProvider } from '@/services/RestApiProviderFactory';

Vue.use(Vuex);

export interface HeaderStatus {
  isCloseButtonVisible: boolean;
  isNotificationButtonVisible: boolean;
  isStatsVisible: boolean;
}

export default new Vuex.Store({
  state: {
    dataProvider: null as unknown as IRestApiProvider,
    isMobile: true as boolean,
    isOffline: false as boolean,
    isLogged: false as boolean,
    player: null as unknown as Player,
    audio: true as boolean,
    header: {
      isCloseButtonVisible: true,
      isNotificationButtonVisible: true,
      isStatsVisible: true,
    } as HeaderStatus,
    lastNotification: 0 as number,
    lastReadNotification: 0 as number,
    campaignToOpen: 0 as number,
  },
  mutations: {
    INIT_PROVIDER(state, options: IRestApiInitOptions) {
      if (state.dataProvider != null) return;

      state.dataProvider = initProvider(navigator, options.offuscamento, options.criptaggio);
    },
    MOBILE(state, newStatus: boolean) {
      if (state.isMobile != newStatus) {
        state.isMobile = newStatus;
      }
    },
    OFFLINE(state, offline: boolean) {
      if (state.isOffline != offline) {
        state.isOffline = offline;
      }
    },
    LOGIN(state, player: Player) {
      state.isLogged = true;
      state.player = player;
      // console.log('LOGIN', state.player.credits, state.player.experience, state.player.stars);
      state.lastNotification = player.lastNotification;
      state.lastReadNotification = player.lastReadNotification;
    },
    LOGOUT(state) {
      state.isLogged = false;
    },
    UPDATE_PROFILE_IMAGE(state, imageUrl: string) {
      state.player.imageFace = imageUrl;
    },
    SET_PLAYER_SEX(state, sex: string) {
      state.player.sex = sex;
    },
    SET_CREDITS(state, value: number) {
      state.player.credits = value;
    },
    SET_EXPERIENCE(state, value: number) {
      state.player.experience = value;
    },
    SET_STARS(state, value: number) {
      state.player.stars = value;
    },
    SET_HEADER_CLOSE_BUTTON_VISIBILITY(state, value: boolean) {
      state.header.isCloseButtonVisible = value;
    },
    SET_HEADER_NOTIFICATION_BUTTON_VISIBILITY(state, value: boolean) {
      state.header.isNotificationButtonVisible = value;
    },
    SET_HEADER_STATS_VISIBILITY(state, value: boolean) {
      state.header.isStatsVisible = value;
    },
    ADD_QUATTRO_RAISED_EVENT(state, eventKey: string) {
      state.player.addQuattroTriggeredEvent(eventKey);
    },
    SET_AUDIO_STATE(state, value: boolean) {
      state.audio = value;
    },
    SET_LAST_NOTIFICATION_READ(state, value: number) {
      state.lastReadNotification = value;
    },
    SET_LAST_NOTIFICATION(state, value: number) {
      state.lastNotification = value;
    },
    SET_CAMPAIGN_TO_OPEN(state, value: number) {
      state.campaignToOpen = value;
    },
  },
  actions: {
    onInitProvider({commit}, options: IRestApiInitOptions) {
      commit('INIT_PROVIDER', options);
    },
    onWindowResize({commit}, isMobile: boolean) {
      commit('MOBILE', isMobile);
    },
    onNetworkStatusChange({commit}, offline: boolean) {
      commit('OFFLINE', offline);
    },
    onLoginSuccessful({commit}, player: Player) {
      commit('LOGIN', player);
    },
    onLogout({commit}) {
      commit('LOGOUT');
    },
    onProfileImageFaceUpdate({commit}, imageUrl: string) {
      commit('UPDATE_PROFILE_IMAGE', imageUrl);
    },
    onSetPlayerSex({commit}, sex: string) {
      commit('SET_PLAYER_SEX', sex);
    },
    onSetCredits({commit}, value: number) {
      commit('SET_CREDITS', value);
    },
    onSetExperience({commit}, value: number) {
      commit('SET_EXPERIENCE', value);
    },
    onSetStars({commit}, value: number) {
      commit('SET_STARS', value);
    },
    onSetHeaderCloseButtonVisibility({commit}, value: boolean) {
      commit('SET_HEADER_CLOSE_BUTTON_VISIBILITY', value);
    },
    onSetHeaderNotificationButtonVisibility({commit}, value: boolean) {
      commit('SET_HEADER_NOTIFICATION_BUTTON_VISIBILITY', value);
    },
    onSetHeaderStatsVisibility({commit}, value: boolean) {
      commit('SET_HEADER_STATS_VISIBILITY', value);
    },
    onQuattroRaisedEvent({commit}, eventKey: string) {
      commit('ADD_QUATTRO_RAISED_EVENT', eventKey);
    },
    onAudioToggle({commit}, value: boolean) {
      commit('SET_AUDIO_STATE', value);
    },
    onSetLastNotificationsRead({commit}, value: number) {
      commit('SET_LAST_NOTIFICATION_READ', value);
    },
    onSetLastNotification({commit}, value: number) {
      commit('SET_LAST_NOTIFICATION', value);
    },
    onSetCampaignToOpen({commit}, value: number) {
      commit('SET_CAMPAIGN_TO_OPEN', value);
    },
  },
  getters: {
    dataProvider: state => state.dataProvider != null ? state.dataProvider : null,
    isMobile: state => (state.isMobile),
    isOffline: state => (state.isOffline),
    credits: state => state.player != null ? state.player.credits : 0,
    experience: state => state.player != null ? state.player.experience : 0,
    stars: state => state.player != null ? state.player.stars : 0,
    player: state => (state.isLogged ? state.player : null),
    header: state => (state.header),
    audio: state => (state.audio),
    quattroRaisedEvents: state => state.player != null ? state.player.quattroTriggeredEvents : [],
    avatarBackgroundImageStyle: state => {
      if (!state.isLogged || state.player == null) {
        return `background-image:url('` + require('@/assets/img/new_alpha/characters/user.png') + `')`;
      }
      if (state.player.imageFace == null) {
        return `background-image:url('` + require('@/assets/img/new_alpha/characters/user.png') + `')`;
      }
      return `background-image:url('${state.player.imageFace}')`;
    },
    lastReadNotification: state => state.lastReadNotification != null ? state.lastReadNotification : 0,
    lastNotification: state => state.lastNotification != null ? state.lastNotification : 0,
    notificationCount: state => state.lastNotification != null && state.lastReadNotification != null ? state.lastNotification - state.lastReadNotification : 0,
    campaignToOpen: state => state.campaignToOpen > 0 ? state.campaignToOpen : null,
  }
});
