import GameDisplay from './GameDisplay'
import loadResources from './ResourceLoader'
import { QuestStatus } from '@/assets/js/world/models/Enums';
import { GlobalEventBus } from '@/services/GlobalEventBus';
import { GameStatus } from './models/GameStatus';

export const GameCore = {
  dev: false as boolean,
  display: null as any,
  displayOptions: null as any,
  gameStatus: null as unknown as GameStatus,
  callbacks: null as any,

  init(options: any, gameStatus: GameStatus, callbacks: any) {
    this.gameStatus = gameStatus;
    this.callbacks = callbacks;

		const { width, height } = options
    this.display = new GameDisplay(width, height)
    
    const onceLoaded = () => {
      document.getElementById('game_container')!.appendChild(this.display.getContainer());
    }
    
    this.display.beforeInit()

    const resourceLoaderCallbacks = [
      (resources: any) => this.display.loadAssets(resources, onceLoaded)
		]
    loadResources(resourceLoaderCallbacks);

    this.gameStatus.questCoreCompletedEmitter.subscribe(quest => {

      // setTimeout() aggiunto perché ci potrebbero chiamare la dispose() prima di aspettare la risposta delle API setXxxxx.
      // PROBLEMA FIXATO LATO CORE il 23/09: ci teniamo cmq 250ms di tempo... non si sa mai
      setTimeout(() => {
        if (quest.progress.status === QuestStatus.Failed && quest.locationKey.includes("|final")) {
          GlobalEventBus.$emit('quattro', { eventKey: 'final-quest-failed' });
        } else if (quest.progress.status === QuestStatus.Failed && !quest.locationKey.includes("|final")) {
          GlobalEventBus.$emit('quattro', { eventKey: 'first-quest-failed' });
          GlobalEventBus.$emit('first-quest-fallita'); // questo evento è per il tutorial, quando fallisci la prima quest e hai solo un pin visibile
        } else if (quest.progress.status === QuestStatus.Passed && quest.locationKey.includes("|final")) {
          GlobalEventBus.$emit('quattro', { eventKey: `final-quest-success-${quest.districtKey}` });
          if (quest.districtKey == 'finanziario') {
            GlobalEventBus.$emit('end-game');
          }
          if (gameStatus.getNextDistrict(quest.districtKey) != null) {
            GlobalEventBus.$emit('new-district-available', gameStatus.getNextDistrict(quest.districtKey)!.key);
          } else {
            GlobalEventBus.$emit('new-district-available', null);
          }
        } else if (quest.progress.status === QuestStatus.Passed && !quest.locationKey.includes("|final")) {
          GlobalEventBus.$emit('quattro', { eventKey: 'first-quest-success' });
        }

        this.callbacks.cbCloseQuest();
        this.display.zoomOut();
      }, 250);      
      
    });
  },

  updateGameStatus(value: GameStatus) {
    this.gameStatus = value;
  },
}