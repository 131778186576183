



































import {
  Component,
  Vue
} from 'vue-property-decorator';

@Component
export default class AccountStepComponent extends Vue {
  get clientVersion(): string {
    return process.env.VUE_APP_VERSION || "N.D.";
  }

  get lastLogin(): string {
    const utcDateLastLogin = this.$store.getters.player.lastLogin;

    if (utcDateLastLogin == null) return 'sconosciuto';

    try {
      const date = new Date(Date.parse(utcDateLastLogin));
      const stringIt = date.toLocaleDateString('it-IT', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
      
      return stringIt.split(' ').map(x => x[0].toUpperCase() + x.substring(1)).join(' ');
    } catch {
      return 'Sconosciuto';
    }
  }

  logout() {
    this.$emit('logout');
  }
}
