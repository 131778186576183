











import { Component } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';

@Component
export default class LoginErrorComponent extends BaseComponent {
  goToLoginPlatform() {
    location.replace(process.env.VUE_APP_LOGIN_PLATFORM_URL || "https://uclearningplatform.isapiens.net/login/");
  }
}
