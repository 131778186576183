var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "multiplayer multiplayer__multiplayer-container",
      attrs: { id: "multiplayer" }
    },
    [
      !_vm.showCampaign
        ? _c("div", {}, [
            _c("div", { staticClass: "multiplayer__wrapper" }, [
              _vm._m(0),
              _c("div", { staticClass: "multiplayer__heading" }, [
                _vm.profile
                  ? _c("div", { staticClass: "multiplayer__user" }, [
                      _c("div", {
                        staticClass: "user__pic",
                        style: _vm.avatar
                      }),
                      _c("div", { staticClass: "user__name" }, [
                        _c("b", [_vm._v(_vm._s(_vm.profile.username))]),
                        _vm._v(" "),
                        _vm.profile.isDigitalAmbassador
                          ? _c("div", {
                              staticClass: "ranking__card--ambassador",
                              style: {
                                "background-image":
                                  "url(images/ranking/digitala.png)"
                              }
                            })
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "multiplayer__list" }, [
              _vm.campaigns.length > 0
                ? _c(
                    "ul",
                    { staticClass: "multiplayer__items" },
                    _vm._l(_vm.campaigns, function(c) {
                      return _c(
                        "li",
                        {
                          key: c.id,
                          staticClass: "multiplayer__item",
                          on: {
                            click: function($event) {
                              return _vm.onOpenCampaign(c)
                            }
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "item__pic",
                            style: {
                              "background-image": "url(" + c.image + ")"
                            }
                          }),
                          _c("div", { staticClass: "item__text" }, [
                            _c("div", { staticClass: "item__name" }, [
                              _vm._v(_vm._s(c.name))
                            ]),
                            _c("div", { staticClass: "item__description" }, [
                              _vm._v(_vm._s(c.description))
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", [
                    _c("div", { staticClass: "multiplayer__empty" }, [
                      _vm._v(" Al momento non ci sono campagne abilitate ")
                    ])
                  ])
            ])
          ])
        : _vm._e(),
      _vm.showCampaign
        ? _c("CampaignComponent", { attrs: { campaignUrl: _vm.campaignUrl } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "multiplayer__title" }, [
      _c("div", [_c("b", [_vm._v("ARENA")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }