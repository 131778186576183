/* eslint-disable @typescript-eslint/no-explicit-any */

import lodash from 'lodash';

import { BlockType } from './Enums';
import { Coordinates } from './World';

export enum BlockClass {
  Edificabile = 0,
  Bonificabile = 1,
  Standard = 2,
}

export class BlockDefinition {
  readonly type: BlockType;
  readonly classe: BlockClass;
  readonly upgradeFrom: BlockType | null = null;
  readonly upgradeTo: BlockType[] | null = null;
  readonly price: number;
  readonly sprite: string;
  readonly name: string;
  readonly size: Coordinates;
  readonly center: Coordinates;
  
  upgradesFrom: BlockDefinition | null = null;
  upgradesTo: BlockDefinition[] = [];

  constructor(data: any) {
    this.type = data.type;
    this.classe = data.classe;
    this.upgradeFrom = data.upgradeFrom;
    this.upgradeTo = data.upgradeTo;
    this.price = data.price;
    this.sprite = data.sprite;
    this.name = data.name;
    this.size = {
      x: data.size.x,
      y: data.size.y,
    };
    this.center = {
      x: data.center.x,
      y: data.center.y,
    };

    this.upgradesFrom = null;
    this.upgradesTo = [];
  }

  get completionPercentage(): number {
 
    if (this.classe !== BlockClass.Standard) return 0;
    else if (this.upgradesTo.length === 0) return 100;

    const maxPathLength = this.getMaxLengthPathFromRoot(this.rootDefinition);
    // console.log('maxPathLength', maxPathLength, 'completion', this.upgradeStep  );
    return Math.round(this.upgradeStep / maxPathLength * 100);
  }
  
  private get rootDefinition(): BlockDefinition {
    return (this.upgradesFrom == null) ? this : this.upgradesFrom?.rootDefinition;
  }

  get upgradeStep(): number {
    return (this.upgradesFrom) ? this.upgradesFrom.upgradeStep + 1 : 0;
  }

  getMaxLengthPathFromRoot(currentStep: BlockDefinition): number {
    if (currentStep.upgradesTo.length === 0) return 0;
    
    const possiblePathValues = currentStep.upgradesTo.map(element => 1 + this.getMaxLengthPathFromRoot(element));
    return lodash.max(possiblePathValues)!;
  }
}
