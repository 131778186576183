var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c("div", { staticClass: "faq" }, [
          _c("div", { staticClass: "header faq__header" }, [
            _c(
              "div",
              { staticClass: "header__wrapper header__wrapper--right" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sound",
                        rawName: "v-sound",
                        value: "tick",
                        expression: "'tick'"
                      }
                    ],
                    staticClass: "menu_button",
                    on: {
                      click: function($event) {
                        return _vm.onDispose()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "header__title" }, [_vm._v(" Faq ")])
          ]),
          _c(
            "div",
            { staticClass: "faq__wrapper" },
            [
              _c("div", {
                staticClass: "faq__intro",
                domProps: { innerHTML: _vm._s(_vm.intro) }
              }),
              _c(
                "accordion",
                { staticClass: "faq__accordion" },
                _vm._l(_vm.faqs, function(faq, index) {
                  return _c(
                    "accordion-item",
                    { key: "faq-" + index, staticClass: "faq__accordion-item" },
                    [
                      _c("template", { slot: "accordion-trigger" }, [
                        _c("div", { staticClass: "faq__accordion-header" }, [
                          _c("div", { staticClass: "faq__accordion--title" }, [
                            _vm._v(_vm._s(faq.title))
                          ]),
                          _c("div", { staticClass: "faq__accordion--button" }, [
                            _c("i", { staticClass: "button" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    width: "24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M0 0h24v24H0V0z",
                                      fill: "none"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("template", { slot: "accordion-content" }, [
                        _c("div", { staticClass: "faq__accordion-body" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(faq.content) }
                          })
                        ])
                      ])
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }