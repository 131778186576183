// @ts-ignore
import stringifyDet from 'json-stringify-deterministic';
import lodash from 'lodash';

export interface ObfuscationOutput {
  payload: string;
  iv: string;
  key: string;
}

export default class CryptoUtils {
  static crypto = (window.crypto || (window as any).msCrypto);
  static cryptoSubtle = CryptoUtils.crypto.subtle;
  static cryptoGenerate = CryptoUtils.cryptoSubtle.generateKey.bind(CryptoUtils.cryptoSubtle);
  static cryptoImportKey = CryptoUtils.cryptoSubtle.importKey.bind(CryptoUtils.cryptoSubtle);
  static cryptoExportKey = CryptoUtils.cryptoSubtle.exportKey.bind(CryptoUtils.cryptoSubtle);
  static cryptoEncrypt = CryptoUtils.cryptoSubtle.encrypt.bind(CryptoUtils.cryptoSubtle);
  static cryptoDecrypt = CryptoUtils.cryptoSubtle.decrypt.bind(CryptoUtils.cryptoSubtle);
  static cryptoSign = CryptoUtils.cryptoSubtle.sign.bind(CryptoUtils.cryptoSubtle);

  static deterministicStringify(input: any) {
    return lodash.isString(input) ? stringifyDet(JSON.parse(input)) : stringifyDet(input);
  }

  static base64StringToArrayBuffer(base64: string): ArrayBuffer {
    const buf = new ArrayBuffer(base64.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = base64.length; i < strLen; i++) {
      bufView[i] = base64.charCodeAt(i);
    }
    return buf;
  }

  static arrayBufferToBase64String(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static extractPemKey(pem: string) {
    const lines = pem.split('\n');
    let result = '';
    for (let i = 0; i < lines.length; i++){
      if (lines[i].trim().length > 0 &&
        lines[i].indexOf('-BEGIN RSA PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-BEGIN RSA PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-BEGIN PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-BEGIN PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-END RSA PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-END RSA PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-END PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-END PUBLIC KEY-') < 0) {
        result += lines[i].trim();
      }
    }
    return result;
  }

  static async generateObfuscationKey(): Promise<CryptoKey> {
    const key = await CryptoUtils.cryptoGenerate(
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
    return key;
  }

  static async importObfuscationKey(input: string): Promise<CryptoKey> {
    const binaryString = window.atob(input);
    const binaryKey = this.base64StringToArrayBuffer(binaryString);

    const key = await CryptoUtils.cryptoImportKey(
      "raw",
      binaryKey,
      {
        name: "AES-CBC",
      },
      false,
      ["decrypt"]
    );
    return key;
  }

  /**
   * @param pem Entire PEM key with "BEGIN" header and "END" footer rows
   */
  static async importPublicRsaKey(pem: string): Promise<CryptoKey> {
    const pemContents = this.extractPemKey(pem);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = this.base64StringToArrayBuffer(binaryDerString);

    try {
      return await CryptoUtils.cryptoImportKey(
        "spki",
        binaryDer,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        false,
        ["encrypt"]
      );

      // await this.obfuscateString("ciao");
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  /**
   * @param pem Entire PEM key with "BEGIN" header and "END" footer rows
   */
  static async importPrivateRsaKey(pem: string): Promise<CryptoKey> {
    const pemContents = this.extractPemKey(pem);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = this.base64StringToArrayBuffer(binaryDerString);

    try {
      return await CryptoUtils.cryptoImportKey(
        "pkcs8",
        binaryDer,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        false,
        ["decrypt"]
      );
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  static async exportCryptoKey(key: CryptoKey): Promise<ArrayBuffer> {
    const exported = await CryptoUtils.cryptoExportKey(
      "raw",
      key
    );
    return exported;
  }

  /**
   * @param input string to obfuscate
   * @param cryptoKeyPublicRsa public part of the key, used to encrypt input
   */
  static async obfuscateString(input: string, cryptoKeyPublicRsa: CryptoKey): Promise<ObfuscationOutput> {
    const enc = new TextEncoder();
    const encoded = enc.encode(input);
    
    const key = await CryptoUtils.generateObfuscationKey();
    const exportedKey = await CryptoUtils.exportCryptoKey(key);    
    const iv = crypto.getRandomValues(new Uint8Array(16));

    const obfuscatedInput = await CryptoUtils.cryptoEncrypt(
      {
        name: "AES-CBC",
        iv,
        // @ts-ignore
        hash: { name: "SHA-256" }
      },
      key,
      encoded
    );
    const exportedCryptedKey = await CryptoUtils.cryptoEncrypt({
      name: "RSA-OAEP",
      // @ts-ignore
      hash: { name: "SHA-256" }
    }, cryptoKeyPublicRsa, exportedKey);

    return {
      payload: CryptoUtils.arrayBufferToBase64String(obfuscatedInput),
      iv: CryptoUtils.arrayBufferToBase64String(iv),
      key: CryptoUtils.arrayBufferToBase64String(exportedCryptedKey),
    };
  }

  static async sign(input: string, key: string) {
    const binaryDer = this.base64StringToArrayBuffer(key);
    
    const signingKey = await CryptoUtils.cryptoImportKey(
      'raw',
      binaryDer,
      {
        name: 'HMAC',
        hash: 'SHA-256'
      },
      false,
      ['sign']
    );

    const enc = new TextEncoder();
    const encoded = enc.encode(input);
    
    const ciphertext = await CryptoUtils.cryptoSign({
      name: "HMAC",
      // @ts-ignore
      hash: { name: "SHA-256" }
    }, signingKey, encoded);

    const str = this.arrayBufferToBase64String(ciphertext);
    // console.log("encodeString", input, str);
    return str;
  }

  static async decryptRsa(input: string, privateKeyRsa: CryptoKey): Promise<string> {
    try {
      const inputArrayBuffer = this.base64StringToArrayBuffer(window.atob(input));
      const decrypted = await CryptoUtils.cryptoDecrypt(
        {
          name: "RSA-OAEP",
          // @ts-ignore
          hash: { name: "SHA-256" },
        },
        privateKeyRsa,
        inputArrayBuffer,
      );
      
      return window.atob(this.arrayBufferToBase64String(decrypted));
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  static async decryptAes(input: string, aesKey: string, iv: string): Promise<string> {
    try {
      const inputArrayBuffer = this.base64StringToArrayBuffer(window.atob(input));
      const inputIV = this.base64StringToArrayBuffer(window.atob(iv));
      const aesCryptoKey = await this.importObfuscationKey(window.btoa(aesKey));
      
      const decrypted = await CryptoUtils.cryptoDecrypt(
        {
          name: "AES-CBC",
          iv: inputIV,
        },
        aesCryptoKey,
        inputArrayBuffer,
      );
      
      return new TextDecoder("utf-8").decode(decrypted);
      // return window.atob(this.arrayBufferToBase64String(decrypted));
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
}