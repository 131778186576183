import Vue from 'vue';
import Component from 'vue-class-component';
import { IRestApiProvider } from '@/services/RestApiProviderFactory';

@Component
export default class BaseVue extends Vue {
  get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  get isOffline(): boolean {
    return this.$store.getters.isOffline;
  }
  get dataProvider(): IRestApiProvider {
    return this.$store.getters.dataProvider;
  }

  created() {
    const offuscamento = this.$route.query.o == null || (this.$route.query.o != "false" && this.$route.query.o != "0");
    const criptaggio = this.$route.query.s == null || (this.$route.query.s != "false" && this.$route.query.s != "0");

    this.$store.dispatch('onInitProvider', {
      offuscamento,
      criptaggio
    });
  }
}