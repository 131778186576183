var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lobby lobby__lobby-container lobby__body--avatar" },
    [
      _c("div", { staticClass: "lobby__heading" }, [
        _c(
          "ul",
          { staticClass: "avatar__editor" },
          _vm._l(_vm.assetsToSuperimpose, function(asset, idx) {
            return _c("li", { key: "sel-" + idx }, [
              _c("img", {
                staticClass: "avatar_image",
                staticStyle: { position: "absolute" },
                attrs: { src: asset.url, width: "200", height: "200" }
              })
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "user__actions" },
          [
            _c("AsyncButtonComponent", {
              attrs: {
                classes: "user__cta",
                isSpinning: _vm.isSaving,
                text: "Conferma"
              },
              on: {
                click: function($event) {
                  return _vm.saveConfiguration()
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lobby__body" }, [
        _c("div", { staticClass: "lobby__body--bar" }, [
          _c(
            "ul",
            { ref: "navigation", staticClass: "avatar_types" },
            _vm._l(_vm.types, function(type, index) {
              return _c(
                "li",
                {
                  key: "typ-" + type.type,
                  staticClass: "avatar_type",
                  on: {
                    click: function($event) {
                      return _vm.onTypeSelected(type, index)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(type.displayType) + " ")]
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "lobby__body--content" }, [
          _c(
            "ul",
            { staticClass: "avatar__assets" },
            _vm._l(_vm.sortedAssetsToShow, function(asset) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "tick",
                      expression: "'tick'"
                    }
                  ],
                  key: "opt-" + asset.asset.id,
                  staticClass: "avatar_asset",
                  class: [
                    "avatar_asset--" + asset.asset.type,
                    { "avatar_asset--active": asset.isSelected },
                    { "avatar_asset--non_selectable": !asset.isSelectable }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.onAssetSelected(asset)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "asset__wrapper" }, [
                    _c("img", {
                      attrs: {
                        src: asset.asset.url,
                        width: "200",
                        height: "200"
                      }
                    })
                  ])
                ]
              )
            }),
            0
          )
        ]),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lobby_canvas" }, [
      _c("canvas", {
        attrs: { id: "lobbyCanvasFull", width: "1024", height: "1024" }
      }),
      _c("canvas", {
        attrs: { id: "lobbyCanvasFace", width: "640", height: "640" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }