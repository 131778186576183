import { JsApiError, JsApiErrorStatusCode } from './CustomErrors';
import { CampaignObject } from '@/assets/js/campaign/models/Campaign';
import { AlertEventArgs } from './GlobalEventBus';

export default class CampaignCoreApi {
  private readonly campaignId: number;
  private readonly token: string;

  constructor({ campaignId, token }: { campaignId: number; token: string }) {
    if (campaignId == null || token == null)
      throw new JsApiError(JsApiErrorStatusCode.MissingParameters, "CampaignCoreApi ctor", "Mandatory parameters are missing");

    console.log('CampaignCoreApi ctor. campaignId passed: ' + campaignId);
    this.campaignId = campaignId;
    this.token = token;
  }

  async init(): Promise<CampaignObject> {
    try {
      const result: CampaignObject = await (document.getElementById('multiplayer') as any).__vue__.initCampaign(this.campaignId);
      if (result != null) {
        return result;
      }
      
      throw new JsApiError(JsApiErrorStatusCode.CampaignNotFound, "CampaignCoreApi.init", `Campaign ${this.campaignId} not found, maybe there is a campaignId misuse in quest configuration`);
    } catch (e) {
      console.error(e);
      throw new JsApiError(JsApiErrorStatusCode.UnexpectedError, "CampaignCoreApi.init", "Unexpected error", e);
    }
  }

  showProfile(userId: number): void {
    (document.getElementById('multiplayer') as any).__vue__.showProfile(userId);
  }

  updateUsersStatus(): void {
    (document.getElementById('multiplayer') as any).__vue__.updateUsersStatus();
  }

  dispose(target: string): void {
    (document.getElementById('multiplayer') as any).__vue__.disposeCampaign(target);
  }

  showAlert(args: AlertEventArgs): void {
    (document.getElementById('multiplayer') as any).__vue__.showAlert(args);
  }
}
