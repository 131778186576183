import axios, { AxiosError, AxiosInstance } from 'axios';
import PQueue from 'p-queue';

import { IRestApiError } from './RestApiProviderFactory';
import CryptoUtils, { ObfuscationOutput } from './Crypto';

export default class BaseRestApiProvider {
  protected doCryptation = false;
  protected doSignature = false;
  protected axiosInstance: AxiosInstance;
  protected axiosAntiCheatingInstance: AxiosInstance;
  protected requestQueue: PQueue;
  private cryptoKeyPublicRsa: CryptoKey;
  private nonce: number;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL
    });
    this.axiosAntiCheatingInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL
    });
    this.requestQueue = new PQueue({
      concurrency: 1,
    });
  }

  importNonce(value: number) {
    this.nonce = value;
  }

  private incrementNonce() {
    this.nonce += 1;
    this.nonce = this.nonce % Math.pow(2, 32);
    // console.log('Updated nonce, new value: ', this.nonce);
  }

  async importPublicRsaKey(pem: string) {
    try {
      this.cryptoKeyPublicRsa = await CryptoUtils.importPublicRsaKey(pem);

      // await this.obfuscateString("ciao");
    } catch (error) {
      console.log(error)
    }
  }

  async obfuscateString(input: string): Promise<ObfuscationOutput> {
    const obfuscatedString = await CryptoUtils.obfuscateString(input, this.cryptoKeyPublicRsa);
    return obfuscatedString;
  }

  /**
   * L'offuscamento è solo outgoing, infatti io devo solamente encodare i messaggi da mandare a server con la chiave pubblica RSA
   * che ho ricevuto in fase di login. Il client di per sé non decoda mai i messaggi, non avendo la chiave privata non può nemmeno farlo.
   * 
   * NB l'ordine di esecuzione dei middleware axios è inversa all'ordine in cui vengono aggiunti: https://github.com/axios/axios/issues/1663
   */
  async addObfuscationMiddleware() {
    // console.log('addObfuscationMiddleware');
    this.axiosAntiCheatingInstance.interceptors.request.use(async (config) => {
      // console.log('Request obfuscation middleware: obfuscating', config.data);
      config.data = await this.obfuscateString(JSON.stringify(config.data));
      return config;
    }, (error) => {
      console.warn("Request obfuscation middleware: error");
      console.error(error);
      return Promise.reject(error);
    });
  }

  /**
   * La firma è implementata outgoing e permette di annullare il tentativo di hacking di molteplici richieste identiche.
   * Non ha niente a che vedere con la chiave RSA, ma utilizza un one-time token, il nonce (numero che si deve incrementare ad ogni richiesta)
   * La firma è un HASH, che viene ripetuto e controllato lato server (i nonce client e server devono combaciare).
   * 
   * NB l'ordine di esecuzione dei middleware axios è inversa all'ordine in cui vengono aggiunti: https://github.com/axios/axios/issues/1663
   */
  addOutgoingSignatureMiddleware() {
    // console.log('addOutgoingSignatureMiddleware');
    this.axiosAntiCheatingInstance.interceptors.request.use(async (config) => {
      const stringifiedBody = CryptoUtils.deterministicStringify(config.data);
      // console.log('Request signature middleware: signing payload with this nonce', stringifiedBody, this.nonce);
      const signature = await CryptoUtils.sign(stringifiedBody, this.nonce.toString());

      config.headers.Authorization = `Signature ${signature}`;
      return config;
    }, (error) => {
      console.warn("Request signature middleware: error");
      console.error(error);
      return Promise.reject(error);
    });
  }

  /**
   * Middleware per gestire eventuale risposta 422 da server che non deve incrementare il nonce
   */
  addIncomingSignatureMiddleware() {
    // console.log('addIncomingSignatureMiddleware');
    this.axiosAntiCheatingInstance.interceptors.response.use((response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // console.log('Response signature middleware: successful status code', response);
      this.incrementNonce();
      return response;
    }, (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      console.log("Response signature middleware: error", error)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status != 422) {
          this.incrementNonce();
        }
        // console.log(error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
      }
      return Promise.reject(error);
    });
  }

  addSignatureMiddleware() {
    this.addOutgoingSignatureMiddleware();
    this.addIncomingSignatureMiddleware();
  }

  composeCustomError(error: any): IRestApiError {
    if (error.isAxiosError != null) {
      // duck type check, se esiste quel campo è perché l'input è di tipo AxiosError
      const e: AxiosError = (error as AxiosError);
      return {
        isClientError: false,
        error: error,
        axiosError: {
          errorJson: e.toJSON(),
          responseExists: e.response != null,
          responseErrorCode: e.response != null ? e.response.status : undefined,
        },
      };
    } else {
      return {
        isClientError: true,
        error: error,
      }
    }
  }
}