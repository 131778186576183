var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c("div", { staticClass: "regolamento" }, [
          _c("div", { staticClass: "header regolamento__header" }, [
            _c(
              "div",
              { staticClass: "header__wrapper header__wrapper--right" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sound",
                        rawName: "v-sound",
                        value: "selezione_icona",
                        expression: "'selezione_icona'"
                      }
                    ],
                    staticClass: "menu_button",
                    on: {
                      click: function($event) {
                        return _vm.onDispose()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "header__title" }, [
              _vm._v(" Regolamento ")
            ])
          ]),
          _vm.visible
            ? _c("div", { staticClass: "regolamento__wrapper" }, [
                _c("div", {
                  staticClass: "regolamento__scroller",
                  domProps: { innerHTML: _vm._s(_vm.content) }
                })
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }