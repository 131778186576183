




























































import moment from 'moment';

import {
  Component,
} from "vue-property-decorator";
import {
  BaseNotification,
  ChallengeNotification,
} from "@/assets/js/profile/models/Notifications";
import {
  GlobalEventBus
} from '@/services/GlobalEventBus';
import BaseComponent from './BaseComponent';

interface NotificationModel {
  notification: BaseNotification,
  wasNeverReadBefore: boolean,
  isChallengeAcceptable: boolean,
}

@Component
export default class NotificationsComponent extends BaseComponent {
  pageLastNotificationIdShown = 0;
  pageSize = 10;
  notifications: NotificationModel[] = [];
  visible = false;
  startingNotificationIdToHighlight: number;

  get isEmpty(): boolean {
    return this.notifications != null && this.notifications.length === 0;
  }

  created() {
    GlobalEventBus.$on('notifications', async () => {
      GlobalEventBus.$emit('notifications-raised');
      GlobalEventBus.$emit('notificator', { polling: false });

      this.startingNotificationIdToHighlight = this.$store.getters.lastReadNotification;
      // console.log('startingNotificationIdToHighlight', this.startingNotificationIdToHighlight);
      
      this.pageLastNotificationIdShown = this.$store.getters.lastNotification;
      // console.log('pageLastNotificationIdShown', this.pageLastNotificationIdShown);
      
      this.visible = true;

      this.notifications = [];
      await this.fetchData();

      this.$store.dispatch('onSetLastNotificationsRead', this.$store.getters.lastNotification);

      if (this.$store.getters.lastNotification > 0)
        await this.wrapApiCall(() => this.dataProvider.setLastNotificationViewed(this.$store.getters.lastNotification), "scrittura ultima notifica letta");
    });
  }

  notificationImgStyle(notification: Notification) {
    return {
      'background-image': `url(${notification.image})`
    };
  }

  infiniteHandler(state: any) {
    // console.log('infiniteHandler');
    this.fetchData()
      .then(hasResults => {
        hasResults ? state.loaded() : state.complete();
      });
  }

  async fetchData(): Promise<boolean> {
    // console.log('fetchData');
    const notifications = 
      await this.wrapApiCall(() => this.dataProvider.getNotificationsBackwardPaged(this.pageLastNotificationIdShown, this.pageSize), "lettura notifiche") as BaseNotification[];
    if (notifications == null || notifications.length === 0) return false;

    let retValue = false;
    notifications.forEach(x => {
      if (this.notifications.find(y => y.notification.id === x.id) == null) {
        retValue = true;
        this.notifications.push({
          notification: x,
          wasNeverReadBefore: x.id > this.startingNotificationIdToHighlight,
          isChallengeAcceptable: x instanceof ChallengeNotification && moment().isBefore(moment(x.challengeTimeout)),
        });
      }
    });
    this.pageLastNotificationIdShown = notifications[notifications.length - 1].id;
    return retValue;
  }

  onNotificationClicked(notification: BaseNotification) {
    // console.log('onNotificationClicked', notification);

    if (notification instanceof ChallengeNotification) {
      this.$emit('show-multiplayer', notification.campaignId);
      this.onDispose();
    }
  }

  onDispose() {
    GlobalEventBus.$emit('notificator', { polling: true });
    this.visible = false;
    GlobalEventBus.$emit('pause-viewport', false);
  }
}
