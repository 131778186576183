























import { Component } from "vue-property-decorator";

import { GlobalEventBus, NoticeEventArgs } from "@/services/GlobalEventBus";
import BaseComponent from './BaseComponent';
import {
  EndingContents
} from "@/assets/js/RestApiPayloads";
import videojs, { VideoJsPlayer } from "video.js";

@Component
export default class NoticeComponent extends BaseComponent {
  queue: string[] = [];
  showVideo: boolean;
  // @ts-ignore
  endingContents: EndingContents = null;
  // @ts-ignore
  videoPlayer: VideoJsPlayer = null;
  hasVideoEnded = false;

  get currentNotice(): string {
    if (this.queue != null && this.queue.length > 0)
      return this.queue[0];

    return '';
  }

  get visible(): boolean {
    return this.queue != null && this.queue.length > 0;
  }

  loadVideo(): any {
    this.dataProvider
      .getEndingContents()
      .then((contents) => {
        this.endingContents = contents;

        // console.log(contents);

        this.videoPlayer = videojs(document.getElementById('videoPlayerEnd') as HTMLElement, {
          autoplay: true,
          controls: false,
          sources: [
            {
              src: this.endingContents.video.mp4,
              type: "video/mp4",
            },
            {
              src: this.endingContents.video.m3u8,
              type: "application/x-mpegURL",
            },
          ],
        });
        this.videoPlayer.on('ended', () => {
          this.hasVideoEnded = true;
        });
        let vid = this.videoPlayer;
        this.videoPlayer.on('error', function(e) {
          e.stopImmediatePropagation();
          var error = vid.error();
          vid.error(null)
          GlobalEventBus.$emit('alert', { text: "errore caricamento video", error });
        });
      })
  }

  created() {
    GlobalEventBus.$on("notice", async (args: NoticeEventArgs) => {
      // console.log("notice event:", args);
      this.queue.push(args.text);

      if(args.video == true) {
        // this.showVideo = true
        // this.loadVideo()
      } else {
        this.showVideo = false
      }
      this.showVideo = false

      if (args.disposeTimeoutMs != null && args.disposeTimeoutMs > 0) {
        setTimeout(() => {
          this.dispose();
        }, args.disposeTimeoutMs);
      }
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off('notice');
  }

  dispose() {
    try {
      this.queue.splice(0, 1);
    } catch (e) {
      // console.log(e);
    }
  }
}
