import { ConversationMessage } from './Conversation';
import { QuestStarsOutcomes, QuestStatus } from './Enums';

export interface FrameQuestPayload {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  jsonData: any;
  state: UserQuestProgress;
}

export class UserQuestProgress {
  stars: QuestStarsOutcomes;
  status: string;
  suspendData: string | null;

  constructor(data: any) {
    this.stars = data.stars != null && !isNaN(parseInt(data.stars)) ? parseInt(data.stars) : data.stars;
    this.status = data.status;
    this.suspendData = data.suspendData || null;
  }
}

export class Quest {
  readonly id: number;
  readonly locationKey: string;
  readonly questUrl: string;
  readonly preQuestConversation: ConversationMessage[];
  readonly postQuestConversation?: ConversationMessage[];
  readonly acceptLabel: string;
  readonly denyLabel: string;
  readonly characterName: string;
  readonly characterKey: string;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  jsonData: any;
  progress: UserQuestProgress;

  get districtKey(): string {
    return this.locationKey.split("|")[0];
  }

  get zoneKey(): string {
    return this.locationKey.split("|")[1];
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  constructor(data: any) {
    this.id = data.id;
    this.locationKey = data.locationKey;
    this.questUrl = data.questUrl;
    if (location.hostname.includes('localhost') || location.hostname.includes('127.0.0.1') || location.hostname.includes('0.0.0.0')) {
      this.questUrl = `/quests/0/index.html?questId=${data.id}`;
    }

    this.preQuestConversation = data.conversations.intro.map((x: any) => new ConversationMessage(x));
    this.postQuestConversation = data.conversations.outro?.map((x: any) => new ConversationMessage(x)) || null;
    this.acceptLabel = data.acceptLabel;
    this.denyLabel = data.denyLabel;
    this.characterName = data.characterName;
    this.characterKey = data.characterKey;

    this.jsonData = data.jsonData;
    this.progress = data.questProgress != null ?
      new UserQuestProgress(data.questProgress):
      new UserQuestProgress({
        stars: QuestStarsOutcomes.Zero,
        status: QuestStatus.NotAttempted,
      });
  }

  toFrameQuestPayload(): FrameQuestPayload {
    // console.log('toFrameQuestPayload', this.progress);
    return {
      jsonData: this.jsonData,
      state: {
        stars: this.progress.stars,
        status: this.progress.status,
        suspendData: this.progress.suspendData,
      }
    }
  }
}
