const dataBlockDefinitions = require('@/assets/js/world/data/dataBlockDefinitions.json');
const dataWorld = require('@/assets/js/world/data/dataWorld.json');

import BaseRestApiProvider from './BaseRestApiProvider';
import { Player } from '@/assets/js/world/models/Player';
import { IRestApiProvider } from './RestApiProviderFactory';
import { UserBlockProgress } from '@/assets/js/world/models/UserBlockProgress';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { PlayerCreditsPayload, PlayerExpPayload, QuestStarsPayload, QuestStatusPayload,
  QuestSuspendDataPayload, PlayerStatistics, UserAvatarPayload, UsernameRegistrationPayload,
  OnboardingContents, StaticPage, StaticPageContent, EndingContents, CampaignRanking} from '@/assets/js/RestApiPayloads';
import { JsApiError, JsApiErrorStatusCode } from './CustomErrors';
import { Campaign } from '@/assets/js/campaign/models/Campaign';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { AvatarAsset } from '@/assets/js/profile/models/Avatar';
import { ConversationMessage } from '@/assets/js/world/models/Conversation';
import { Badge } from '@/assets/js/profile/models/Badge';
import { Prize } from '@/assets/js/profile/models/Prize';
import { BaseNotification, NotificationFactory } from '@/assets/js/profile/models/Notifications';
import { Category, Ranking } from '@/assets/js/campaign/models/Ranking';
import { AxiosResponse } from 'axios';
import { Quest, UserQuestProgress } from '@/assets/js/world/models/Quest';
import CryptoUtils from './Crypto';

const K1 = process.env.VUE_APP_K1;
const K2 = process.env.VUE_APP_K2;
const K3 = process.env.VUE_APP_K3;

export default class RestApiProvider extends BaseRestApiProvider implements IRestApiProvider {
  navigator: Navigator;

  constructor(navigator: Navigator, doCryptation: boolean, doSignature: boolean) {
    super();
    this.navigator = navigator;
    this.doCryptation = doCryptation;
    this.doSignature = doSignature;
  }

  /**
   * Va attivato prima il middleware di firma, poi quello di offuscamento.
   * Lato server, la prima operazione è il de-offuscamento, poi il controllo sulla firma.
   */
  async login(username?: string, password?: string): Promise<Player> {
    try {

      // questo serve prettamente per sviluppare in localhost: in pratica, non arrivando dalla piattaforma vera di login,
      // viene simulata una login fatta dal pannello di admin. Questo permette di ricevere un cookie, lo stesso cookie
      // che si avrebbe a disposizione arrivando da una piattaforma reale di login (ISAPIENS).
      // Il cookie è necessario per chiamare la auth/login/refresh al passo successivo
      if (username != null && password != null) {
        await this.axiosInstance.post(`auth/login`, {
          username,
          password
        }).catch((e) => console.log(e));
      }
      const response = await this.axiosInstance.post(`auth/login/refresh`);

      if (response.status === 200 || response.status === 201) {
        await this.activateSecurityMiddleware(response);
        return new Player(response.data);
      }

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "login", response.statusText);
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status == 404) {
          throw {
            message: "User must complete onboarding",
            status: 404
          };
        } else if (e.response.status == 401) {
          throw {
            message: "Missing login cookie, user must be redirected to the login platform",
            status: 401
          };
        }
      }
      throw this.composeCustomError(e);
    }
  }

  async logout(): Promise<void> {
    await this.axiosInstance.get(`auth/logout`);
  }

  async activateSecurityMiddleware(response: AxiosResponse): Promise<void> {
    // console.log("Public key received", response.data.publicKey);
    if (response.data.publicKey != null && response.data.publicKey.length > 0) {
      await this.importPublicRsaKey(response.data.publicKey);

      if (this.doCryptation) {
        this.addObfuscationMiddleware();
      } else {
        console.log("STATO CRYPTATION NON ATTIVO");
      }
    } else {
      console.log("CRYPTATION NON ATTIVABILE, PUBLIC KEY NON RICEVUTA");
    }

    // console.log("Nonce received", response.data.nonce);
    if (response.data.nonce != null) {
      this.importNonce(response.data.nonce);

      if (this.doSignature) {
        this.addSignatureMiddleware();
      } else {
        console.log("STATO SIGNATURE NON ATTIVO");
      }
    } else {
      console.log("SIGNATURE NON ATTIVABILE, NONCE NON RICEVUTO");
    }
  }

  async signUp(displayName: string, username: string, password: string): Promise<void> {
    try {
      const response = await this.axiosInstance.post(`users/signup`, {
        display_name: displayName,
        username,
        password,
        role: 1,          // "User"
      });
      if (response.status === 201) return;
      throw new JsApiError(JsApiErrorStatusCode.UnexpectedError, "signUp", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getQuests(player: Player): Promise<Quest[]> {
    try {
      const axiosResponse = await this.axiosInstance.get('quests');
      
      const pk = await CryptoUtils.importPrivateRsaKey([K1, K2, K3].join('\n'));
      const decryptedKey = await CryptoUtils.decryptRsa(axiosResponse.data.key, pk);
      const stringPayload = await CryptoUtils.decryptAes(axiosResponse.data.encryptedData, decryptedKey, axiosResponse.data.iv);
      const stringifiedDeterministicPayload = CryptoUtils.deterministicStringify(stringPayload);
      const signToCheck = await CryptoUtils.sign(stringifiedDeterministicPayload, player.id.toString());

      if (signToCheck != axiosResponse.headers.authorization.substring(10)) {
        throw "Security error";
      }
      // console.log('signToCheck', signToCheck);
      // console.log('sign in header', axiosResponse.headers.authorization);
      const payload = JSON.parse(stringPayload);
      // console.log('payload', payload);
      
      return payload;
    } catch (er) {
      console.error(er);
      throw this.composeCustomError(er);
    }
  }

  async getUserProgresses(): Promise<UserQuestProgress[]> {
    try {
      const axiosResponse = await this.axiosInstance.get('progresses');
      return axiosResponse.data;
      // return (axiosResponse.data != null && Array.isArray(axiosResponse.data)) ? (axiosResponse.data.map((x: any) => new UserQuestProgress(x))) : [];
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getGameStatus(player: Player): Promise<GameStatus> {
    try {
      const dataQuests = await this.getQuests(player)
      const dataUserProgress = await this.getUserProgresses();

      return new GameStatus(player, dataWorld, dataQuests, dataBlockDefinitions, dataUserProgress);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  /**
   * Restituisce il nuovo valore di credits aggiornato, validato dal server, in caso di risposta HTTP 201
   */
  private async _setPlayerCredits(payload: PlayerCreditsPayload): Promise<PlayerStatistics> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`progresses/credits`, payload);
      if (response.status === 201) return response.data;

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setPlayerCredits", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async setPlayerCredits(payload: PlayerCreditsPayload): Promise<PlayerStatistics> {
    return await this.requestQueue.add(() => this._setPlayerCredits(payload));
  }

  /**
   * Restituisce il nuovo valore di exp aggiornato, validato dal server, in caso di risposta HTTP 201
   */
  private async _setPlayerExperience(payload: PlayerExpPayload): Promise<PlayerStatistics> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`progresses/experience`, payload);
      if (response.status === 201) return response.data;

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setPlayerExperience", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async setPlayerExperience(payload: PlayerExpPayload): Promise<PlayerStatistics> {
    return await this.requestQueue.add(() => this._setPlayerExperience(payload));
  }

  private async _setQuestStars(payload: QuestStarsPayload): Promise<PlayerStatistics> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`quests/stars`, payload);
      if (response.status === 201) return response.data;

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setQuestStars", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async setQuestStars(payload: QuestStarsPayload): Promise<PlayerStatistics> {
    return await this.requestQueue.add(() => this._setQuestStars(payload));
  }

  private async _setQuestStatus(payload: QuestStatusPayload): Promise<void> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`quests/status`, payload);
      if (response.status === 201) return;

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setQuestStatus", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async setQuestStatus(payload: QuestStatusPayload): Promise<void> {
    return await this.requestQueue.add(() => this._setQuestStatus(payload));
  }

  private async _setQuestSuspendData(payload: QuestSuspendDataPayload): Promise<void> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`quests/suspend`, payload);
      if (response.status === 201) return;

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setQuestSuspendData", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async setQuestSuspendData(payload: QuestSuspendDataPayload): Promise<void> {
    return await this.requestQueue.add(() => this._setQuestSuspendData(payload));
  }

  private async _createUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    try {
      const response = await this.axiosAntiCheatingInstance.post(`progresses/${payload.blockKey}`, payload);
      if (response.status === 201) return new UserBlockProgress(response.data);

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "createUserBlockProgress", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async createUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    return await this.requestQueue.add(() => this._createUserBlockProgress(payload));
  }

  private async _updateUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    try {
      const response = await this.axiosAntiCheatingInstance.put(`progresses/${payload.blockKey}`, payload);
      if (response.status === 200) return new UserBlockProgress(response.data);

      throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "updateUserBlockProgress", response.statusText);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async updateUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    return await this.requestQueue.add(() => this._updateUserBlockProgress(payload));
  }

  async getCampaigns(): Promise<Campaign[]> {
    try {
      const response = await this.axiosInstance.get("campaigns");
      return response.data.map((x: any) => new Campaign(x));
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getCategories(): Promise<Category[]> {
    try {
      const response = await this.axiosInstance.get("categories");
      return response.data.map((x: any) => new Category(x));
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getRanking(categoryId: number, pageIndex: number, pageSize: number): Promise<Ranking[]> {
    const apiPath = "leaderboard" + (categoryId == 0 ? "" : `/${categoryId}`);
    try {
      const response = await this.axiosInstance.get(apiPath, {
        params: {
          page: pageIndex,
          items: pageSize,
        }
      });
      // console.log('ranking API', response.data);
      return response.data.map((x: any) => new Ranking(x));
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getProfile(userId = 0): Promise<UserProfile> {
    try {
      const response = await this.axiosInstance.get(`users/${userId == 0 ? 'me' : userId}`);
      return new UserProfile(response.data);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getAvatarAssets(sex: string): Promise<AvatarAsset[]> {
    try {
      const response = await this.axiosInstance.get("avatars/assets", {
        params: {
          sex,
        }
      });
      return response.data.map((x: any) => new AvatarAsset(x));
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getUserAvatar(): Promise<number[]> {
    try {
      const response = await this.axiosInstance.get("users/me/avatar");
      return response.data;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async setUserAvatar(payload: UserAvatarPayload): Promise<UserAvatarPayload> {
    try {
      const response = await this.axiosInstance.post("users/me/avatar", payload);
      return {
        assets: [],
        imageBody: response.data.imageBody,
        imageFace: response.data.imageFace,
      };
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getOnboardingContents(): Promise<OnboardingContents> {
    try {
      const response = await this.axiosInstance.get('/onboarding/contents');
      return response.data as OnboardingContents;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getEndingContents(): Promise<EndingContents> {
    try {
      const response = await this.axiosInstance.get('/endgame/contents');
      return response.data as EndingContents;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async isUsernameAvailable(username: string): Promise<void> {
    try {
      await this.axiosInstance.get(`users/check/username/${username}`);
      return;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async registerUsername(payload: UsernameRegistrationPayload): Promise<void> {
    try {
      await this.axiosInstance.post(`users/signup/best-academy`, payload);
      return;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async setUserSex(sex: string): Promise<void> {
    try {
      let value = sex;
      if (value != 'male' && value != 'female') {
        value = 'male';
      }
      await this.axiosInstance.patch(`/users/me/sex/${value}`);
      return;
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async askForQuattroDetails(eventKey: string, player: Player): Promise<ConversationMessage[]> {
    try {
      const result = await this.axiosInstance.post(`/quattro/trigger/${eventKey}`);
      return result.data.map((x: any) => {
        // console.log('Quattro message', x);
        const interpolatedMessage = (x.content as string).interpolate(player.toObject());
        // console.log('Quattro message after interpolation', interpolatedMessage);
        return new ConversationMessage({
          type: x.type,
          content: interpolatedMessage,
        });
      });
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getBadges(): Promise<Badge[]> {
    try {
      const badges = await this.axiosInstance.get('users/me/badges');
      return badges.data.map((x: any) => new Badge(x)).sort(this.order);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  order(a : Badge, b: Badge) : any {
    const timeA = a.ownedTimestamp ? a.ownedTimestamp : new Date().toISOString() ;
    const timeB = b.ownedTimestamp ? b.ownedTimestamp : new Date().toISOString();
    return (timeA < timeB) ? -1 : ((timeA > timeB) ? 1 : 0);
  }

  async getPrizes(pageIndex: number, itemsPerPage = 20): Promise<Prize[]> {
    try {
      const prizes = await this.axiosInstance.get(`users/me/prizes?page=${pageIndex}&items=${itemsPerPage}`);
      return prizes.data.map((x: any) => new Prize(x));
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async setLastNotificationViewed(notificationId: number): Promise<void> {
    try {
      await this.axiosInstance.patch(`notifications/latest/${notificationId}`);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getNotificationsAfterId(notificationId: number): Promise<BaseNotification[]> {
    try {
      const notifications = await this.axiosInstance.get(`notifications/latest/${notificationId}`);
      return notifications.data.map((x: any) => NotificationFactory.create(x)).filter((x: any) => x != null);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getNotificationsBackwardPaged(notificationId: number, itemsPerPage: number): Promise<BaseNotification[]> {
    try {
      const notifications = await this.axiosInstance.get('notifications', {
        params: {
          from: notificationId,
          items: itemsPerPage,
        }
      });
      return notifications.data.map((x: any) => NotificationFactory.create(x)).filter((x: any) => x != null);
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getStaticPage(pageKey: string): Promise<StaticPage> {
    try {
      const res = await this.axiosInstance.get(`pages/${pageKey}`);
      return {
        key: pageKey,
        content: res.data.content,
      };
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getStaticPageContent(pageKey: string): Promise<StaticPageContent[]> {
    try {
      const res = await this.axiosInstance.get(`pages/${pageKey}`);
      return res.data as StaticPageContent[];
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }

  async getUserRankings(): Promise<CampaignRanking[]> {
    try {
      const res = await this.axiosInstance.get(`users/me/ranking`);
      return res.data as CampaignRanking[];
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }
}
