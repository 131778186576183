



































import { Component } from 'vue-property-decorator';

import { GlobalEventBus, QuattroEventArgs } from "@/services/GlobalEventBus";
import { ConversationMessage } from '@/assets/js/world/models/Conversation';
import BaseComponent from './BaseComponent';

@Component
export default class QuattroComponent extends BaseComponent {
  conversationsQueue: ConversationMessage[][] = [];
  chatIndex = 0;
  currentCharacterName = "";
  killQuattro = false;

  get currentConversation(): ConversationMessage[] {
    if (this.conversationsQueue != null && this.conversationsQueue.length > 0)
      return this.conversationsQueue[0];

    return [];
  }

  get visible(): boolean {
    return this.conversationsQueue != null && this.conversationsQueue.length > 0;
  }

  get isEndOfConversation(): boolean {
    return this.currentConversation.length == 0 || this.chatIndex == this.currentConversation.length - 1;
  }

  get avatar(): string {
    return this.$store.getters.avatarBackgroundImageStyle;
  }

  get classes(): any {
    return {
      hidden: !this.visible
    }
  }

  created() {

    GlobalEventBus.$on('kill-quattro', (kill: boolean) => {
      this.killQuattro = kill;
    })

    GlobalEventBus.$on('quattro', async (args: QuattroEventArgs) => {
      // console.log('trying to emit this Quattro event:', args.eventKey);

      // if (this.killQuattro) {
      //   return;
      // }

      if ((this.$store.getters.quattroRaisedEvents as string[]).includes(args.eventKey))
        return;
      
      if (args.eventKey == 'enter-residenziale') {
        GlobalEventBus.$emit('highlight', 'residenziale');
        GlobalEventBus.$emit('hint', {text: 'Seleziona l’edificio da esplorare', disposeTimeoutMs: 0 });
        GlobalEventBus.$emit('set-header-visibility', false);
      }
      if (args.eventKey == 'enter-menu') {
        GlobalEventBus.$emit('highlight', 'menu');
        GlobalEventBus.$emit('hint', {text: 'Clicca sull\'icona attiva', disposeTimeoutMs: 0 }); 
        GlobalEventBus.$emit('set-header-visibility', false);   
      }
      if (args.eventKey == 'first-quest-success') {
        GlobalEventBus.$emit('first-quest-success');
        // GlobalEventBus.$emit('hint', {text: 'Clicca sull\'icona attiva', disposeTimeoutMs: 0 }); 
        GlobalEventBus.$emit('set-header-visibility', false);  
      }
      if (args.eventKey == 'exit-upgrade') {
        GlobalEventBus.$emit('exit-upgrade');      
      }
      if (args.eventKey == 'final-quest-success-finanziario') {
        console.log("FINE GAME! show video here");
      }
      
      try {
        const conversation = await this.wrapApiCall(() => this.dataProvider.askForQuattroDetails(args.eventKey, this.$store.getters.player), "lettura dati Quattro") as ConversationMessage[];
        if (conversation == null || conversation.length === 0) {
          return;
        }

        // console.log(conversation);      
        
        this.$store.dispatch('onQuattroRaisedEvent', args.eventKey);
        this.conversationsQueue.push(conversation.map(c => {
          return new ConversationMessage({
            type: c.type,
            content: this.sanitize(c.content),
          });
        }));

        GlobalEventBus.$emit('play-sound', 'feedback_positivo')

        GlobalEventBus.$emit('quattro-raised', {
          eventKey: args.eventKey,
          wasShown: true,
        });
      } catch (error) {
        // console.log('error downloading quattro conversation', error);

        GlobalEventBus.$emit('quattro-raised', {
          eventKey: args.eventKey,
          wasShown: false,
        });
      }
    });
  }

  goToNextChatItem() {
    this.chatIndex += 1;
  }

  closeCurrentConversation() {
    this.conversationsQueue.splice(0, 1);
    this.chatIndex = 0;
  }
}
