
































































import {
  Component,
} from "vue-property-decorator";
import Accordion from './Common/Accordion.vue';
import AccordionItem from "./Common/AccordionItem.vue";
import { GlobalEventBus } from '@/services/GlobalEventBus';
import BaseComponent from './BaseComponent';
import { StaticPageContent } from '@/assets/js/RestApiPayloads';


@Component({
  components: {
    Accordion,
    AccordionItem,
  }
})
export default class FaqComponent extends BaseComponent {
  faqs: StaticPageContent[] = [];
  intro = "";
  visible = false;

  created() {
    GlobalEventBus.$on('open-faq', () => {
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
      this.visible = true;
    });
  }

  async mounted() {
    try {
      const faqIntro = await this.dataProvider.getStaticPage('faq-intro');
      const faqs = await this.dataProvider.getStaticPageContent('faq');
      this.intro = this.sanitize(faqIntro.content);
      this.faqs = faqs.map(x => {
        x.content = this.sanitize(x.content);
        return x;
      });
    } catch (e) {
      GlobalEventBus.$emit('alert', {
        text: 'Impossibile recuperare le FAQs. Contattare il supporto tecnico',
      });
    }
  }

  beforeDestroy() {
    GlobalEventBus.$off('open-faq');
  }

  onDispose() {
    this.visible = false;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
  }
}
