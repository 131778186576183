export enum BlockType {
  Macerie = 1,
  Edificabile = 2,
  Aiuola = 3,
  Bosco = 4,
  Laghetto = 5,
  Collinetta = 6,
  Orto = 7,
  Piazza = 8,
  Fontana = 9,
  MercatoAperto = 10,
  SkatePark = 11,
  Piscina = 12,
  Anfiteatro = 13,
  Minigolf = 14,
  Tennis = 15,
  Monumento = 16,
  FermataMetro = 17,
  PannelliSolari = 18,
  Elica = 19,
  GelateriaBaracchino = 21,
  LunaParkGiostre = 22,

  Edicola1 = 1001,
  Edicola2 = 1002,
  Edicola3 = 1003,
}


export enum QuestStatus {
  NotAttempted = "not_attempted",
  Incomplete = "incomplete",
  Failed = "failed",
  Passed = "passed",
}

export const QuestStatusNumberValueMap: { [key: string]: number } = {
  "not_attempted": 0,
  "incomplete": 10,
  "failed": 20,
  "passed": 30,
}

export enum QuestStarsOutcomes {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
}
