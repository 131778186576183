
















import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';

@Component
export default class AsyncButtonComponent extends Vue {
  @Prop() readonly classes: any;
  @Prop() readonly isSpinning: boolean;
  @Prop() readonly text: string;

  get dynamicClasses(): any {
    return {
      'user__cta--spinning': this.isSpinning,
      ...this.classes,
    }
  }

  // get label(): string {
  //   return this.isSpinning ? "..." : this.text;
  // }

  onClick() {
    this.$emit('click');
  }
}

