var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lobby__body lobby__body--assistenza" }, [
    _c("div", { staticClass: "lobby__full assistenza__helpdesk" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "assistenza__button",
          on: {
            click: function($event) {
              return _vm.goToFaq()
            }
          }
        },
        [_vm._v(" FAQ ")]
      ),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3)
    ]),
    _c("div", { staticClass: "lobby__full assistenza__helpdesk" }, [
      _c("div", [
        _vm._v("In qualsiasi momento puoi consultare il nostro regolamento")
      ]),
      _c(
        "div",
        {
          staticClass: "assistenza__button",
          on: {
            click: function($event) {
              return _vm.goToRegolamento()
            }
          }
        },
        [_vm._v("Regolamento")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" Hai una domanda per noi?"),
      _c("br"),
      _vm._v("Prova a vedere se abbiamo già risposto ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("br"), _vm._v(" oppure scrivi una mail a ")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "assistenza__button" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "mailto:bestacademy@isapiens.it?subject=Richiesta supporto U-City"
          }
        },
        [_vm._v("bestacademy@isapiens.it")]
      ),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "100%" } }, [
      _c("br"),
      _vm._v(
        " Ricordati di indicare sempre il tuo nickname di gioco per permetterci di riconoscerti "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }