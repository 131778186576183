var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidedown" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "alert", class: { "alert--active": _vm.visible } },
          [
            _c("div", { staticClass: "alert__wrapper" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "tick",
                      expression: "'tick'"
                    }
                  ],
                  staticClass: "alert__button",
                  on: {
                    click: function($event) {
                      return _vm.triggerDetailVisibility()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v(
                      " " + _vm._s(_vm.triggerDetailVisibilityButtonLabel) + " "
                    )
                  ])
                ]
              ),
              _vm.currentAlert
                ? _c("div", { staticClass: "alert__text" }, [
                    _vm._v(
                      "[" +
                        _vm._s(_vm.currentAlert.index) +
                        "] " +
                        _vm._s(_vm.currentAlert.text)
                    )
                  ])
                : _vm._e(),
              _vm.currentAlert
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "tick",
                          expression: "'tick'"
                        }
                      ],
                      staticClass: "alert__button",
                      on: {
                        click: function($event) {
                          return _vm.onActionButtonClicked()
                        }
                      }
                    },
                    [
                      _vm.currentAlert.isDisposable
                        ? _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close")
                          ])
                        : _c("i", { staticClass: "material-icons" }, [
                            _vm._v("refresh")
                          ])
                    ]
                  )
                : _vm._e()
            ]),
            _vm.currentAlert != null &&
            _vm.currentAlert.error != null &&
            _vm.isDetailOpen
              ? _c("div", { staticClass: "alert__caption" }, [
                  _vm._v("[" + _vm._s(_vm.currentAlert.error) + "]")
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }