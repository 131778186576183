var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHeaderFull
    ? _c("div", { staticClass: "header__full" }, [
        _c("div", { staticClass: "header" }, [
          _vm.showNotificationsButton
            ? _c(
                "div",
                { staticClass: "header__wrapper header__wrapper--left" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "selezione_icona",
                          expression: "'selezione_icona'"
                        }
                      ],
                      staticClass: "menu_button",
                      on: {
                        click: function($event) {
                          return _vm.onNotificationsButtonClick()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "uni-icons" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              "stroke-width": "2",
                              stroke: "none",
                              fill: "none",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          },
                          [
                            _c("path", {
                              staticClass: "fill",
                              attrs: {
                                d:
                                  "M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6",
                                fill: "black"
                              }
                            }),
                            _c("path", {
                              staticClass: "fill",
                              attrs: {
                                d: "M9 17v1a3 3 0 0 0 6 0v-1",
                                fill: "black"
                              }
                            })
                          ]
                        )
                      ]),
                      _vm.isDotShowing
                        ? _c("div", { staticClass: "notificator__dot" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.notificationBadgeCount))
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "header" }, [
          _vm.showStats
            ? _c(
                "div",
                { staticClass: "header__wrapper header__wrapper--center" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "selezione_icona",
                          expression: "'selezione_icona'"
                        }
                      ],
                      staticClass: "user",
                      on: {
                        click: function($event) {
                          return _vm.onStatsClick()
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "user__pic",
                        style: _vm.avatar
                      }),
                      _c("div", { staticClass: "user__stats" }, [
                        _c("div", { staticClass: "user__experience" }, [
                          _c("i", { staticClass: "uni-icons" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 240 240",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  staticClass: "fill",
                                  attrs: {
                                    d:
                                      "M68.6919 135.805C70.6489 131.707 72.0187 129.561 73.1929 129.561H74.9541C75.9325 129.561 77.4981 131.707 79.455 135.805L100.785 179.902C101.96 182.634 104.504 184 108.222 184H123.094C127.4 184 128.574 182.439 127.008 179.122L98.8285 125.463C97.4586 122.927 96.8716 120.976 96.8716 119.415C96.8716 117.854 97.4587 115.902 98.6328 113.561L124.856 62.2439C126.421 59.3171 125.051 57.7561 120.942 57.7561H106.265C103.916 57.7561 102.351 58.1463 101.372 58.5366C100.394 58.9268 99.6113 59.9024 98.8285 61.4634L79.0636 103.22C77.6938 105.951 76.911 107.707 76.5196 108.488C76.1282 109.268 75.5412 109.463 74.7584 109.463H72.9972C72.2144 109.463 71.4316 109.073 71.0402 108.293C70.4532 107.512 69.6704 105.951 68.4963 103.22L49.1228 61.4634C48.34 59.9024 47.3615 58.9268 46.3831 58.5366C45.4046 58.1463 43.8391 57.7561 41.6865 57.7561H27.0096C22.7043 57.7561 21.5302 59.3171 23.0957 62.2439L49.3184 113.561C50.4926 115.902 51.0797 117.854 51.0797 119.415C51.0797 120.976 50.2969 122.927 49.1228 125.463L20.7474 179.122C18.9862 182.439 20.356 184 24.6613 184H39.5339C43.0563 184 45.6003 182.634 46.9701 179.902L68.6919 135.805Z",
                                    fill: "black"
                                  }
                                }),
                                _c("path", {
                                  staticClass: "fill",
                                  attrs: {
                                    d:
                                      "M171.077 75.9024C179.883 75.9024 186.145 77.4634 189.668 80.1951C192.995 83.1219 194.756 88.1951 194.756 95.2195V97.7561C194.756 105.756 192.799 111.415 189.276 114.537C185.558 117.659 179.296 119.22 170.49 119.22H158.357V79.0244C158.357 77.4634 158.944 76.6829 160.51 76.2927C162.467 76.0976 165.989 75.9024 171.077 75.9024ZM158.357 139.707H169.707C186.341 139.707 198.865 136.585 207.28 129.951C215.695 123.317 220 112.976 220 98.7317V95.2195C220 81.1707 216.086 71.0244 208.454 64.9756C200.822 59.1219 188.102 56 170.49 56C156.596 56 146.616 56.3902 140.549 57.1707C137.809 57.561 136.048 58.1463 135.07 59.3171C134.091 60.4878 133.7 62.6341 133.7 65.3658V179.122C133.7 181.268 133.896 182.634 134.678 183.22C135.265 183.805 136.635 184 138.984 184H153.073C155.226 184 156.596 183.805 157.379 183.22C157.966 182.634 158.357 181.268 158.357 179.122V139.707Z",
                                    fill: "black"
                                  }
                                })
                              ]
                            )
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.displayedExperience))])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "user__credits",
                            class: {
                              "user__credits--active": _vm.creditsAnimation
                            }
                          },
                          [
                            _c("i", { staticClass: "uni-icons" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 240 240",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    staticClass: "fill",
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M24 72C24 58.7452 34.7452 48 48 48H216V168C216 181.255 205.255 192 192 192H24V72ZM48 84C48 77.3726 53.3726 72 60 72H96V92C96 98.6274 90.6274 104 84 104H48V84ZM54 152C50.6863 152 48 154.686 48 158V168H186C189.314 168 192 165.314 192 162V152H54Z",
                                      fill: "black"
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.displayedCredits) + " Cr.")
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "header" }, [
          _vm.showCloseButton
            ? _c(
                "div",
                { staticClass: "header__wrapper header__wrapper--right" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "selezione_icona",
                          expression: "'selezione_icona'"
                        }
                      ],
                      staticClass: "menu_button",
                      on: {
                        click: function($event) {
                          return _vm.onCloseButtonClick()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }