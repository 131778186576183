declare const PIXI: any;
declare const particles: any;
declare const ease: any;

import {
  GameCore
} from './GameCore'
import {
  GlobalEventBus
} from "@/services/GlobalEventBus";
import seedrandom from 'seedrandom'
import {
  BlockClass
} from './models/BlockDefinition';
import {
  BlockType
} from './models/Enums.js';
import {
  Viewport
} from 'pixi-viewport';
import {
  Howl
} from 'howler';

import { getGPUTier } from 'detect-gpu';

const particleExplosion = require('./data/explosion.json');
const particleSparks = require('./data/sparks.json');
const audiosprite = require('@/assets/audio/audiosprite.json')


export default class GameDisplay {
  debug: boolean;
  width: number;
  height: number;
  scale: number;
  resolutionMult: number;
  worldSize: number;
  time: number;

  viewport: any;
  rng: any;

  currentDialogIndex: number;
  currentConversation: any;
  currentCharacterId: number;
  currentSceneId: string;

  isAnswering: boolean;
  hasAnswered: boolean;
  dialogTimeout: any;

  cameraCurrentPos: any;
  cameraTargetPos: any;
  cameraTargetZoom: any;
  cameraCurrentZoom: any;

  currentBlockToUpgrade: any;
  currentQuestBlock: any;
  isSwitchingView: boolean;
  currentView: number;
  loaderInfo: any;
  loading: boolean;

  finalQuestSelector: any;
  emitter: any;
  sparksEmitter: any;
  smokeEmitter: any;
  isUpgrading: boolean;
  menuSelectors: any;
  selectorSize: number;
  districtSelectorSize: number;

  waters: any[];
  clouds: any[];
  particles: any[];
  flyingObj: any;

  grid: any;
  numberOfColumns: number;
  mapOffset: number;
  roadOffset: number;
  mapOffsetY: number;

  tileWidth: number;
  tileHeight: number;
  tileWidthHalf: number;
  tileHeightHalf: number;

  superBlocks: any[];

  audio: any[];
  sound: any;
  music: any;

  cameraZoomTarget: number;
  cameraTarget: any;
  currentCameraZoom: any;

  app: any;

  multiplayerFirstTimeUnlocked: boolean;
  firstTimeInResidenziale: boolean;
  currentScene: string;
  
  // MENU STUFF
  menuSelectorsStartScale = 0.12
  selectorsStartScale = 0.08
  menuButtons: any[];
  cheatCode = '';
  cheatCodeTimeout: any;

  //// SETTINGSSS!!!! \\\\\
  lowQuality = true

  tutorialBoi = false;



  constructor(width: number, height: number) {
    this.debug = false;

    this.width = width
    this.height = height
    this.scale = 1.0
    this.resolutionMult = 1
    this.worldSize = 1024

    // @ts-ignore
    this.viewport = null
    // @ts-ignore
    this.rng = null

    this.time = 0

    this.currentDialogIndex = 0
    this.currentConversation = null
    // @ts-ignore
    this.currentCharacterId = null
    this.isAnswering = false
    this.hasAnswered = false
    this.dialogTimeout = null

    this.cameraCurrentPos = null
    this.cameraTargetPos = null
    this.cameraTargetZoom = null
    this.cameraCurrentZoom = null

    this.currentBlockToUpgrade = null
    this.currentQuestBlock = null

    this.isSwitchingView = false

    this.currentView = 0

    this.loaderInfo = {}
    this.loading = false

    this.finalQuestSelector = null

    this.emitter = null
    this.sparksEmitter = null
    this.smokeEmitter = null
    this.isUpgrading = false
    this.menuSelectors = []

    this.selectorSize = 50
    this.districtSelectorSize = 50

    // map stuff

    this.grid = []
    this.numberOfColumns = 6;
    this.mapOffset = 149 / 4
    this.roadOffset = 50 / 4
    this.mapOffsetY = 6 / 4

    this.tileWidth = 0;
    this.tileHeight = 0;
    this.tileWidthHalf = 0;
    this.tileHeightHalf = 0;

    this.superBlocks = []

    this.waters = []


    // MENU STUFF
    this.menuButtons = []

    // AUDIO
    this.audio = []
    this.sound = null
    this.music = null

    this.detectGpu()
    // console.log("creating renderer");
    

    
    // this.app.renderer.backgroundColor = 0xf5f5f5;

    
  }


  detectGpu() {
    (async () => {
      const gpuTier = await getGPUTier({
        benchmarksURL: '/json'
      })
      // console.log(gpuTier);
      if(gpuTier.tier >= 2) {
        this.lowQuality = false
        
        PIXI.settings.RESOLUTION = window.devicePixelRatio;
        
        // console.log("HIGH QUALITY MODE! 🌵🌵🌵");
        // console.log("pixi resolution = ", PIXI.settings.RESOLUTION);
      } else {
        this.lowQuality = true

        PIXI.settings.RESOLUTION = 1;
        console.log("LOW QUALITY MODE! 🔥🔥🔥");
        // console.log("pixi resolution = ", PIXI.settings.RESOLUTION);
        
      }

      this.app = new PIXI.Application({
        forceCanvas: true,
        width: this.width,
        height: this.height,
        antialias: true,
        sharedTicker: true,
        resizeTo: window
        // transparent: false,
        // powerPreference: 'high-performance'
      })

    })();
  }

  createBackgroundParticles() {
    for (let index = 0; index < 40; index++) {
      this.createBackgroundParticle(Math.random() * this.viewport.worldWidth, Math.random() * this.viewport.worldHeight);  
    }
  }

  createBackgroundParticle(x: number, y: number) {
    const obj = this.createSprite('particle_2')
    obj.anchor.set(0.5, 0.5);
    obj.zIndex = -100;
    obj.width = 10
    obj.height = 10
    obj.x = x
    obj.y = y
    obj.startX = x
    obj.startY = y
    obj.offsetX = 0
    obj.offsetY = 0
    obj.parallax = 0.1 + Math.random() * 0.1
    obj.timeOffset = Math.random() * 10000
    this.viewport.addChild(obj);
    this.particles.push(obj)
  }

  createBackgroundGradient() {
    const ratioH = window.innerHeight / window.innerWidth;
    const ratioW = window.innerWidth / window.innerHeight;

    let height = this.worldSize * ratioH;
    if(height < this.worldSize) {
      height = this.worldSize;
    }
    let width = this.worldSize * ratioW;
    if(width < this.worldSize) {
      width = this.worldSize;
    }

    // console.log(height);
    
    const canvas = document.createElement('canvas');
    canvas.width  = width;
    canvas.height = height ;
    const ctx = canvas.getContext('2d');
    const gradient = ctx!.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, "#BFEBF3");
    gradient.addColorStop(1, "#F2F9FB");
    ctx!.fillStyle = gradient;
    ctx!.fillRect(0, 0, canvas.width, canvas.height);
    const sprite = new PIXI.Sprite(PIXI.Texture.from(canvas));
    sprite.anchor.set(0, 0);
    sprite.x = this.worldSize / 2 - width / 2;
    sprite.y = this.worldSize / 2 - height / 2;
    sprite.zIndex = -100
    this.viewport.addChild(sprite);
  }

  createCloud(x:number, y:number, hasShadow: boolean, shadowY: number) {
    const obj = this.createSprite('cloud_' + Math.floor(Math.random()*4))
    obj.anchor.set(0.5, 0.5);
    obj.zIndex = 1000000000000000000;
    obj.width = 200
    obj.height = 200
    obj.x = x
    obj.y = y
    obj.startX = x
    obj.startY = y
    obj.offsetX = 0
    obj.offsetY = 0
    obj.parallax = 0.1 + Math.random() * 0.1
    obj.timeOffset = Math.random() * 10000
    this.viewport.addChild(obj);
    this.clouds.push(obj)
    obj.hasShadow = hasShadow;

    if(!hasShadow) return;

    const shadow = this.createSprite('cloud_shadow')
    shadow.anchor.set(0.5, 0.5);
    shadow.zIndex = 100000;
    shadow.width = 100
    shadow.height = 100
    shadow.alpha = 0.1
    shadow.x = x
    shadow.y = y + shadowY
    shadow.startX = x
    obj.shad = shadow
    this.viewport.addChild(shadow);
  }

  populateClouds() {
    this.createCloud(400, 200, true, 250)
    this.createCloud(200, 350, true, 160)
    this.createCloud(700, 280, true, 200)
    this.createCloud(900, 200, true, 300)
    this.createCloud(450, 750, false, 0)
  }

  beforeInit() {
    this.loaderInfo = {
      text: "Carico U-City...",
      scene: "",
      // image: "ucity"
    }
    GameCore.callbacks.cbSetLoading(true, this.loaderInfo)

    window.addEventListener('resize', () => {
      // console.log('resize', this.viewport);
      // this.initMenu()
      if (this.viewport) {
        this.viewport.moveCenter(this.viewport.worldWidth / 2, this.viewport.worldHeight / 2)
      }

    })
  }

  onResize() {
    // console.log("resize", this.viewport);

    // if (this.viewport) {
    //   console.log(this.viewport.worldWidth);

    //   this.viewport.screenWidth = window.innerWidth
    //   this.viewport.screenHeight = window.innerHeight
    //   this.viewport.moveCenter(this.viewport.worldWidth / 2, this.viewport.worldHeight / 2)
    // }

  }

  loadAssets(resources: any, ...callbacks: any[]) {

    document.addEventListener('keypress', (e) => {
      this.logKey(e);
    });


    // GLOBAL EVENTS

    GlobalEventBus.$on('header-notifications-button-clicked', () => {
      // console.log('aprire notifiche');
      this.viewport.pause = true;
    });
    GlobalEventBus.$on('header-notifications-closed', () => {
      // console.log('chiudere notifiche');
      this.viewport.pause = false;
    });

    GlobalEventBus.$on('deselect-district', (key: string) => {
      this.unpressMenuButton(key)
    });
    
    GlobalEventBus.$on('new-district-available', (key: string) => {
      this.newDistrictAvailable(key)
    });

    GlobalEventBus.$on('end-game', () => {
      this.endGame()
    });

    GlobalEventBus.$on('pause-viewport', (pause: any) => {
      if (this.viewport) {
        this.viewport.pause = pause;
      }
    });

    GlobalEventBus.$on('show-final-quest', (first: boolean) => {
      this.showFinalQuest(first);
    });

    

    GlobalEventBus.$on('first-quest-fallita', () => { // questo evento è per il tutorial, quando fallisci la prima quest e hai solo un pin visibile
      // GlobalEventBus.$emit('set-footer-visibility', true);
      // console.log("fallita!");
      if(this.firstTimeInResidenziale) {
        // console.log("ciao fallita!");
        GlobalEventBus.$emit('hint', {text: 'Supera questa prova per proseguire nel gioco', disposeTimeoutMs: 0 });   
      }
    });

    GlobalEventBus.$on('highlight', (highlight: string) => {
      this.triggerHighlight(highlight)
    });
    GlobalEventBus.$on('first-quest-success', () => {
      // this.multiplayerFirstTimeUnlocked = true
      // console.log("CIALLA");
      
      setTimeout(() => {
        // this.highlightUpgrade()
        this.tutorialBoi = true;
        // GlobalEventBus.$emit('upgrades-button-active', true); 
        this.switchView(0);
        GlobalEventBus.$emit('set-footer-visibility', true); 
        GlobalEventBus.$emit('hint', {text: 'Seleziona la tab degli Upgrades con il tasto <i class="material-icons" style="font-size:14px; margin: 0 0 -4px 8px; transform: translateY(2px);" >build</i> ', disposeTimeoutMs: 0 }); 
        
      }, 1000);

    });



    GlobalEventBus.$on('exit-upgrade', () => {
      setTimeout(() => {
        this.initMenu();
      }, 500);
    });

    GlobalEventBus.$on('goto-district', (info: any) => {
      this.gotoDistrict(info)    
    });



    GameCore.callbacks.cbSetLoading(false, this.loaderInfo)
    this.rng = seedrandom("666");

    
    

    this.initMenu();
    // this.initDistrict('residenziale');
    this.initSound();

    

    GlobalEventBus.$emit('loaded')

    // console.log(GameCore.gameStatus.world.districts);
    

    this.app.ticker.add((delta: any) => this.renderLoop(delta))
    for (const cb of callbacks)
      if (cb) cb(resources)
  }

  // // 14.656
  initSound() {
    // console.log(audiosprite);
    
    this.sound = new Howl({
      src: [require('@/assets/audio/audiosprite.mp3'), require('@/assets/audio/audiosprite.ogg')],
      sprite: audiosprite.sprite,
      onloaderror: () => console.log('sound load error'),
      onload: () => { /* console.log('sound loaded') */ },
    });

    GlobalEventBus.$on('play-sound', (name: string) => {
      this.playSound(name);
    });
    GlobalEventBus.$on('stop-sound', (name: string) => {
      this.stopSound(name);
    });
    GlobalEventBus.$on('toggle-audio', (on: boolean) => {
      this.toggleAudio(on);
    });
  }

  playSound(name: string) {
    
    if (name == 'music' ) {
      if(!this.music) {
        this.music = this.sound.play(name);
        this.sound.fade(0, 1, 1000, this.music)
      } else {
        this.sound.fade(0, 1, 1000, this.music)
      }
      return;
    }
    if(this.sound) {
      this.sound.play(name);
    }
    
  }
  
  stopSound(name: string) {
    if (name == 'music' && this.music ) {
      this.sound.fade(this.sound.volume(this.music), 0, 1000, this.music)
      return;
    }
    this.sound.stop(name);
  }

  toggleAudio(on: boolean) {
    this.sound.mute(!on)

  }

  logKey(e: any) {
     
    this.cheatCode += e.key

    // console.log(this.cheatCode);
    
    if(this.cheatCode == 'quattro') {
      GlobalEventBus.$emit('kill-quattro', true);
      // console.log("killing quattro");
      this.cheatCode = ''
    } 
    if(this.cheatCode == 'ponte') {
      this.showFinalQuestSprite()
      this.cheatCode = ''
    }
    if(this.cheatCode == 'asd') {
      GameCore.gameStatus.isGameCompleted();
    }
    if(this.cheatCode == 'hint') {
      GlobalEventBus.$emit('hint', {text: 'Seleziona la tab delle Quests con il tasto <i class="material-icons">build</i> ', disposeTimeoutMs: 0 }); 
    }
    if(this.cheatCode == 'tutto') {
      this.tutorialBoi = true;
    }
    if(this.cheatCode == 'palle') {
      this.multiplayerFirstTimeUnlocked = true;
      this.triggerHighlight('residenziale')
      this.triggerHighlight('menu')
    }
    

    clearTimeout(this.cheatCodeTimeout)
    this.cheatCodeTimeout = setTimeout(() => {
      this.cheatCode = ''
    }, 1000);

  }

  initViewport(w: number, h: number) {

    // if(this.viewport != null) {
    // 	this.app.stage.addChild(this.viewport)
    // 	return;
    // }
    // console.log(this.currentScene);
    

    this.viewport = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: w,
      worldHeight: h
    })
    this.app.stage.addChild(this.viewport)

    let clampZoomMultiplier = 1
    if(window.innerWidth > window.innerHeight) {
      clampZoomMultiplier = window.innerWidth / window.innerHeight
    }

    this.viewport
      .drag()
      .pinch()
      .wheel()
      .decelerate()
      .clamp({
        left: 0,
        right: this.viewport.worldWidth,
        top: 0,
        bottom: this.viewport.worldHeight
      })
      .clampZoom({
        minWidth: 400,
        maxWidth: this.viewport.worldWidth * clampZoomMultiplier,
        minHeight: 500,
        // maxHeight: this.viewport.worldHeight
      }) // max: 800

    // CLAMP DIFFERENTLY IN MENU
    if(this.currentScene == "menu") {
      this.viewport.plugins.plugins["clamp-zoom"].options.maxHeight = this.viewport.worldHeight
    }

    this.viewport.on('mousedown', (e : any) => {
      // MOUSE POSITION mousepos
      const pos = this.viewport.toWorld(e.data.global);
      // console.log(pos);

      // this.createPonte(pos.x, pos.y, 'ponte-sud_ovest');
      
    })

    this.viewport.on('moved', () => {
      // console.log(this.viewport.center); 
      // return;
      if(this.lowQuality) return;
      this.updateCloudsOffset();
      
    })

    this.zoomCamera(0.5)
    this.viewport.moveCenter(this.viewport.worldWidth / 2, this.viewport.worldHeight / 2)
    this.viewport.sortableChildren = true;

    
  }

  updateCloudsOffset() {
    if(this.clouds) {
      this.clouds.forEach(cloud => {
        
        cloud.offsetX = ((this.viewport.worldWidth / 2) - this.viewport.center.x) * cloud.parallax
        cloud.offsetY = ((this.viewport.worldHeight / 2) - this.viewport.center.y) * cloud.parallax
        
      });       
    }
  }

  createFlyingObject() {
    let sprite = 'aeroplano'
    if(Math.random() < 0.5) sprite = 'mongolfiera'
    const obj = this.createSprite(sprite)
    obj.anchor.set(0.5, 0.5);
    obj.zIndex = 100000000000000000;
    obj.width = 60
    obj.height = 60
    obj.x = this.viewport.worldWidth + 100
    obj.y = 0;
    obj.speed = 0.1
    if(sprite == 'aeroplano') {
      obj.speed = 0.6
    }
    this.viewport.addChild(obj);
    this.flyingObj = obj;
    this.resetFlyingObjectPosition();
  }

  resetFlyingObjectPosition() {
    if(this.flyingObj) {
      this.flyingObj.x = this.viewport.worldWidth + 100
      this.flyingObj.y = -50 + Math.random() * (50 + this.viewport.worldHeight/2 ) 
    }
  }


  initDistrict(districtKey: any) {

    this.currentScene = districtKey;

    // console.log( this.firstTimeInResidenziale );
    if(this.firstTimeInResidenziale) {
      this.firstTimeInResidenziale = false;
    }
    

    this.waters = []
    this.clouds = []
    this.particles = []
    this.app.renderer.backgroundColor = 0xf5f5f5;

    const district = GameCore.gameStatus.world.districts.find(element => element.key == districtKey);
    GameCore.gameStatus.setCurrentDistrict(district!)
    // console.log(GameCore.gameStatus.currentDistrict);

    GameCore.callbacks.cbSetScene(1)

    this.clear();

    GameCore.callbacks.cbHideUpgrades()
    GameCore.callbacks.cbHideQuest()

    GlobalEventBus.$emit('play-sound', 'music');
    GlobalEventBus.$emit('set-footer-visibility', true); 

    // console.log('VIEWPORT ' , this.viewport);

    this.initViewport(this.worldSize, this.worldSize);
    this.zoomCamera(2.0)
    // this.viewport.moveCenter(this.worldSize , this.worldSize );
    this.viewport.moveCenter(this.viewport.worldWidth / 2, this.viewport.worldHeight / 2);

    // console.log(this.worldSize, this.viewport.worldWidth);

    // this.emitter = 
    const cont = new PIXI.Container();
    cont.zIndex = 1000000
    this.viewport.addChild(cont)
    // console.log(particles);
    this.emitter = new particles.Emitter(cont, [this.createTexture('particle')], particleExplosion)
    this.emitter.emit = false

    this.sparksEmitter = new particles.Emitter(cont, [this.createTexture('particle')], particleSparks)
    this.sparksEmitter.emit = false

    this.drawBackground();
    this.createBackgroundGradient();
    this.createBackgroundParticles();
    this.populateClouds()
    
    if(!this.lowQuality) {
      this.createFlyingObject();
    }
    

    this.tileWidth = this.viewport.worldWidth / this.numberOfColumns - this.mapOffset;
    this.tileHeight = this.tileWidth / 2;

    this.tileWidthHalf = this.tileWidth / 2;
    this.tileHeightHalf = this.tileHeight / 2;

    const blocks = GameCore.gameStatus.currentDistrictBlocks;

    // console.log(blocks);
    blocks.forEach((block: any) => {

      let texture = block.definition.sprite
      if (block.definition.classe == BlockClass.Bonificabile) {
        // console.log((block.position.x + block.position.y) * block.zone.id);
        texture += ("-" + Math.floor(this.rng((block.position.x + block.position.y) * block.zone.id) * 3))
      }
      // if(block.initialType == 80 || block.initialType == 81) {
      //   console.log(block);
      // }
      
      
      this.initBlock(block.position.x, block.position.y, block.definition.size.x, block.definition.size.y, block, texture)

    });

    this.currentView = -1
    this.switchView(0)

    GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {
      if (block.questSelector != null) {
        this.resetSelectorSize(block.questSelector)
      }
      // if (block.upgradeSelector != null) {
      //   this.resetSelectorSize(block.upgradeSelector)
      // }
    });

    this.setDistrictStats()
    // this.switchView(0)

    if (GameCore.gameStatus.allQuestCompleted) {
      // console.log("all quest completed");
      this.showFinalQuest(false);
    }

    GlobalEventBus.$emit('quattro', {
      eventKey: 'enter-' + districtKey
    });

    // if(districtKey == "residenziale" && !this.lowQuality) {
    //    // WATER
    //   for (let i = 0; i < 11; i++) {
    //     const water = this.createSprite('water')
    //     this.viewport.addChild(water)
    //     this.waters.push(water)
    //     water.zIndex = 11
    //     water.anchor.x = 0.5
    //     water.anchor.y = 0.5 
    //     water.width = 100
    //     water.height = 100
    //     water.timeOffset = Math.random() * 10000
    //     water.startScale = Math.random()
    //     water.position.x = this.viewport.worldWidth/2 + 50 * i + Math.random()*5 - 5
    //     // water.position.y = 100 + Math.random() * i * 25
    //     const maxY = 255 + i * 25 + Math.random()*5 - 5
    //     water.position.y =  maxY
    //   }

    //   for (let i = 0; i < 11; i++) {
    //     const water = this.createSprite('water')
    //     this.viewport.addChild(water)
    //     this.waters.push(water)
    //     water.zIndex = 11
    //     water.anchor.x = 0.5
    //     water.anchor.y = 0.5 
    //     water.width = 100
    //     water.height = 100
    //     water.timeOffset = Math.random() * 10000
    //     water.startScale = Math.random()
    //     water.position.x = this.viewport.worldWidth/2 + 50 * i + Math.random()*5 - 5
    //     // water.position.y = 100 + Math.random() * i * 25
    //     const maxY = 770 - i * 25 + Math.random()*5 -5
    //     water.position.y =  maxY
    //   }
    // }

  }

  gotoDistrict(info: any) {
    this.loaderInfo = info.loaderInfo
    GameCore.callbacks.cbSetLoading(true, this.loaderInfo)
    setTimeout(() => {
      this.initDistrict(info.key);
      GameCore.callbacks.cbSetLoading(false, this.loaderInfo)
    }, 1000);
  }

  initMenu() {
    this.clear();
    this.menuButtons = []
    this.particles = []
    
    this.switchView(0)

    GlobalEventBus.$emit('stop-sound', 'music');

    if(this.multiplayerFirstTimeUnlocked) {
      GlobalEventBus.$emit('set-header-visibility', false);  
    } else {
      GlobalEventBus.$emit('set-header-visibility', true);  
    }
    

    this.app.renderer.backgroundColor = 0xABC8CE;
    

    this.currentScene = "menu";
    GameCore.callbacks.cbSetScene(0)

    // console.log('VIEWPORT ' , this.viewport);

    this.initViewport(this.worldSize, this.worldSize);
    this.zoomCamera(0.23)
    this.viewport.moveCenter(this.viewport.worldWidth / 2 + 40, this.viewport.worldHeight / 2);

    // const bg = 'menu'
    // this.drawEnvironment(bg, -10);

    // console.log( GameCore.gameStatus );

    this.createBackgroundGradient();
    this.createBackgroundParticles()

    // MENU CLOUDS
    this.clouds = []
    this.createCloud(754, 440, false, 250)
    this.createCloud(300, 400, false, 250)
    this.createCloud(400, 700, false, 250)
    // this.createCloud(430, 290, false, 250)

    this.updateCloudsOffset();

    // ISOLA
    const sprite = this.createSprite('isola')
    this.viewport.addChild(sprite)
    sprite.zIndex = -1
    sprite.anchor.x = 0.5
    sprite.anchor.y = 1 
    sprite.width = 600
    sprite.height = 600/1.6
    sprite.position.x = 540
    sprite.position.y = 800

    // BUTTONS

    this.createMenuButton('residenziale', 495, 550)
    this.createMenuButton('commerciale', 400, 621)
    this.createMenuButton('industriale', 495, 647)
    this.createMenuButton('finanziario', 635, 621)

    // SELECTORS

    this.createDistrictSelector('residenziale', 500, 457)
    this.createDistrictSelector('commerciale', 395, 510)
    this.createDistrictSelector('industriale', 500, 550)
    this.createDistrictSelector('finanziario', 630, 480)

    // ISOLA MULTIPLAYER
    const sprite2 = this.createSprite('isola')
    this.viewport.addChild(sprite2)
    sprite2.zIndex = -1
    sprite2.anchor.x = 0.5
    sprite2.anchor.y = 1 
    sprite2.width = 150
    sprite2.height = 150/1.3
    sprite2.position.x = 560
    sprite2.position.y = 380

    // BUTTON MULTIPLAYER
    this.createMenuButton('multiplayer', 560, 320)

     // ALLOOOOOO
    //  this.multiplayerFirstTimeUnlocked = true;

    // MULTIPLAYER SELECTOR
    let multiplayerSelector = "lock_selector"
    let multiplayerUnlocked = false
    if (GameCore.gameStatus.firstDistrictStars > 0) {
      multiplayerSelector = "quest_selector"
      if (this.multiplayerFirstTimeUnlocked) {
        multiplayerSelector = "quest_selector_highlight"
        // multiplayerSelector.width *= 2

        

        GlobalEventBus.$emit('hint', {text: 'Accedi al multiplayer cliccando sull’icona attiva', disposeTimeoutMs: 0 });  

      }
      multiplayerUnlocked = true
    }
    const selector2 = this.createSelector(multiplayerSelector, 2250 / 4, 0, this.selectorSize, this.selectorSize, () => {
      if (multiplayerUnlocked) {
        GameCore.callbacks.cbShowMultiplayer();
        this.playSound('selezione_icona')
        if (this.multiplayerFirstTimeUnlocked) { // TUTORIAL
          this.multiplayerFirstTimeUnlocked = false;
          
        }
      } else {
        console.log("multiplayer LOCKED");
      }
    })
    
    
   


    selector2.highlighted = this.multiplayerFirstTimeUnlocked
    selector2.name = 'multiplayer'
    selector2.startY = 940 / 4
    selector2.timeOffset = Math.random() * 1000
    selector2.y = selector2.startY
    this.resetMenuSelectorSize(selector2)
    this.menuSelectors.push(selector2)

    if (this.multiplayerFirstTimeUnlocked) { // TUTORIAL
      
      selector2.width *= 1.5
      selector2.height *= 1.8
      
    }
        
    /// END MULTI SELECTOR
       

    GlobalEventBus.$emit('quattro', { eventKey: 'enter-menu' });

    if(this.lowQuality) return;

    //SMOKE
    // const cont = new PIXI.Container();
    // cont.zIndex = 10000
    // this.viewport.addChild(cont)
    // this.smokeEmitter = new particles.Emitter(cont, [this.createTexture('particle')], particleSmoke)
    // this.smokeEmitter.emit = true
    // this.smokeEmitter.spawnCircle.x = 670
    // this.smokeEmitter.spawnCircle.y = 450

    // WATER
    // for (let i = 0; i < 20; i++) {
    //   const water = this.createSprite('water')
    //   this.viewport.addChild(water)
    //   this.waters.push(water)
    //   water.zIndex = -11
    //   water.anchor.x = 0.5
    //   water.anchor.y = 0.5 
    //   water.width = 100
    //   water.height = 100
    //   water.timeOffset = Math.random() * 10000
    //   water.position.x = 30 + 50 * i
    //   water.startScale = Math.random()
    //   // water.position.y = 100 + Math.random() * i * 25
    //   const maxY = 200 + i * 25
    //   water.position.y = Math.random() * maxY
    // }
    
    // const water = this.createSprite('circle')
    // this.viewport.addChild(water)
    // this.waters.push(water)
    // water.zIndex = -11
    // water.anchor.x = 0.5
    // water.anchor.y = 0.5 
    // water.width = 100
    // water.height = 100
    // water.isCircle = true
    // water.timeOffset = Math.random() * 10000
    // water.position.x = 560
    // water.position.y = 338
    
  }

  createMenuButton(buttonName: string, x: number, y: number) {
    const sprite = this.createSprite(buttonName + '_idle')
    this.viewport.addChild(sprite)
    this.menuButtons.push(sprite)
    sprite.name = buttonName
    sprite.zIndex = 0
    sprite.anchor.x = 0.5
    sprite.anchor.y = 1 
    sprite.width = 200
    sprite.height = 200
    sprite.position.x = x
    sprite.position.y = y
  }

  pressMenuButton(buttonName: string){
    this.menuButtons.forEach(element => {
      if(element.name == buttonName ) {
        // console.log(element);
        const newTexture = PIXI.Texture.from( buttonName + '_pressed');
        element.texture = newTexture;
      }
    });
  }

  unpressMenuButton(buttonName: string){
    this.menuButtons.forEach(element => {
      if(element.name == buttonName ) {
        // console.log(element);
        const newTexture = PIXI.Texture.from( buttonName + '_idle');
        element.texture = newTexture;
      }
    });
  }

  createDistrictSelector(districtKey: string, x: number, y: number) {


    if(this.multiplayerFirstTimeUnlocked) {
     return; 
    }

    let sel = "lock_selector"
    let isUnlocked = false
    if(GameCore.gameStatus.getDistrictByKey(districtKey) != null) {
      isUnlocked = GameCore.gameStatus.getDistrictByKey(districtKey)!.isUnlocked
    } else {
      console.log("district key sbagliata ", districtKey);
      
    }
    // console.log(isUnlocked);
    
    
    
    if( isUnlocked ) {
      sel = "quest_selector"
    }
    const selector = this.createSelector(sel, x, 0, this.selectorSize, this.selectorSize, () => {
      if(isUnlocked) {
        this.pressMenuButton(districtKey)
        this.playSound('selezione_icona')
        GlobalEventBus.$emit('select-district', {
          key: districtKey,
          loaderInfo: {
            text: "Stai andando al",
            scene: "Quartiere " + districtKey,
            image: "icons/" + districtKey
          }
        })
      } else {
        GlobalEventBus.$emit('play-sound', 'feedback_negativo');
        console.log(districtKey + " is LOCKED");
      }
    })
    selector.name = districtKey
    selector.startY = y
    selector.timeOffset = Math.random() * 1000
    selector.y = selector.startY
    this.resetMenuSelectorSize(selector)
    this.menuSelectors.push(selector)
  }


  initBlock(x: number, y: number, width: number, depth: number, block: any, texture: any) {

    let worldX = (x - y) * this.tileWidthHalf
    let worldY = (x + y) * this.tileHeightHalf + this.tileHeightHalf
    worldX += this.viewport.worldWidth / 2
    worldY += this.viewport.worldHeight / 2 - this.tileHeightHalf * (this.numberOfColumns - 1)

    let spriteSize = this.tileWidth
    // let depthOffset = 0

    if (width == 1 && depth == 1) {
      spriteSize = this.tileWidth
    }
    if (width == 2 && depth == 1) {
      spriteSize = this.tileWidth * 1.5
      worldX -= this.tileWidthHalf / 2
      // depthOffset = -1
    }
    if (width == 1 && depth == 2) {
      spriteSize = this.tileWidth * 1.5
      worldX += this.tileWidthHalf / 2
      // depthOffset = -1
    }
    if (width == 2 && depth == 2) {
      spriteSize = this.tileWidth * 2
      // depthOffset = -1
    }
    if (width == 3 && depth == 1) {
      spriteSize = this.tileWidth * 2
      worldX -= this.tileWidthHalf
      // depthOffset = -2
    }

    const sprite = this.createSprite(texture)
    sprite.anchor.set(0.5, 1);
    sprite.x = worldX;
    sprite.y = worldY;
    sprite.width = spriteSize
    sprite.height = spriteSize
    sprite.zIndex = block.zIndex

    this.viewport.addChild(sprite);

    block.sprite = sprite
    block.worldPos = {
      x: worldX,
      y: worldY
    }
    block.timeOffset = Math.random() * 10

    const selectorCenter = block.definition.center
    selectorCenter.x /= 4
    selectorCenter.y /= 4

    if (block.upgradeSelector != null) {
      block.upgradeSelector = null
    }
    if (block.questSelector != null) {
      block.questSelector = null
    }

    // INIT UPGRADE SLEECTOR
    block.upgradeSelector = this.createSelector("upgrade_selector", worldX + selectorCenter.x, worldY - this.tileHeight - selectorCenter.y, this.districtSelectorSize, this.districtSelectorSize, () => {
      // if(this.isUpgrading) return
      this.showUpgrades(block)
      this.focusOn(worldX, worldY)
    })
    block.upgradeSelector.scale = {
      x: 0,
      y: 0
    }
    block.upgradeSelector.timeOffset = Math.random() * 1000
    block.upgradeSelector.startScale = this.selectorsStartScale

    // INIT QUEST SELETOR
    if (block.isQuestBlock) {

      // console.log(block.zone.relatedQuest.progress.status);
      

      let sel = 'quest_selector'
      if (!block.zone.hasRelatedQuest) {
        sel = 'quest_selector_disabled'
      } else if (block.zone.relatedQuest.progress.status == 'passed') {
        sel = 'quest_selector_passed'
      }
      block.questSelector = this.createSelector(sel, worldX + selectorCenter.x, worldY - this.tileHeight - selectorCenter.y, this.districtSelectorSize / 1.5, this.districtSelectorSize / 1.5, () => {
        // console.log("MADONNA CHE CORE BLOCK!", block );
        if(!block.zone.hasRelatedQuest) {
          console.log("no quest found!");
          return;
        }
        this.playSound('selezione_icona')
        GameCore.callbacks.cbToggleQuest(true, block.zone.relatedQuest, block.definition.name)
        this.currentQuestBlock = block
        GlobalEventBus.$emit('pause-viewport', true);
        // this.focusOn(worldX, worldY)

        // RESET HIGHLITED BLOCK
        if (block.questSelector.highlighted) {
          block.questSelector.highlighted = false;
          
          // block.questSelector.width *= 2;
          // block.questSelector.height *= 2;

          const newTexture = PIXI.Texture.from('quest_selector');
          block.questSelector.anchor.y = 1
          block.questSelector.texture = newTexture;
          this.showSelector(block.questSelector)
        }
      })
      block.questSelector.timeOffset = Math.random() * 1000
      block.questSelector.startScale = this.selectorsStartScale
      block.questSelector.enabled = block.zone.hasRelatedQuest;
      block.questSelector.passed = (block.zone.hasRelatedQuest && block.zone.relatedQuest.progress.status == 'passed');
      this.updateQuestSelector(block)
    }

    // DEBUG SHIT
    if (this.debug) {
      const x = block.worldPos.x
      const y = block.worldPos.y

      const text = new PIXI.Text(block.key + "\n" + block.completionPercentage + "%", {
        fontFamily: 'Arial',
        fontSize: 32,
        fill: 0xffff00,
        dropShadow: true,
        dropShadowBlur: 10,
        align: 'center'
      });
      text.x = x
      text.y = y - 150
      text.zIndex = 100000000000000
      text.anchor.x = 0.5
      text.anchor.y = 0.5
      this.viewport.addChild(text)
      block.debugText = text;
    }

    this.updateBlock(block);
  }

  resetSelectorSize(selector: any) {
    // selector.scaleX = 0.1
    // selector.scaleY = 0.1

    let scale = 0.1

    if(selector.highlighted) {
      scale = 0.15
    }

    ease.add(selector, {
      scale: scale
    }, {
      reverse: false,
      duration: 10,
      ease: 'easeOutBack',
      wait: 0
    })
    
  }

  resetMenuSelectorSize(selector: any) {
    // selector.scaleX = 0.1
    // selector.scaleY = 0.1

    let scale = 0.15

    if (this.multiplayerFirstTimeUnlocked || selector.highlighted) { // TUTORIAL
      
      scale = 0.2
      
    }

    ease.add(selector, {
      scale: scale
    }, {
      reverse: false,
      duration: 10,
      ease: 'easeOutBack',
      wait: 0
    })
  }

  updateQuestSelector(block: any) {
    if (block.isQuestBlock && block.zone.hasRelatedQuest) {

      // ritorna se è già nello stato giusto
      if (block.questSelector.passed && block.zone.relatedQuest.progress.status == 'passed' ||
        !block.questSelector.passed && block.zone.relatedQuest.progress.status != 'passed') {
        return;
      }

      if (block.zone.relatedQuest.progress.status == 'passed') {
        block.questSelector.passed = true
      } else {
        block.questSelector.passed = false
      }

      let sel = 'quest_selector'
      if (block.questSelector.passed) {
        sel = 'quest_selector_passed'
      }
      const newTexture = PIXI.Texture.from(sel);
      block.questSelector.texture = newTexture;
    }
  }

  triggerHighlight(highlight: string) {
    // console.log('HIGHLIGHT = ', highlight);

    if (highlight == 'residenziale') {
      GlobalEventBus.$emit('set-footer-visibility', false); 
      GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {
        if (block.isQuestBlock && block.zone.key == 'biblioteca') {
          // console.log(block);
          const newTexture = PIXI.Texture.from('quest_selector_highlight');
          block.questSelector.anchor.y = 0.75
          block.questSelector.texture = newTexture;
          block.questSelector.highlighted = true
          
          // block.questSelector.width *= 1.5
          // block.questSelector.height *= 1.8

        } else {
          if(block.isQuestBlock && block.questSelector) {
            setTimeout(() => {
              this.hideSelector(block.questSelector)
            }, 800);
          }
        }
      });
      this.firstTimeInResidenziale = true
    }
  
    if (highlight == 'menu') {
      
      this.menuSelectors.forEach((selector: any) => {
        if (selector.name == 'residenziale') {
          // console.log(selector);
          const newTexture = PIXI.Texture.from('quest_selector_highlight');
          selector.anchor.y = 0.75
          selector.texture = newTexture;
          selector.highlighted = true

          // selector.width *= 1.5
          // selector.height *= 1.8

          this.resetMenuSelectorSize(selector)
        } else {
          this.hideSelector(selector);
        }
      });
    }

  }

  highlightUpgrade() {
    GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {
      // if is bonificabile
      if (  block.isUpgradable && block.definition.classe == 1 ) {
        // console.log(block);
        const newTexture = PIXI.Texture.from('upgrade_selector_highlight');
        block.upgradeSelector.anchor.y = 0.75
        block.upgradeSelector.texture = newTexture;
        block.upgradeSelector.highlighted = true
        // this.resetSelectorSize(block.upgradeSelector)
        this.showSelector(block.upgradeSelector);
      } else if (block.upgradeSelector) {
        // this.hideSelector(block.upgradeSelector);
        setTimeout(() => {
          this.hideSelector(block.upgradeSelector);
        }, 100);
      }
    });
  }

  showFinalQuest(isOnQuestCompleted: boolean) {
    // console.log("showing final quest. district: ", GameCore.gameStatus.currentDistrict.key);
    // GlobalEventBus.$emit('quattro', {
    //   eventKey: 'unlocked-final-' + GameCore.gameStatus.currentDistrict.key
    // });

    if(isOnQuestCompleted) {
      setTimeout(() => {
        this.showFinalQuestSprite()  
      }, 500);
    } else {
      this.showFinalQuestSprite()
    }
    
  }

  createPonte(x: number, y: number, sprite: string) {

    // DEBUG LINES
    // const graphics = new PIXI.Graphics();
    // graphics.lineStyle(1, 0xFEEB77, 1);
    // graphics.drawRect(x - 100, y - 100, 200, 200);
    // graphics.zIndex = 10000000000
    // this.viewport.addChild(graphics);
    //// END

    const obj = this.createSprite(sprite)
    obj.anchor.set(0.5, 0.5);

    obj.x = x;
    obj.y = y;

    obj.width = 200;
    obj.height = 200;
    obj.zIndex = 100;
    if(sprite == 'rampa_lancio') obj.zIndex = 10000
    this.viewport.addChild(obj);

    this.finalQuestSelector = this.createSelector("quest_selector_2", x, y , this.districtSelectorSize * 0.75, this.districtSelectorSize * 0.75, () => {
      const finalQuest = GameCore.gameStatus.getFinalQuest();
      // console.log("FINAL QUEST: ", finalQuest);
      this.playSound('selezione_icona')
      GameCore.callbacks.cbToggleQuest(true, finalQuest, "final quest")
    })
    this.finalQuestSelector.startPos = this.finalQuestSelector.y
    this.resetSelectorSize(this.finalQuestSelector)
  }

  showFinalQuestSprite() {
    // console.log(GameCore.gameStatus.currentDistrict.key);
    
    switch (GameCore.gameStatus.currentDistrict.key) {
      case 'residenziale': {
        this.createPonte(210,620, 'ponte-nord_est')
        break;
      }

      case 'commerciale': {
        this.createPonte(612, 720, 'ponte-2')
        break;
      }

      case 'industriale': {
        this.createPonte(882,346, 'ponte-sud_ovest')
        break;
      }

      case 'finanziario': {
        
        // this.createPonte(210,620, 'ponte-nord_est')
        this.createPonte(810,600, 'rampa_lancio')

        // console.log("BASE SPAZIALEEEEEE");
        
        
        break;
      }

      default:
        console.log("PONTE NON IMPOSTATO!");
        break;
    }
  }

  focusOn(x: number, y: number) {
    this.viewport.snap(x, y, {
      removeOnComplete: true,
      forceStart: true,
      ease: 'easeInOutQuint',
      interrupt: false
    })
    this.viewport.snapZoom({
      width: 200,
      time: 1000,
      removeOnComplete: true,
      ease: 'easeInOutCirc',
      interrupt: false
    })
  }

  endGame () {
    // console.log("END GAME uwu");
    // GlobalEventBus.$emit('notice', {
    //   text: '',
    //   video: true
    // } );
  }

  newDistrictAvailable(key: string) {

    if(key == null) {
      if(GameCore.gameStatus.currentDistrict.key == 'finanziario') {
        // console.log("finito gioco finanza");
        return;
      }
      GlobalEventBus.$emit('notice', {text: 'Complimenti! Il nuovo quartiere arriverà presto'} );
      return;
    }

    let isoDate = null

    if(GameCore.gameStatus.getDistrictByKey(key) == null) return;
    isoDate = GameCore.gameStatus.getDistrictByKey(key)!.unlocksFromDate;

    if(isoDate != null){
      const date = new Date(isoDate)
      const now = new Date();

      const d = date.getDate();  // returns 15
      const m = date.getMonth(); // returns 6
      const y = date.getFullYear();  // returns 2012

      // console.log(date, now);
      const readable = d + "-" + m  + "-" + y;
      if(date > now) {
        GlobalEventBus.$emit('notice', {text: 'Complimenti! Il quartiere ' + key + ' sarà disponibile il ' + readable + '.', button: 'Vai al nuovo quartiere'} );
      } else {
        GlobalEventBus.$emit('notice', {text: 'Complimenti! Ora puoi accedere al quartiere ' + key} );
      }
      
    }
  }

  onQuestClosed() {
    // const initialStars = this.currentQuestBlock.zone.relatedQuest.progress.stars
    // GameCore.gameStatus.setZoneStars(this.currentQuestBlock.zone, stars )
    if (this.currentQuestBlock == null) return;

    const currentStars = this.currentQuestBlock.zone.relatedQuest.progress.stars
    // console.log( currentStars);
    if (currentStars > 0) { // quest success
      this.switchView(1)
      this.playSound('feedback_positivo')
      this.updateQuestSelector(this.currentQuestBlock)
      // if (initialStars == 0) {
      // 	// console.log("HAI SBLOCCATO ZONA!");
      // }
    }

    // console.log('all quest completed ' , GameCore.gameStatus.allQuestCompleted);
    // if (GameCore.gameStatus.allQuestCompleted ) {
    //   // console.log("all quest completed");
    //   this.showFinalQuest(true);
    // }

    this.setDistrictStats()
  }

  setDistrictStats() {
    const zones = GameCore.gameStatus.currentDistrictZones.map(x => this.getZoneData(x))
    // console.log(zones);
    GameCore.callbacks.cbSetStats(zones)
  }

  getZoneData(zone: any) {
    // console.log(zone);
    const stars = []
    for (let i = 0; i < 3; i++) {
      if (zone.hasRelatedQuest && i < zone.relatedQuest.progress.stars) {
        stars[i] = 1
      } else {
        stars[i] = 0
      }
    }
    const zoneStats = {
      name: zone.name,
      stars: stars
    }
    return zoneStats
  }

  updateBlock(block: any) {
    const selectorCenter = block.definition.center
    block.selectorPos = {
      x: block.worldPos.x - selectorCenter.x,
      y: block.worldPos.y - this.tileHeight - selectorCenter.y
    }

    if(block.upgradeSelector) {
      block.upgradeSelector.startY = block.selectorPos.y
      block.upgradeSelector.timeOffset = Math.random() * 1000
    }
    if(block.questSelector) {
      block.questSelector.startY = block.selectorPos.y
    }

    if (this.currentView == 1) {
      if (!block.isUpgradable) {
        this.hideSelector(block.upgradeSelector)
      } else {
        this.setBlockSelectorTexture(block)
        this.showSelector(block.upgradeSelector)
      }
    }

    if (this.debug && block.debugText) {
      block.debugText.text = block.key + "\n" + block.completionPercentage + "%"
    }


  }

  async refreshBlockUI(block: any) {

    const shake = ease.add(block.sprite, {
      shake: 4
    }, {
      reverse: false,
      duration: 400,
      ease: 'easeInOutQuint'
    })
    shake.once('complete', () => {
      this.emitter.emit = true;
      this.emitter.spawnCircle.x = block.worldPos.x
      this.emitter.spawnCircle.y = block.worldPos.y - 120 / 4

      GlobalEventBus.$emit('play-sound', 'feedback_positivo')
      // GlobalEventBus.$emit('pause-viewport', false);

      // this.isUpgrading = false
      // this.viewport.pause = false

      // this.sparksEmitter.emit = true;
      // this.sparksEmitter.spawnCircle.x = block.worldPos.x
      // this.sparksEmitter.spawnCircle.y = block.worldPos.y - 120

      // BLOCK UPGRADE ANIMATION

      const tex = this.createTexture(block.definition.sprite);
      block.sprite.texture = tex;
      this.updateBlock(block);
      this.currentBlockToUpgrade = null;
      GameCore.callbacks.cbHideUpgrades()

      // this.playSound('confirmation')

      setTimeout(() => {
        GlobalEventBus.$emit('quattro', {
          eventKey: 'exit-upgrade'
        });
      }, 600);

    });
  }

  getUpgradesDefinitions(blockType: BlockType) {
    const definition = GameCore.gameStatus.getBlockDefinitionByBlockType(blockType)
    // console.log(definition);
    let totalSize = 1
    if (definition.size.x == 2 && definition.size.y == 2) {
      totalSize = 4
    } else if ((definition.size.x == 1 && definition.size.y == 2) || (definition.size.x == 2 && definition.size.y == 1)) {
      totalSize = 2
    }

    let purchasable = GameCore.gameStatus.player.credits >= GameCore.gameStatus.getBlockDefinitionByBlockType(blockType).price
    if (definition.classe == BlockClass.Edificabile) {
      purchasable = true
    }
    return {
      name: this.getNameByBlockType(blockType),
      imageUrl: this.getSpriteUrlByBlockType(blockType),
      type: blockType,
      class: definition.classe,
      price: definition.price,
      purchasable: purchasable,
      totalSize: totalSize
    }
  }

  showUpgrades(block: any) {

    // console.log(block.sprite.texture.baseTexture.textureCacheIds[1]);


    // this.isUpgrading = true;
    // this.viewport.pause = true

    // console.log(block);

    const allUpgrades = block.upgrades.map((x: any) => this.getUpgradesDefinitions(x));    
    const currentBlockInfo = {
      text: block.definition.sprite,
      // imageUrl: this.getSpriteUrl(block.sprite),
      imageUrl: block.sprite.texture.baseTexture.textureCacheIds[1],
      type: block.type,
      class: block.definition.classe
    };
    GameCore.callbacks.cbShowUpgrades(block, currentBlockInfo, allUpgrades);

    // show back selector of last selected block
    this.showLastSelector()
    // hide selector of currently selected
    this.hideSelector(block.upgradeSelector)

    // console.log('enter upgrade');
    GlobalEventBus.$emit('quattro', {
      eventKey: 'enter-upgrade'
    });

    this.currentBlockToUpgrade = block
  }

  getNameByBlockType(blockType: BlockType) { // TODO return the name, not the sprite
    return GameCore.gameStatus.getBlockDefinitionByBlockType(blockType).name
  }

  getSpriteUrlByBlockType(blockType: BlockType) {

    const textureName = GameCore.gameStatus.getBlockDefinitionByBlockType(blockType).sprite
    return PIXI.Loader.shared.resources[textureName].url
  }

  getSpriteUrl(spriteName: string) {
    return PIXI.Loader.shared.resources[spriteName].url
  }

  getSpriteUrlByCharacterName(characterName: string) {
    return PIXI.Loader.shared.resources[characterName].url
  }

  showLastSelector() {
    if (this.currentBlockToUpgrade != null) {
      this.showSelector(this.currentBlockToUpgrade.upgradeSelector)
    }
  }

  drawBackground() {

    let sprite = 'map_residenziale'
    if(GameCore.gameStatus.currentDistrict.key != null) {
      sprite = 'map_' + GameCore.gameStatus.currentDistrict.key
    }
    
    const obj = this.createSprite(sprite)
    obj.anchor.set(0, 0);

    // obj.x = 0;
    // obj.y = this.mapOffsetY - 20;
    // obj.x += this.mapOffset * 3 - 100
    // obj.x -= this.roadOffset

    const sizeOffset = 50
    const backgroundOffsetX = 25
    const backgroundOffsetY = 52

    obj.width = this.viewport.worldWidth + sizeOffset;
    obj.x -= backgroundOffsetX

    obj.height = this.viewport.worldHeight + sizeOffset;
    obj.y -= backgroundOffsetY

    obj.zIndex = -10;
    this.viewport.addChild(obj);
  }

  renderLoop(delta: number) {

    if(this.lowQuality) return;

    this.time += delta
    // const sinBase = Math.sin(this.time * 0.05)

    if(this.flyingObj) {
      this.flyingObj.x -= delta * this.flyingObj.speed
      this.flyingObj.y += delta/2 * this.flyingObj.speed
      if(this.flyingObj.y > this.viewport.worldHeight + 100) {
        this.resetFlyingObjectPosition()
      }
    }

    if (this.emitter && this.sparksEmitter) {
      this.emitter.update(delta * 0.01);
      this.sparksEmitter.update(delta * 0.01);
    }
    if (this.smokeEmitter) {
      this.smokeEmitter.update(delta * 0.01);
    }
    // console.log(this.waters);

    if(this.particles) {
      this.particles.forEach(obj => {
        obj.x = obj.startX + obj.offsetX + (Math.sin(this.time * 0.001 + obj.timeOffset ) + 1) * 10
        obj.y = obj.startY + obj.offsetY + (Math.sin(this.time * 0.004 + obj.timeOffset ) + 1) * 10
        obj.scale.x = (Math.sin(this.time * 0.01 + obj.timeOffset ) + 1) * 0.1
        obj.scale.y = (Math.sin(this.time * 0.01 + obj.timeOffset ) + 1) * 0.1
      });
    }
    
    // this.waters.forEach(water => {
    //   if(water.isCircle) {
    //     water.width = 140 + (Math.sin(this.time * 0.03 + water.timeOffset ) + 1) / 2 * 5
    //     water.height = 75 + (Math.sin(this.time * 0.03 + water.timeOffset ) + 1) / 2 * 2
    //   } else {
    //     water.width = (Math.sin(this.time * 0.03 + water.timeOffset ) + 1) * 10 * water.startScale
    //     water.height = (Math.sin(this.time * 0.03 + water.timeOffset ) + 1) * 10 * water.startScale
    //   }
    // });

    if(this.clouds) {
      this.clouds.forEach(obj => {
        obj.x = obj.startX + obj.offsetX + (Math.sin(this.time * 0.001 + obj.timeOffset ) + 1) * 10
        obj.y = obj.startY + obj.offsetY + (Math.sin(this.time * 0.004 + obj.timeOffset ) + 1) * 10
        if(obj.hasShadow) {
          obj.shad.x = obj.shad.startX + obj.offsetX
        }
      });
    }
    

    this.menuSelectors.forEach((selector: any) => {
      this.animateSelector(selector)
    });

    GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {
      if (this.currentView == 0 && block.questSelector != null) {
        this.animateSelector(block.questSelector)
        // block.questSelector.y = block.selectorPos.y + Math.sin(this.time * 0.01 + block.timeOffset) * 10 / 4
      }
      if (this.currentView == 1) {
        this.animateSelector(block.upgradeSelector)
        // block.upgradeSelector.y = block.selectorPos.y + Math.sin(this.time * 0.01 + block.timeOffset) * 10 / 4
      }

    });

    if (this.finalQuestSelector) {
      this.finalQuestSelector.y = this.finalQuestSelector.startPos + Math.sin(this.time * 0.01) * 10 / 4
    }
  }

  animateSelector(selector: any) {
    selector.y = selector.startY + Math.sin(this.time * 0.03 + selector.timeOffset) * 5 / 4
    return;
    // if(selector.highlighted) {
    //   console.log(selector);
      
    //   selector.width = 20;
    //   selector.height = 20;
    //   // selector.y = selector.startY
    //   // selector.transform.scale.x = selector.startScale + (Math.sin(this.time * 0.1 ) + 2)/2 * selector.startScale / 4
    //   // selector.transform.scale.y = selector.startScale + (Math.cos(this.time * 0.1 ) + 2)/2 * selector.startScale / 4
    // } 
    // // else {
    // //   selector.y = selector.startY + Math.sin(this.time * 0.03 + selector.timeOffset) * 5 / 4
    // // }
  }

  initMenuTrans() {


    this.loading = false
    setTimeout(() => {
      this.loading = false
      this.initMenu()
    }, 200)
  }

  switchView(view: any) {

    if (this.currentView == view || this.isSwitchingView) {
      return
    }

    this.isSwitchingView = true
    this.currentView = view

    
    setTimeout(() => {
      this.isSwitchingView = false
        GameCore.callbacks.cbSwitchView(view)
    }, 700)

    // se ho zero stelle mando un alert
    if (this.currentView == 1 && GameCore.gameStatus.currentDistrictStars <= 0) {
      // console.log('current district stars', GameCore.gameStatus.currentDistrictStars);
      GlobalEventBus.$emit('hint', {
        text: 'Devi completare almeno una quest per effettuare gli upgrade!',
        disposeTimeoutMs: 10000
      });
    }

    // SE SONO IN TUTORIAL
    if( this.currentView == 1 && this.tutorialBoi) {

      GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {
        if (block.questSelector) {
          this.hideSelector(block.questSelector)
        }
        if (block.upgradeSelector) {
          this.hideSelector(block.upgradeSelector)
        }
      })

      setTimeout(() => {
        this.highlightUpgrade()
        // console.log("CENCIO");  
        this.multiplayerFirstTimeUnlocked = true  
        GlobalEventBus.$emit('hint', {text: 'Clicca sull\'icona attiva', disposeTimeoutMs: 0 }); 
        GlobalEventBus.$emit('upgrades-button-active', false); 
        this.tutorialBoi = false;
      }, 500);

      
      return;
    }

    // se sono in upgrade view nascondo final quest
    if(this.finalQuestSelector) {
      if(this.currentView == 1) {
        this.hideSelector(this.finalQuestSelector)
      } else if (this.currentView == 0) {
        this.showSelector(this.finalQuestSelector)
      }
    }
    

    GameCore.gameStatus.currentDistrictBlocks.forEach((block: any) => {

      if (block.upgradeSelector || block.questSelector) {
        if (this.currentView == 0) { // QUEST VIEW
          this.hideSelector(block.upgradeSelector)
          setTimeout(() => {
            if (block.questSelector != null) {
              this.showSelector(block.questSelector)
            }
          }, 400)
        }
        if (this.currentView == 1) { // UPGRADE VIEW
          if (block.questSelector != null) {
            this.hideSelector(block.questSelector)
          }
          setTimeout(() => {
            if (block.isUpgradable) {

              this.setBlockSelectorTexture(block)
              this.showSelector(block.upgradeSelector)
            }
          }, 400)
        }
      }


    });
  }

  setBlockSelectorTexture(block: any) {
    if (block.definition.classe == BlockClass.Bonificabile) { // if block is macerie
      // console.log("MACERIE! ", block.upgrades);
      const tex = this.createTexture('recycle_selector')
      block.upgradeSelector.texture = tex
    } else {
      const tex = this.createTexture('upgrade_selector')
      block.upgradeSelector.texture = tex
    }
  }


  hideSelector(selector: any) {
    const delay = Math.random() * 300
    /*const example = */
    ease.add(selector, {
      scaleY: 0
    }, {
      reverse: false,
      duration: 300,
      ease: 'easeInBack',
      wait: delay
    })
    // example.once('complete', () => console.log('move ease complete.'))
    /*const example2 = */
    ease.add(selector, {
      scaleX: 0
    }, {
      reverse: false,
      duration: 300,
      ease: 'easeInQuart',
      wait: delay
    })
    // example2.once('complete', () => console.log('move 2 2 2 2 2 2 ease complete.'))
  }

  showSelector(selector: any) {
    /*const example = */
    ease.add(selector, {
      scale: 0.1
    }, {
      reverse: false,
      duration: 300,
      ease: 'easeOutBack',
      wait: Math.random() * 300
    })
    // example.once('complete', () => console.log('move ease complete.'))
  }

  createSelector(_sprite: any, x: number, y: number, w: number, h: number, callback: any) {

    

    // const selector = new PIXI.Container();
    const sprite = this.createSprite(_sprite)
    sprite.position.set(x, y)
    sprite.anchor.set(0.5, 1)
    sprite.w = w;
    sprite.h = h;
    sprite.width = w;
    sprite.height = h;
    sprite.zIndex = 500000

    

    const button = this.createButton(0, 0, w * 6, h * 7, () => {
      callback()
      GlobalEventBus.$emit('close-hint')
      // this.playSound('tick');
    });

    // selector.addChild(sprite)
    sprite.addChild(button)
    this.viewport.addChild(sprite);

    return sprite;
  }

  createButton(x: number, y: number, w: number, h: number, callback: any) {
    const button = new PIXI.Sprite();
    // const button = new PIXI.Sprite(PIXI.Loader.shared.resources['square'].texture);
    // this.viewport.addChild(button);
    button.x = x - w / 2;
    button.y = y - h;
    button.width = w;
    button.height = h;
    button.interactive = true;
    button.zIndex = 999999999
    button.on('pointerdown', function () {
      callback()
      document.body.style.cursor = 'auto';
    });
    button.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });
    button.on('mouseout', function () {
      document.body.style.cursor = 'auto';
    });
    return button
  }




  drawEnvironment(spriteId: any, zIndex: number) {
    const obj2 = this.createSprite(spriteId);
    obj2.anchor.set(0, 0);
    obj2.x = 0;
    obj2.y = 0;
    obj2.width = this.viewport.worldWidth;
    obj2.height = this.viewport.worldHeight;
    obj2.zIndex = zIndex;
    this.viewport.addChild(obj2);
  }

  //
  // UI STUFF
  //


  // HELPERS

  zoomOut() {
    this.zoomCamera(0.1)
    this.viewport.moveCenter(this.viewport.worldWidth / 2, this.viewport.worldHeight / 2)
  }

  zoomTo(x: number, y: number) {

    // this.zoomCamera(2)
    // this.viewport.moveCenter(x,y);

    this.cameraZoomTarget = 2;
    this.cameraTarget = {
      x: x,
      y: y
    }
  }

  zoomCamera(z: any) {
    this.viewport.setZoom(z)
    this.currentCameraZoom = z
  }

  createSprite(textureName: string) {
    // console.log(textureName);
    return new PIXI.Sprite(
      PIXI.Loader.shared.resources[textureName].texture
    )
  }

  createTexture(textureName: string) {
    // console.log(textureName);
    try {
      return new PIXI.Texture(
        PIXI.Loader.shared.resources[textureName].texture
      )
    } catch (e) {
      // console.log('textureName', textureName);
    }
  }

  getContainer() {
    return this.app.view
  }

  clear() {
    if (this.viewport) {
      // console.log(this.viewport);
      this.viewport.removeChildren()
    }
    // console.log(this.app.stage);
    this.app.stage.removeChildren()

    // @ts-ignore
    this.viewport = null
  }

  getDistance(vec1: any, vec2: any) {
    const dx = vec1.x - vec2.x,
      dy = vec1.y - vec2.y;
    return dx * dx + dy * dy;
  }
}