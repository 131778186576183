var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-error" }, [
    _c("div", { staticClass: "login-error-wrapper" }, [
      _c("div", { staticClass: "login-error-text" }, [
        _vm._v(
          "Ops, qualcosa non ha funzionato! Prova ad accedere nuovamente premendo sul bottone"
        )
      ]),
      _c(
        "div",
        {
          staticClass: "login-error-button",
          on: {
            click: function($event) {
              return _vm.goToLoginPlatform()
            }
          }
        },
        [_vm._v(" Accedi ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }