var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c("div", { staticClass: "notifications" }, [
          _c("div", { staticClass: "header notifications__header" }, [
            _c(
              "div",
              { staticClass: "header__wrapper header__wrapper--right" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sound",
                        rawName: "v-sound",
                        value: "selezione_icona",
                        expression: "'selezione_icona'"
                      }
                    ],
                    staticClass: "menu_button",
                    on: {
                      click: function($event) {
                        return _vm.onDispose()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "header__title" }, [
              _vm._v(" Centro Notifiche ")
            ])
          ]),
          _vm.visible
            ? _c("div", { staticClass: "notifications__wrapper" }, [
                _c(
                  "div",
                  { staticClass: "notifications__scroller" },
                  [
                    _vm.isEmpty
                      ? _c("div", { staticClass: "notifications__empty" }, [
                          _vm._v(" Non hai ricevuto ancora nessuna notifica ")
                        ])
                      : _vm._e(),
                    _vm._l(_vm.notifications, function(notification) {
                      return _c(
                        "div",
                        {
                          key: "not-" + notification.notification.id,
                          staticClass: "notifications__card",
                          class: {
                            "new-notification": notification.wasNeverReadBefore
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "notifications__card--pic",
                            style: _vm.notificationImgStyle(
                              notification.notification
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "notifications__card--text" },
                            [
                              _c(
                                "div",
                                { staticClass: "notifications__card--infos" },
                                [
                                  _c("div", { staticClass: "tag" }, [
                                    _c("div", {
                                      staticClass: "notificator__dot"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(notification.notification.tipo) +
                                        " "
                                    )
                                  ]),
                                  _c("div", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          notification.notification.relativeDate
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      notification.notification.interpolatedText
                                    ) +
                                    " "
                                )
                              ]),
                              notification.notification.type == "challenge" &&
                              notification.isChallengeAcceptable
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "challenge",
                                      on: {
                                        click: function($event) {
                                          return _vm.onNotificationClicked(
                                            notification.notification
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Accetta ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    }),
                    _c("infinite-loading", {
                      on: { infinite: _vm.infiniteHandler }
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }