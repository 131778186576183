






























import {
  Component,
  Vue
} from 'vue-property-decorator';

import { GlobalEventBus } from '@/services/GlobalEventBus';

@Component
export default class HelpdeskStepComponent extends Vue {
  
  goToFaq() {
    GlobalEventBus.$emit('open-faq');
  }

  goToRegolamento() {
    GlobalEventBus.$emit('open-regolamento');
  }

}
