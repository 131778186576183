var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home", attrs: { id: "home" } },
    [
      !_vm.showLoginErrorComponent ? _c("HeaderComponent") : _vm._e(),
      _c("NotificatorComponent", {
        on: { "show-multiplayer": _vm.onShowMultiplayer }
      }),
      _c("NotificationsComponent", {
        on: { "show-multiplayer": _vm.onShowMultiplayer }
      }),
      _c("GameComponent", {
        ref: "gameVue",
        class: _vm.gameClass,
        attrs: {
          gameStatus: _vm.gameStatus,
          isUpgradingBlockApi: _vm.isUpgradingBlockApi
        },
        on: {
          "set-loading": _vm.onGameSetLoading,
          "open-quest": _vm.onOpenQuest,
          "upgrade-block": _vm.onUpgradeBlock,
          "close-conversation-dialog": _vm.onCloseDialog,
          "show-multiplayer": _vm.onShowMultiplayer,
          "show-district": _vm.onShowDistrict,
          "is-map": _vm.onIsMap,
          "add-or-subtract-credits": _vm.addOrSubtractCredits
        }
      }),
      _vm.showQuest
        ? _c("QuestComponent", {
            ref: "questVue",
            attrs: { questUrl: _vm.questUrl }
          })
        : _vm._e(),
      _vm.showMultiplayer
        ? _c("MultiplayerComponent", {
            ref: "multiplayerVue",
            attrs: { gameStatus: _vm.gameStatus },
            on: {
              "show-campaign": _vm.onShowCampaign,
              "hide-campaign": _vm.onHideCampaign
            }
          })
        : _vm._e(),
      _vm.showLobbyStats
        ? _c("LobbyComponent", {
            ref: "lobbyStatsVue",
            attrs: { gameStatus: _vm.gameStatus },
            on: { "show-avatar": _vm.onShowAvatar }
          })
        : _vm._e(),
      _vm.showAvatar
        ? _c("AvatarComponent", {
            ref: "AvatarVue",
            attrs: {
              gameStatus: _vm.gameStatus,
              allAssets: _vm.allAvatarAssets,
              avatarAssets: _vm.avatarAssets
            },
            on: { "close-avatar": _vm.onCloseAvatarConfigurator }
          })
        : _vm._e(),
      _c("div", { staticClass: "quest_return" }),
      _c(
        "div",
        {
          staticClass: "loader",
          class: {
            "loader--active": _vm.showLoader && !_vm.showLoginErrorComponent
          }
        },
        [
          _vm.loaderInfo
            ? _c("div", { staticClass: "loader__wrapper" }, [
                _c("div", { staticClass: "loader__info" }, [
                  _c("div", { staticClass: "loader__texts" }, [
                    _c("span", { staticClass: "loader__info__text" }, [
                      _vm._v(_vm._s(_vm.loaderInfo.text) + " ")
                    ]),
                    _c("span", { staticClass: "loader__info__name" }, [
                      _vm._v(_vm._s(_vm.loaderInfo.scene) + " ")
                    ])
                  ]),
                  _vm.loaderInfo.image
                    ? _c("div", {
                        staticClass: "loader__pic",
                        style:
                          _vm.loaderInfo.image == "menu"
                            ? _vm.avatar
                            : {
                                "background-image":
                                  "url(" +
                                  require("@/assets/img/new_alpha/" +
                                    _vm.loaderInfo.image +
                                    ".png") +
                                  ")"
                              }
                      })
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "loader_play",
          class: {
            "loader_play--active":
              _vm.showLoaderPlay && !_vm.showLoginErrorComponent
          }
        },
        [
          _c("div", { staticClass: "loader_play__wrapper" }, [
            _c("div", {
              staticClass: "loader_play__image",
              style: {
                "background-image":
                  "url(" +
                  require("@/assets/img/new_alpha/misc/logo_ucity.png") +
                  ")"
              }
            }),
            _c("div", { staticClass: "loader_play__bar" }, [
              _c(
                "div",
                {
                  staticClass: "loader_play__button",
                  class: { "loader_play__button--active": !_vm.gameLoaded }
                },
                [_vm._v(" Caricamento... ")]
              ),
              _c(
                "div",
                {
                  staticClass: "loader_play__button",
                  class: { "loader_play__button--active": _vm.gameLoaded },
                  on: {
                    click: function($event) {
                      return _vm.startGame()
                    }
                  }
                },
                [_vm._v(" Gioca ")]
              )
            ])
          ])
        ]
      ),
      _vm.showLoginErrorComponent ? _c("LoginErrorComponent") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }