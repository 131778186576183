/* eslint-disable @typescript-eslint/no-explicit-any */

import { ISimpleEvent, SimpleEventDispatcher } from 'ste-simple-events';

import { GlobalEventBus, QuattroEventArgs } from '@/services/GlobalEventBus';
import { BlockDefinition, BlockClass } from './BlockDefinition';
import { Block, World, District, Zone } from './World';
import { BlockType, QuestStarsOutcomes, QuestStatus } from './Enums';
import { Player } from './Player';
import { Quest } from './Quest';

export class GameStatus {
  player: Player;
  world: World;
  blockDefinitions: BlockDefinition[];
  currentDistrict: District;
  private _onQuestCoreClosed: SimpleEventDispatcher<Quest> = new SimpleEventDispatcher<Quest>();
  
  get quests(): Quest[] {
    return this.world.quests;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  constructor(player: Player, dataWorld: any, dataQuests: any[], dataBlockDefinitions: any[], dataUserProgress: any[]) {
    if (player == null) throw Error("Player instance must be given to Game ctor");
    if (dataWorld == null) throw Error("World data must be given to Game ctor");
    if (dataQuests == null || dataQuests.length === 0) throw Error("Quests data must be given to Game ctor");
    if (dataBlockDefinitions == null || dataBlockDefinitions.length === 0) throw Error("Blocks definition data must be given to Game ctor");

    this.initBlockDefinitions(dataBlockDefinitions);
    this.player = player;
    this.world = new World(dataWorld, dataQuests, this.blockDefinitions, player.enabledDistricts, (error: string) => GlobalEventBus.$emit('alert', { text: error }));
    this.currentDistrict = this.world.districts[0];

    if (dataUserProgress != null && dataUserProgress.length > 0)
      this.initUserBlockUpgrades(dataUserProgress);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private initBlockDefinitions(dataBlockDefinitions: any[]) {
    // parto dalle bd BlockClass.Standard con upgradeFrom null
    this.blockDefinitions = [];

    dataBlockDefinitions
      .filter(dbd => dbd.upgradeFrom == null && dbd.classe !== BlockClass.Standard)
      .map(d => this.blockDefinitions.push(new BlockDefinition(d)));
    
    const blockDefinitionsStep0 = dataBlockDefinitions
      .filter(dbd => dbd.upgradeFrom == null && dbd.classe === BlockClass.Standard)
      .map(d => {
        const defInstance = new BlockDefinition(d);
        this.blockDefinitions.push(defInstance);
        return defInstance;
      });
    this.recursiveInitBlockDefinition(dataBlockDefinitions, blockDefinitionsStep0);
  }

  /**
   * @param dataBlockDefinitions all definitions JSON
   * @param blockDefinitionInstanceCurrentStep Step N
   * @returns Array of Step N+1 definition instances, with from and to populated with related definition instances
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private recursiveInitBlockDefinition(dataBlockDefinitions: any[], blockDefinitionInstanceCurrentStep: BlockDefinition[]): void {
    const currentStepBlockDefinitionInstances = dataBlockDefinitions
      .filter(dbd => dbd.upgradeFrom != null && blockDefinitionInstanceCurrentStep.map(x => x.type).includes(dbd.upgradeFrom))
      .map(d => {
        const currentDefInstance = new BlockDefinition(d);
        const previousStepInstance = this.blockDefinitions.find(prevDefInstance => currentDefInstance.upgradeFrom === prevDefInstance.type)!;
        currentDefInstance.upgradesFrom = previousStepInstance;
        previousStepInstance.upgradesTo.push(currentDefInstance);
        this.blockDefinitions.push(currentDefInstance);
        return currentDefInstance;
      });
    
    if (currentStepBlockDefinitionInstances.length > 0) {
      this.recursiveInitBlockDefinition(dataBlockDefinitions, currentStepBlockDefinitionInstances);
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private initUserBlockUpgrades(dataUserProgress: any[]) {
    dataUserProgress.forEach(up => {
      const blockToUpgrade = this.getBlock(up.blockKey);
      const blockDefinitionToSet = this.getBlockDefinition(up.blockType);
      if (blockToUpgrade != null && blockDefinitionToSet != null)
        blockToUpgrade.upgrade(blockDefinitionToSet);
    });
  }

  getBlock(blockKey: string): Block {
    return this.allBlocks.find(x => x.key === blockKey)!;
  }

  getBlockDefinition(blockType: BlockType): BlockDefinition {
    return this.blockDefinitions.find(x => x.type === blockType)!;
  }

  setCurrentDistrict(district: District): void {
    this.currentDistrict = district;
  }

  upgradeBlock(block: Block, upgradedBlockType: BlockType): void {
    const upgradeBlockDefinition = this.blockDefinitions.find(x => x.type === upgradedBlockType)!;
    block.upgrade(upgradeBlockDefinition);
    
    // @ts-ignore
    let eventToEmit: QuattroEventArgs = null;

    if (block.isQuestBlock && block.currentQuestBlockUpgrade(block.definition, 0) >= 2) {
      eventToEmit = { eventKey: `3-upgrade-${block!.zone.district.key}-${block!.zone.key}` };
    }

    if (block.zone.district.completionPercentage >= 20 && block.zone.district.completionPercentage < 50) {
      eventToEmit = { eventKey: `20-perc-${block!.zone.district.key}` };
    } else if (block.zone.district.completionPercentage >= 50 && block.zone.district.completionPercentage < 99) {
      eventToEmit = { eventKey: `50-perc-${block!.zone.district.key}` };
    } else if (block.zone.district.completionPercentage >= 99) {
      eventToEmit = { eventKey: `100-perc-${block!.zone.district.key}` };
      
      if(this.isGameCompleted()) {
        eventToEmit = { eventKey: `end-game-100-perc` };
      }
    }

    

    if (eventToEmit != null) {
      GlobalEventBus.$emit('quattro', eventToEmit);
    }
  }

  setPlayerCredits(value: number): void {
    this.player.credits = value;
  }

  setPlayerExp(value: number): void {
    this.player.experience = value;
  }

  getBlockDefinitionByBlockType(upgradeBlockType: BlockType): BlockDefinition {
    return this.blockDefinitions.find(x => x.type === upgradeBlockType)!
  }

  private get allBlocks(): Block[] {
    return this.world.districts.flatMap(x => x.zones.flatMap(y => y.blocks));
  }

  get currentDistrictBlocks(): Block[] {
    return this.currentDistrict.zones.flatMap(x => x.blocks);
  }

  get currentDistrictZones(): Zone[] {
    return this.currentDistrict.zones;
  }

  get questCoreCompletedEmitter(): ISimpleEvent<Quest> {
    return this._onQuestCoreClosed.asEvent();
  }

  get currentDistrictPercentage(): number {
    return this.currentDistrict.completionPercentage;
  }

  get allQuestCompleted(): boolean {
    let allComplete = true;
    this.currentDistrictZones.forEach(zone => {
      if (!zone.hasRelatedQuest || zone.relatedQuest.progress.stars <= 0) {
        allComplete = false;
      } 
    });
    return allComplete;
  }

  get currentDistrictStars(): number {
    let stars = 0;
    this.currentDistrictZones.forEach(zone => {
      if (zone.hasRelatedQuest) stars += parseInt(zone.relatedQuest.progress.stars + '') 
    });
    return stars;
  }
  
  get firstDistrictStars(): number {
    let stars = 0;
    this.world.districts[0].zones.forEach(zone => {
      if (zone.hasRelatedQuest) stars += parseInt(zone.relatedQuest.progress.stars + '') 
    });
    return stars;
  }

  setQuestStars(questId: number, value: QuestStarsOutcomes) {
    const quest = this.quests.find(x => x.id === questId);
    const block = this.getBlockByQuestId(questId);

    // console.log( this.currentDistrict.collectedStars );
    const allQuestCompletedBefore = this.allQuestCompleted;
    // console.log(allQuestCompletedBefore);
    
    // @ts-ignore
    let eventToEmit: QuattroEventArgs = null;
    
    // any star of quest
    const prevQuestStarsValue = quest!.progress.stars;
    if (value > 0 && block != null) {
      eventToEmit = { eventKey: `${value}-star-${block!.zone.district.key}-${block.zone.key}` };
    }

    if (block != null) {
      // first star of district
      const prevDistrictStarsValue = block.zone.district.collectedStars;
      if (prevDistrictStarsValue === 0 && value > 0) {
        if(block.zone != null) {
          eventToEmit = { eventKey: `1-star-${block.zone.district.key}` };
        }
      }

      quest!.progress.stars = value;
      this.player.stars = this.player.stars - prevQuestStarsValue + value;

      // last star of district
      if (block.zone.district.collectedStars === 15 || 
        (block.zone.district.collectedStars === 12 && block.zone.district.key == 'industriale' )
        ) {
        eventToEmit = { eventKey: `15-star-${block.zone.district.key}` };
        
        if (this.isGameCompleted()) {
          eventToEmit = { eventKey: `end-game-100-perc` };
        }
      }

      if (this.allQuestCompleted && !allQuestCompletedBefore) {
        // console.log("all quest completed for the first time!");
        eventToEmit = { eventKey: 'unlocked-final-' + this.currentDistrict.key };
        GlobalEventBus.$emit('show-final-quest', true);
      }

      if (eventToEmit != null) {
        GlobalEventBus.$emit('quattro', eventToEmit);
      }
    } else {
      // caso in cui termino quest ponte, non ho block associato, ma devo cmq settare le stelle x la quest
      quest!.progress.stars = value;
    }    

    // console.log( this.currentDistrict.collectedStars );
  }

  // qui end-game-100-perc
  isGameCompleted() : boolean {
    
    let totalPerc = 0
    let totalStars = 0

    this.world.districts.forEach(dist => {
      totalStars += dist.collectedStars
      totalPerc += dist.completionPercentage;
    });
    if(totalPerc == 400 && totalStars == 57) {
      // console.log("WINNN!");
      return true;
    }
    // console.log(totalPerc);
    // console.log(totalStars);

    return false;
  }

  setQuestStatus(questId: number, value: QuestStatus) {
    // const block = this.getBlockByQuestId(questId);

    // if (block) {
    //   block!.relatedQuest!.progress.status = value;
    // } else {
    //   console.log("no block found for QUEST ");
    // }

    const quest = this.quests.find(x => x.id === questId);
    quest!.progress.status = value;
    // console.log(quest, value);
  }

  setQuestSuspendData(questId: number, value: string) {
    const quest = this.quests.find(x => x.id === questId);
    quest!.progress.suspendData = value;
    // const block = this.getBlockByQuestId(questId);
    // block!.relatedQuest!.progress.suspendData = value;
  }

  emitQuestCoreComplete(questId: number): void {
    const quest = this.quests.find(x => x.id === questId)!;
    this._onQuestCoreClosed.dispatch(quest);
  }

  getQuest(questId: number): Quest | null {
    // console.log(`searching for quest ${questId} in this.quests`, this.quests);
    const quest = this.quests.find(x => x.id == questId);
    return quest ? quest : null;
  }

  getFinalQuest(): Quest | null {
    const quest = this.quests.find(x => x.locationKey == (this.currentDistrict.key + "|final")  );
    return quest ? quest : null;
  }

  getBlockByQuestId(questId: number): Block | null {
    const quest = this.quests.find(x => x.id === questId);
    if (quest == null) return null;

    const questBlock = this.allBlocks.find(x => x.zone.key === quest.zoneKey && x.isQuestBlock);
    return questBlock ? questBlock : null;
  }

  getDistrictByKey(key : string): District | null {
    const dist = this.world.districts.find(x => x.key === key);
    return dist ? dist : null;
  }

  getNextDistrict(previousDistrictKey: string): District | null {
    const dist = this.world.districts.find(x => x.dependsOnCompletionOf === previousDistrictKey);

    return dist != null ? dist : null;
  }
}