



















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseComponent from './BaseComponent';
import HeaderComponent from '@/components/Header.vue';
import AsyncButtonComponent from '@/components/Common/AsyncButton.vue';
import { GameCore } from '@/assets/js/world/GameCore'
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { Quest } from '@/assets/js/world/models/Quest';
import { Block, District } from '@/assets/js/world/models/World';
import { BlockDefinition, BlockClass } from '@/assets/js/world/models/BlockDefinition';
import { BlockType } from '@/assets/js/world/models/Enums';
import { BlockInfo } from '@/assets/js/world/models/BlockInfo';
import { LoadingInfo } from '@/views/Home.vue';
import { GlobalEventBus } from "@/services/GlobalEventBus";
import { ConversationMessage } from '@/assets/js/world/models/Conversation';

@Component({
  components: {
    HeaderComponent,
    AsyncButtonComponent,
  }
})
export default class GameComponent extends BaseComponent {
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  showUpgrades = false;
  upgradeLeftText = "no";
  upgradeLeftImage = "ciaoaosp";
  gc = GameCore;
  
  // @ts-ignore
  blockToUpgrade: Block = null;
  // @ts-ignore
  blockToUpgradeInfo: BlockInfo = null;
  blockToUpgradeUpgradeDefinitions: BlockDefinition[] = [];
  showFakeQuest = false;
  selectorView = 0;
  
  showStats = false;
  stats: any[] = [];
  animatedCredits = 0;
  creditsAnimation = false;

  showChat = false;
  chatContent: ConversationMessage[] = [];
  currentQuest: Quest | null = null ;
  questBlockName = "";

  chatIndex = 0;
  isEndOfConversation = false;
  currentCharacterName = "";

  // @ts-ignore
  statsArea: LoadingInfo = null;

  sceneType = 0;
  isUpgrading = false;

  // MENU
  selectedDistrict: District | null = null;
  selectedDistrictInfo : any;
  showSelectedDistrict = false;

  showFooter = true;

  upgradesbuttonactive = false;

  currentQuestCharacterKey = require('@/assets/img/new_alpha/characters/user.png')

  @Prop() readonly gameStatus: GameStatus;
  @Prop() readonly isUpgradingBlockApi = false;

  get avatar(): string {
    return this.$store.getters.avatarBackgroundImageStyle;
  }

  @Watch('gameStatus')
  onGameStatusChanged(val: GameStatus, oldVal: GameStatus) {
    if (oldVal == null) {
      this.loadGameCore();
    } else {
      GameCore.updateGameStatus(val);
    }
  }

  @Watch('chatIndex')
  onChatUpdated(/*val: number*/) {
    // console.log(val);
    const container = this.$el.querySelector(".chat__items");

    setTimeout(() => {
      container!.scrollTop = container!.scrollHeight ;  
    }, 10);
    
  }

  created() {
    GlobalEventBus.$on('select-district', (info: any) => {
      // console.log(info);
      this.selectedDistrictInfo = info;
      if (GameCore.gameStatus.getDistrictByKey(info.key) != null) {
        this.selectDistrict(GameCore.gameStatus.getDistrictByKey(info.key)!)
      }
    });
    GlobalEventBus.$on('set-footer-visibility', (visible: boolean) => {
      this.showFooter = visible;
    });
    GlobalEventBus.$on('upgrades-button-active', (active: boolean) => {
      this.upgradesbuttonactive = active
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off('select-district');
  }

  selectDistrict(district: District) {
    this.selectedDistrict = district
    // console.log(district);
    this.stats = this.selectedDistrict.zones.map(x => this.getZoneData(x))
    this.showSelectedDistrict = true
  }

  deselectDistrict() {
    // console.log( "deselecting " + this.selectedDistrict!.key );
    GlobalEventBus.$emit('deselect-district', this.selectedDistrict!.key)
    this.showSelectedDistrict = false;
    setTimeout(() => {
      this.selectedDistrict = null  
    }, 300); 
  }

  gotoDistrict( ) {
    GlobalEventBus.$emit('goto-district', this.selectedDistrictInfo)
    this.deselectDistrict()
  }

  get bestNetData() {
    if (this.gameStatus == null || this.gameStatus.currentDistrict == null || !this.gameStatus.currentDistrict.isCompleted) return null;

    const stars = []
    for (let i = 0; i < 3; i++) {
      stars[i] = (i < this.gameStatus.currentDistrict.finalQuest.progress.stars) ? 1 : 0;
    }

    return {
      name: 'BestNet',
      stars
    }
  }

  getZoneData(zone: any) {
    // console.log(zone);
    const stars = []
    for (let i = 0; i < 3; i++) {
      if (zone.hasRelatedQuest && i < zone.relatedQuest.progress.stars) {
        stars[i] = 1
      } else {
        stars[i] = 0
      }
    }
    const zoneStats = {
      name: zone.name,
      stars: stars
    }
    return zoneStats
  }

  loadGameCore() {
    GameCore.init(
      {
        width: this.width,
        height: this.height,
      },
      this.gameStatus,
      {
        cbGoToDistrict: this.goToDistrict,
        cbOpenQuest: this.onOpenQuestRequest,
        cbCloseQuest: this.onCloseDialogRequest,
        cbShowUpgrades: this.onShowUpgrades,
        cbHideUpgrades: this.closeUpgradeDialog,
        cbToggleQuest: this.onToggleQuest,
        cbHideQuest: this.onHideQuest,
        cbSetStats: this.setStats,
        cbAddOrSubtractCredits: this.addOrSubtractCredits,
        cbToggleChat: this.toggleChat,
        cbSwitchView: this.switchView,
        cbSetLoading: this.setLoading,
        cbSetScene: this.setScene,
        cbShowMultiplayer: this.showMultiplayer,
      },
    );
  }

  addOrSubtractCredits(deltaCredits: number, block: Block) {
    this.$emit('add-or-subtract-credits', deltaCredits, null, block);
  }

  setStats(stats: any[]) {
    this.stats = stats;
  }


  goToMenu() {
    // console.log("GOING MENU");
    GameCore.display.initMenuTrans();
    this.statsArea = {
      text: "Stai tornando alla ",
      scene: "mappa",
      image: "icons/icona_menu",
    };

    this.$emit("set-loading", true, this.statsArea);
    setTimeout(() => {
      this.$emit("set-loading", false);
      this.$emit("is-map");
		}, 1000);
  }

  goToLobby() {
    // console.log("GOING MENU");
    this.statsArea = {
      text: "Stai tornando a ",
      scene: "casa",
      image: "particle",
    };

    this.$emit("set-loading", true, this.statsArea);
    setTimeout(() => {
      this.$emit("set-loading", false);
		}, 1000);
  }

  goToDistrict() {
    // console.log("goind to district");
    this.$emit("show-district");
  }

  setLoading(loading: boolean, loaderInfo: LoadingInfo) {
    this.statsArea = loaderInfo;
    this.$emit("set-loading", loading, loaderInfo);
  }

  setScene(sceneType: number) {
    this.sceneType = sceneType;
    // console.log("SCENE", this.sceneType);

    this.$store.dispatch('onSetHeaderCloseButtonVisibility', this.sceneType > 0);
  }

  giveFakeResultFromQuest() {
    // this.gameStatus.setPlayerExp(5194);       // totale
    // this.gameStatus.setPlayerCredits(58280);  // totale
    this.gameStatus.setQuestStars(this.currentQuest!.id, 2);      // questId, stelle

    GameCore.display.onQuestClosed()

    this.endQuest();
  }

  endQuest() {
    // GameCore.display.setZoneStars(3);
    this.toggleChat(false);
    this.showFakeQuest = false;
    
  }

  onToggleQuest(show: boolean, quest: Quest, blockName: string) {
    // console.log(quest);
    
    if (quest == null) {
      // GlobalEventBus.$emit('alert', { text: 'Quest non configurata su questa zona'});
      return;
    }

    try {
      this.currentQuestCharacterKey = require('@/assets/img/new_alpha/characters/' + quest.characterKey + '.png')
      // console.log("SUCCENSO");
      
    } catch (error) {
      if(error) {
        this.currentQuestCharacterKey = require('@/assets/img/new_alpha/characters/user.png')
        // console.log("ERRORORORO");
      }
    }
    this.currentQuest = quest;
    this.chatContent = quest.preQuestConversation;
    if(quest.progress.status == "passed" && quest.postQuestConversation) {
      this.chatContent = quest.postQuestConversation;
    }
    this.chatContent = this.chatContent.map(c => {
      return new ConversationMessage({
        type: c.type,
        content: this.sanitize(c.content),
      });
    });
    this.questBlockName = blockName;
    this.toggleChat(true);
    this.chatIndex = 0;
    this.isEndOfConversation = false;
    this.currentCharacterName = quest.characterName;
    this.updateConversation()
    this.showStats = false
  }

  onHideQuest() {    
    this.toggleChat(false);
    this.showStats = false
    
  }

  updateConversation() {
    if(this.chatIndex == this.chatContent.length - 1 || this.chatContent.length == 0) {
      this.isEndOfConversation = true;
    }
    if(this.chatIndex >= this.chatContent.length) {
      this.chatIndex = this.chatContent.length - 1;
      this.toggleChat(false);
    }
    
  }

  startQuest() {
    if (this.currentQuest) {
      this.onOpenQuestRequest(this.currentQuest);
    } else {
      this.showFakeQuest = true;
    }
    this.toggleChat(false)
  }

  toggleChat(show: boolean) {
    this.showChat = show;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', !show);
    if(!show) {
      // GlobalEventBus.$emit('play-sound', 'tick');
      GlobalEventBus.$emit('pause-viewport', false);
    }  
  }

  goToNextChatItem() {
    this.chatIndex += 1;
    this.updateConversation();
  }

  toggleStats() {
    this.showStats = !this.showStats;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', !this.showStats);
  }

  switchView(view: number) {
    if (GameCore.display.isSwitchingView) return;
    GameCore.display.switchView(view);
    this.selectorView = view;
  }

  onShowUpgrades(currentBlock: Block, currentBlockInfo: BlockInfo, upgradeDefinitions: BlockDefinition[]) {
    this.showUpgrades = true;
    this.showStats = false;
    this.blockToUpgradeUpgradeDefinitions = upgradeDefinitions;
    this.blockToUpgradeInfo = currentBlockInfo;
    this.blockToUpgrade = currentBlock;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
  }

  closeUpgradeDialog() {
    this.showUpgrades = false;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);

    // GlobalEventBus.$emit('pause-viewport', false);
    
    setTimeout(() => {
      this.isUpgrading = false;
    }, 1000);

    GameCore.display.showLastSelector();
  }

  upgradeCurrentBlock(type: BlockType) {

    if(this.isUpgrading) return;

    this.isUpgrading = true;

    const blockDef = this.gameStatus.getBlockDefinitionByBlockType(type);

    if (blockDef.classe === BlockClass.Edificabile) {
      this.$emit('upgrade-block', this.blockToUpgrade, type, blockDef.price);
      return;
    }

    if (this.gameStatus.player.credits >= blockDef.price) {
      this.$emit('upgrade-block', this.blockToUpgrade, type, -blockDef.price);
    }
  }

  refreshBlockUI(block: Block) {
    GameCore.display.refreshBlockUI(block);
  }

  onOpenQuestRequest(quest: Quest) {
    this.$emit('open-quest', `${quest.questUrl}?questId=${quest.id}`);
  }

  onCloseDialogRequest() {
    this.toggleChat(false);
    this.endQuest()
    GameCore.display.onQuestClosed()
    this.$emit('close-conversation-dialog');
  }

  showMultiplayer() {
    this.$emit('show-multiplayer');
  }
}
