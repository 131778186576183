/* eslint-disable @typescript-eslint/no-explicit-any */

export interface EnabledDistrictInfo {
  key: string;                         // chiave del distretto, es "residenziale"
  fromDate?: string;                   // null se abilitato, oppure valorizzato con string UTC date se data di sblocco è settata nel futuro
}

export class Player {
  readonly id: number;
  readonly username: string;
  readonly displayName: string;
  sex: string;
  imageFace: string;
  imageBody: string;
  role: string;
  credits: number;
  experience: number;
  stars: number;
  readonly enabledDistricts: EnabledDistrictInfo[];
  quattroTriggeredEvents: string[];
  lastLogin: string;
  lastNotification: number;
  lastReadNotification: number;

  get needsToCompleteOnboarding(): boolean {
    return this.sex == null || this.imageFace == null;
  }

  constructor(data: any) {
    this.id = data.id;
    this.username = data.username;
    this.displayName = data.displayName;
    this.sex = data.sex;
    this.imageFace = data.imageFace;
    this.imageBody = data.imageBody;
    this.role = data.role;
    this.credits = data.credits;
    this.experience = data.experience;
    this.stars = data.stars;
    this.lastLogin = data.lastLogin;
    this.lastNotification = data.lastNotification;
    this.lastReadNotification = data.lastReadNotification;
    this.enabledDistricts = data.enabledDistricts != null ? JSON.parse(JSON.stringify(data.enabledDistricts)) : [];
    this.quattroTriggeredEvents = data.quattroTriggeredEvents != null ? JSON.parse(JSON.stringify(data.quattroTriggeredEvents)) : [];
  }

  canNavigateToDistrict(districtKey: string): boolean {
    if (this.enabledDistricts.length === 0) return true;

    return this.enabledDistricts.find(x => x.key === districtKey && (x.fromDate == null || x.fromDate > new Date(Date.now()).toISOString())) != null;
  }

  addQuattroTriggeredEvent(key: string) {
    this.quattroTriggeredEvents.push(key);
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}
