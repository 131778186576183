import RestApiProvider from './RestApiProvider';
import MockApiProvider from './mocks/RestApiProvider';
import { Player } from '@/assets/js/world/models/Player';
import { UserBlockProgress } from '@/assets/js/world/models/UserBlockProgress';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { PlayerCreditsPayload, PlayerExpPayload, QuestStarsPayload, QuestStatusPayload,
  QuestSuspendDataPayload, PlayerStatistics, UserAvatarPayload,
  UsernameRegistrationPayload, OnboardingContents, EndingContents, StaticPage, StaticPageContent, CampaignRanking } from '@/assets/js/RestApiPayloads';
import { Campaign } from '@/assets/js/campaign/models/Campaign';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { AvatarAsset } from '@/assets/js/profile/models/Avatar';
import { ConversationMessage } from '@/assets/js/world/models/Conversation';
import { Badge } from '@/assets/js/profile/models/Badge';
import { Prize } from '@/assets/js/profile/models/Prize';
import { BaseNotification } from '@/assets/js/profile/models/Notifications';
import { Category, Ranking } from '@/assets/js/campaign/models/Ranking';

export interface IRestApiInitOptions {
  offuscamento: boolean;
  criptaggio: boolean;
}

export interface IRestApiError {
  isClientError: boolean;             // se l'errore è del provider TS, non un errore di comunicazione e/o Axios
  error: any;
  axiosError?: {                      // esiste se isClientError == false
    errorJson: any;
    responseExists: boolean;            // se esiste error.response, cioè se il server ha ricevuto request ed ha risposto con errore
    responseErrorCode?: number;         // se esiste error.response, prendi il field [status]
  }
}

export interface IRestApiProvider {
  // login
  login(username?: string, password?: string): Promise<Player>;
  logout(): void;
  
  // signup / onboarding
  isUsernameAvailable(username: string): Promise<void>;
  signUp(displayName: string, username: string, password: string): Promise<void>;
  registerUsername(payload: UsernameRegistrationPayload): Promise<void>;
  setUserSex(sex: string): Promise<void>;
  getOnboardingContents(): Promise<OnboardingContents>;
  getEndingContents(): Promise<EndingContents>;

  // in-game
  getGameStatus(player: Player): Promise<GameStatus>;
  setPlayerCredits(payload: PlayerCreditsPayload): Promise<PlayerStatistics>;
  setPlayerExperience(payload: PlayerExpPayload): Promise<PlayerStatistics>;
  setQuestStars(payload: QuestStarsPayload): Promise<PlayerStatistics>;
  setQuestStatus(payload: QuestStatusPayload): Promise<void>;
  setQuestSuspendData(payload: QuestSuspendDataPayload): Promise<void>;
  createUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress>;
  updateUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress>;

  // multiplayer
  getCampaigns(): Promise<Campaign[]>;
  getProfile(userId: number): Promise<UserProfile>;
  getCategories(): Promise<Category[]>;
  getRanking(categoryId: number, pageIndex: number, pageSize: number): Promise<Ranking[]>;
  
  // lobby
  getAvatarAssets(sex: string): Promise<AvatarAsset[]>;
  getUserAvatar(): Promise<number[]>;
  setUserAvatar(payload: UserAvatarPayload): Promise<UserAvatarPayload>;
  getUserRankings(): Promise<CampaignRanking[]>;

  // quattro
  askForQuattroDetails(eventKey: string, player: Player): Promise<ConversationMessage[]>;

  // sistema premiante
  getBadges(): Promise<Badge[]>;
  getPrizes(pageIndex: number, itemsPerPage?: number): Promise<Prize[]>;

  // notifiche
  setLastNotificationViewed(notificationId: number): Promise<void>;
  getNotificationsAfterId(notificationId: number): Promise<BaseNotification[]>;
  getNotificationsBackwardPaged(notificationId: number, itemsPerPage: number): Promise<BaseNotification[]>;

  // pagine statiche
  getStaticPage(pageKey: string): Promise<StaticPage>;
  getStaticPageContent(pageKey: string): Promise<StaticPageContent[]>;
}

export function initProvider(navigator: Navigator, offuscamento = false, criptaggio = false): IRestApiProvider {
  if (process.env.VUE_APP_MOCK_RESTAPIPROVIDER != null && process.env.VUE_APP_MOCK_RESTAPIPROVIDER === 'true') {
    return new MockApiProvider(offuscamento, criptaggio);
  }
  return new RestApiProvider(navigator, offuscamento, criptaggio);
}
