var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidedown" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "offline", class: { "offline--active": _vm.visible } },
          [
            _c("div", { staticClass: "offline__wrapper" }, [
              _vm._v("Devi tornare online per continuare su U-City")
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }