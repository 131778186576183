var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile profile__profile-container profile__multiplayer",
      class: { "profile__multiplayer--active": _vm.visible }
    },
    [
      _vm.visible
        ? _c("ProfileStatsComponent", {
            attrs: { profile: _vm.profile, isLoggedPlayerProfile: false },
            on: { dispose: _vm.dispose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }