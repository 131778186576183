/* eslint-disable @typescript-eslint/no-explicit-any */

import { BlockType, QuestStarsOutcomes } from './Enums';
import { BlockDefinition, BlockClass } from './BlockDefinition';
import { Quest } from './Quest';
import { EnabledDistrictInfo } from './Player';

export interface Coordinates {
  x: number;
  y: number;
}

export class Block {
  private readonly _key: string;
  readonly name: string;
  readonly position: Coordinates;
  readonly zIndex: number;
  readonly initialType: BlockType;
  readonly initialDefinition: BlockDefinition;
  readonly initialUpgrades: BlockType[];
  readonly isQuestBlock: boolean;
  zone: Zone;
  definition: BlockDefinition;

  get key(): string {
    return `${this.zone.district.key}|${this.zone.key}|${this._key}`;
  }

  get type(): BlockType {
    return this.definition.type;
  }

  get relatedQuest(): Quest | null {
    return this.isQuestBlock ? this.zone.relatedQuest : null;
  }

  get hasNeverBeenUpgraded(): boolean {
    return this.initialType === this.type;
  }

  currentQuestBlockUpgrade(definition: BlockDefinition, iterations: number): number {
    if (definition.upgradesFrom == null) {
      return iterations;
    } else {
      iterations += 1;
      return this.currentQuestBlockUpgrade(definition.upgradesFrom, iterations);
    }
  }

  get isUpgradable(): boolean {
    // if(this.isQuestBlock) {
    //   // check completion percentage dei blocchi quest per vedere se hanno abbastanza stelle per essere upgradate
    //   const currentUpgrade = this.currentQuestBlockUpgrade(this.definition, 0)
    //   console.log('current upgrade', currentUpgrade);
    //   if(this.relatedQuest!.progress.stars <= currentUpgrade ) return false
    // }
    return this.upgrades != null && this.upgrades.length > 0 && this.zone.isUnlocked;
  }

  get upgrades(): BlockType[] | null {
    if (this.definition.classe === BlockClass.Edificabile) return this.initialUpgrades;
    // if (this.definition.classe === BlockClass.Bonificabile) return this.initialUpgrades;

    return this.definition.upgradeTo;
  }

  get completionPercentage(): number {
    if (this.definition.classe !== BlockClass.Standard) return 0;
    if (this.upgrades == null || this.upgrades.length === 0) return 100;
        
    if (this.initialDefinition.classe === BlockClass.Standard)
      return this.definition.completionPercentage;
    else {
      const definitionMaxLengthPath = this.definition.getMaxLengthPathFromRoot(this.definition);
      const rescaledMaxLengthPath = definitionMaxLengthPath + 1;
      const definitionStep = this.definition.upgradeStep;
      const rescaledStep = definitionStep + 1;
      return Math.ceil(rescaledStep / rescaledMaxLengthPath * 100);
    }
  }

  get questUrl(): string | null {
    return this.relatedQuest?.questUrl || null;
  }

  constructor(data: any, definition: BlockDefinition) {
    this._key = data._key;
    this.name = data.name;
    this.position = {
      x: data.position.x,
      y: data.position.y,
    };
    this.zIndex = data.zIndex;
    this.initialType = data.initialType;
    this.initialUpgrades = JSON.parse(JSON.stringify(data.initialUpgrades));
    this.isQuestBlock = data.isQuestBlock;
    
    this.initialDefinition = definition;
    this.definition = definition;
  }

  upgrade(upgradeDefinition: BlockDefinition): void {
    this.definition = upgradeDefinition;
  }
}


export class Zone {
  readonly key: string;
  readonly name: string;
  readonly blocks: Block[];
  relatedQuest: Quest;
  district: District;

  get hasRelatedQuest(): boolean {
    return this.relatedQuest != null;
  }

  get uniqueKey(): string {
    return `${this.district.key}|${this.key}`;
  }

  get isUnlocked(): boolean {
    if(!this.hasRelatedQuest) return false;
    return this.relatedQuest.progress && this.relatedQuest.progress.stars != QuestStarsOutcomes.Zero;
  }

  get questBlock(): Block {
    return this.blocks.find(x => x.isQuestBlock)!;
  }

  get collectedStars(): number {
    if(this.relatedQuest != null) {
      return this.relatedQuest.progress.stars;
    }
    return 0;
  }

  get completionPercentage(): number {
    const blockCompletionPercentagesSum = this.blocks.reduce((prev, cur) => {
      return cur.completionPercentage + prev;
    }, 0);
    return Math.ceil(blockCompletionPercentagesSum / this.blocks.length);
  }

  constructor(data: any, blockDefinitionInstances: BlockDefinition[]) {
    this.key = data.key;
    this.name = data.name;

    this.blocks = data.blocks.map((b: any) => {
      const definition = blockDefinitionInstances.find((bd: any) => b.initialType === bd.type);
      if (!definition) throw new Error(b.blockType + ' definition not found');
      
      const block = new Block(b, definition);
      block.zone = this;
      return block;
    });
  }
}


export class District {
  readonly key: string;
  readonly name: string;
  readonly zones: Zone[];
  readonly finalQuest: Quest;
  readonly unlocksFromDate?: string;
  world: World;
  readonly dependsOnCompletionOf: string;      // key quartiere "precedente" in ordine di sblocco
  dependsOn?: District;                        // quartiere "precedente" in ordine di sblocco

  get collectedStars(): number {
    
    let stars = 0
    this.zones.forEach(zone => {
      stars += parseInt(''+zone.collectedStars,10) 
    });
    return stars;
    
    // return this.zones.reduce((prev, zone) => {
    //   return prev += zone.collectedStars;
    // }, 0);
  }

  get collectedStarsWithoutFinalQuest(): number {
    return this.zones.filter(x => x.relatedQuest.locationKey).reduce((prev, zone) => {
      return prev += zone.collectedStars;
    }, 0);
  }

  get completionPercentage(): number {
    const blockCompletionPercentagesSum = this.zones.reduce((prev, cur) => {
      return cur.completionPercentage + prev;
    }, 0);
    return Math.ceil(blockCompletionPercentagesSum / this.zones.length);
  }

  // private _isEnabled: boolean;
  get isEnabled(): boolean {
    // return this._isEnabled;
    // console.log('now ISO', new Date(Date.now()).toISOString());
    
    return this.unlocksFromDate == null || this.unlocksFromDate <= new Date(Date.now()).toISOString();
  }

  get isCompleted(): boolean {
    if (this.finalQuest == null) return false;

    return this.finalQuest.progress.status == 'passed';
  }

  get isUnlocked(): boolean {
    if (!this.isEnabled) return false;

    return this.dependsOn == null || this.dependsOn.isCompleted;
  }

  constructor(data: any, quests: Quest[], blockDefinitionInstances: BlockDefinition[], enabledDistrictInfo: EnabledDistrictInfo, onErrorReportCb: (text: string) => void) {
    // this._isEnabled = isEnabled;
    this.key = data.key;
    this.name = data.name;
    this.dependsOnCompletionOf = data.dependsOnCompletionOf;
    this.unlocksFromDate = enabledDistrictInfo.fromDate;
    this.zones = data.zones.map((z: any) => {
      const zone = new Zone(z, blockDefinitionInstances);
      zone.district = this;
      const zoneRelatedQuest = quests.find(q => q.locationKey.split("|")[0] === zone.district.key && q.locationKey.split("|")[1] === zone.key);
      if (zoneRelatedQuest != null) {
        zone.relatedQuest = zoneRelatedQuest;
      } else {
        onErrorReportCb(`Errata configurazione delle quest nella mappa. Manca quest per zona '${zone.uniqueKey}'`);
      }
      return zone;
    });

    const finalQuest = quests.find(q => q.locationKey.split("|")[0] === this.key && q.locationKey.split("|")[1] === "final");
    if (finalQuest != null) {
      this.finalQuest = finalQuest;
    } else {
      onErrorReportCb(`Errata configurazione delle quest nella mappa. Manca final quest per distretto '${this.key}'`);
    }
  }
}

export class World {
  districts: District[];
  quests: Quest[];

  get completionPercentage(): number {
    const blockCompletionPercentagesSum = this.districts.reduce((prev, cur) => {
      return cur.completionPercentage + prev;
    }, 0);
    return Math.ceil(blockCompletionPercentagesSum / this.districts.length);
  }

  constructor(dataWorld: any, dataQuests: any[], blockDefinitionInstances: BlockDefinition[],
    enabledDistrict: EnabledDistrictInfo[],
    onErrorReportCb: (text: string) => void) {
    this.quests = dataQuests.map(x => new Quest(x));
    this.districts = dataWorld.districts.filter((d: any) => enabledDistrict.map(x => x.key).includes(d.key)).map((x: any) => {
      const d = new District(x, this.quests, blockDefinitionInstances, enabledDistrict.find(d => d.key === x.key)!, onErrorReportCb);
      d.world = this;
      return d;
    });
    this.districts.forEach(x => {
      const previousDistrict = this.districts.find(y => y.key === x.dependsOnCompletionOf);
      if (previousDistrict != null) {
        x.dependsOn = previousDistrict;
      }
    })
  }
}
