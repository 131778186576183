var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onboarding-container" }, [
    _vm.currentStep == 1
      ? _c("div", { staticClass: "onboarding" }, [
          _c("div", { staticClass: "onboarding__wrapper" }, [
            _c("div", { staticClass: "onboarding__center" }, [
              _c("div", {
                staticClass: "onboarding__cover",
                style: {
                  "background-image":
                    "url(" +
                    require("@/assets/img/new_alpha/misc/logo_ucity.png") +
                    ")"
                }
              })
            ]),
            _c("div", { staticClass: "onboarding__bottom" }, [
              _vm._m(0),
              _c("div", { staticClass: "onboarding__action" }, [
                _c(
                  "button",
                  {
                    class: { disabled: !_vm.isButtonEnabled },
                    attrs: { disabled: !_vm.isButtonEnabled },
                    on: { click: _vm.goToUsername }
                  },
                  [_vm._v("Inizia")]
                )
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm.currentStep == 2
      ? _c("div", { staticClass: "onboarding" }, [
          _c("div", {}, [
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "onboarding__action",
                class: _vm.usernameInputClasses
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.username,
                      expression: "username"
                    }
                  ],
                  attrs: {
                    placeholder: "Type here...",
                    maxlength: "8",
                    spellcheck: "false"
                  },
                  domProps: { value: _vm.username },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.username = $event.target.value
                      },
                      _vm.onUsernameChanged
                    ]
                  }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "onboarding__bottom" }, [
            _c("div", { staticClass: "onboarding__action" }, [
              _c(
                "button",
                {
                  class: { disabled: !_vm.isButtonEnabled },
                  attrs: { disabled: !_vm.isButtonEnabled },
                  on: { click: _vm.goToTutorial }
                },
                [_vm._v("Continua")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.currentStep == 3
      ? _c("div", { staticClass: "onboarding" }, [
          _vm._m(2),
          _c("div", { staticClass: "onboarding__bottom" }, [
            _c("div", { staticClass: "onboarding__action" }, [
              _c(
                "button",
                {
                  class: { disabled: !_vm.isButtonEnabled },
                  attrs: { disabled: !_vm.isButtonEnabled },
                  on: { click: _vm.goToSex }
                },
                [_vm._v("Continua")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.currentStep == 4
      ? _c("div", { staticClass: "onboarding" }, [
          _c("div", {}, [
            _vm._m(3),
            _c("div", { staticClass: "onboarding__action" }, [
              _c("div", {
                staticClass: "gender",
                class: { "gender--selected": _vm.selectedSex == "female" },
                style: {
                  "background-image":
                    "url(" +
                    require("@/assets/img/new_alpha/misc/female.png") +
                    ")"
                },
                on: {
                  click: function($event) {
                    return _vm.onSexChange("female")
                  }
                }
              }),
              _c("div", {
                staticClass: "gender",
                class: { "gender--selected": _vm.selectedSex == "male" },
                style: {
                  "background-image":
                    "url(" +
                    require("@/assets/img/new_alpha/misc/male.png") +
                    ")"
                },
                on: {
                  click: function($event) {
                    return _vm.onSexChange("male")
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "onboarding__bottom" }, [
            _c("div", { staticClass: "onboarding__action" }, [
              _c(
                "button",
                {
                  class: { disabled: !_vm.isButtonEnabled },
                  attrs: { disabled: !_vm.isButtonEnabled },
                  on: { click: _vm.goToAvatar }
                },
                [_vm._v("Continua")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.currentStep == 5
      ? _c(
          "div",
          [
            _vm.allAvatarAssets.length === 0
              ? _c("div", { staticClass: "onboarding__loading" })
              : _c("AvatarComponent", {
                  ref: "AvatarVue",
                  attrs: { allAssets: _vm.allAvatarAssets, avatarAssets: [] },
                  on: { "close-avatar": _vm.onOnboardingCompleted }
                })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__text" }, [
      _c("p", { staticClass: "title" }, [_vm._v("Questa è U-City.")]),
      _c("p", { staticClass: "text" }, [_vm._v("Che dici, iniziamo?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__text" }, [
      _c("p", { staticClass: "title" }, [_vm._v("Scegli il tuo nickname?")]),
      _c("p", { staticClass: "text" }, [
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "notificator__dot" }),
            _vm._v(" "),
            _c("div", {}, [
              _vm._v("Scegli tra lettere e numeri, da 5 a 8 caratteri")
            ])
          ]),
          _c("li", [
            _c("div", { staticClass: "notificator__dot" }),
            _c("div", {}, [
              _vm._v("Non usare il tuo vero nome o quello di un collega")
            ])
          ]),
          _c("li", [
            _c("div", { staticClass: "notificator__dot" }),
            _c("div", {}, [
              _vm._v(
                "E mi raccomando, evita espressioni inappropriate: amiamo le buone maniere"
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__full" }, [
      _c("video", { attrs: { id: "videoPlayer", nocontrols: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__text" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v("Scegli quale avatar utilizzare")
      ]),
      _c("p", { staticClass: "text" }, [
        _vm._v("Ricorda: come un diamante, sarà per sempre")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }