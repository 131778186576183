































































































// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../types/extensions.d.ts" />

import { Component, Watch } from "vue-property-decorator";
import videojs, { VideoJsPlayer } from "video.js";

import BaseVue from "./BaseVue";
import AvatarComponent from "@/components/Avatar.vue";
import {
  OnboardingContents,
  UsernameRegistrationPayload,
} from "@/assets/js/RestApiPayloads";
import { AvatarAsset } from "@/assets/js/profile/models/Avatar";
import { GlobalEventBus } from "@/services/GlobalEventBus";
import { Player } from '@/assets/js/world/models/Player';

@Component({
  components: {
    AvatarComponent,
  },
})
export default class Onboarding extends BaseVue {
  name = "Onboarding";
  // @ts-ignore
  videoPlayer: VideoJsPlayer = null;
  hasVideoEnded = false;
  // @ts-ignore
  selectedSex = "";

  username = "";
  allAvatarAssets: AvatarAsset[] = [];
  // @ts-ignore
  onboardingContents: OnboardingContents = null;

  // @ts-ignore
  usernameCheckAvailabilityTimeout: any = null;
  isNicknameAvailable = true;
  usernameChecking = true;

  get isButtonEnabled(): boolean {
    switch (this.currentStep) {
      case 2:
        return this.isNicknameAvailable && !this.usernameChecking && this.username.length >= 5 && this.username.length <= 8;
      case 3:
        return this.hasVideoEnded;
      case 4:
        return this.selectedSex != null && this.selectedSex.length > 0;
      default:
        return true;
    }
  }

  get usernameInputClasses() {
    return {
      available: this.isNicknameAvailable,
      "not-available": !this.isNicknameAvailable,
    };
  }

  private currentStep = 1;
  @Watch("currentStep") onCurrentStepChange(val: number, oldVal: number) {
    if (val > 5) val = 5;

    if (process.env.VUE_APP_SKIP_REAL_ONBOARDING != null && process.env.VUE_APP_SKIP_REAL_ONBOARDING === 'true') {
      // console.log('VUE_APP_SKIP_REAL_ONBOARDING');
    } else {
      if (oldVal > 2 && val <= 2) {
        // non voglio far tornare l'utente alla scelta dello username se l'ha già fatta
        this.currentStep = 3;
        return;
      }
    }

    this.currentStep = val;

    if (val === 3 && this.onboardingContents == null) {
      // console.log("downloading contents");
      this.dataProvider
        .getOnboardingContents()
        .then((contents) => {
          this.onboardingContents = contents;

          this.videoPlayer = videojs(document.getElementById('videoPlayer') as HTMLElement, {
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.onboardingContents.video.mp4,
                type: "video/mp4",
              },
              {
                src: this.onboardingContents.video.m3u8,
                type: "application/x-mpegURL",
              },
            ],
          });
          this.videoPlayer.on('ended', () => {
            this.hasVideoEnded = true;
          });
        });
    }
    if (val === 5 && (this.allAvatarAssets == null || this.allAvatarAssets.length === 0)) {
      // console.log("downloading assets");
      this.dataProvider.getAvatarAssets(this.selectedSex).then((assets) => {
        // console.log("downloaded assets", assets);

        if (assets == null || assets.length === 0) {
          GlobalEventBus.$emit('alert', { text: "Ora non è possibile proseguire con la personalizzazione dell'avatar, riprova più avanti", error: new Error("Ricevuti 0 assets da server") });
          // faccio finta di aver finito di fare l'avatar, lo mando al gioco senza averlo fatto
          this.onOnboardingCompleted();
          return;
        }

        this.allAvatarAssets = assets;
      })
      .catch(error => {
        GlobalEventBus.$emit('alert', { text: "Ora non è possibile proseguire con la personalizzazione dell'avatar, riprova più avanti", error });
        // faccio finta di aver finito di fare l'avatar, lo mando al gioco senza averlo fatto
        this.onOnboardingCompleted();
      });
    }
  }

  @Watch("username")
  onUsernameChanged() {
    this.usernameChecking = true
    setTimeout(() => {
      this.username = this.username.replace(/[^0-9a-zA-Z]/gi, "").toLowerCase();

      if (this.username.length < 5) return;
      if (this.username.length > 8) this.username = this.username.substring(0, 8);

      if (this.usernameCheckAvailabilityTimeout != null) {
        clearTimeout(this.usernameCheckAvailabilityTimeout);
      }

      this.usernameCheckAvailabilityTimeout = setTimeout(async () => {
        // console.log(`checking for ${this.username} availability`);
        try {
          await this.dataProvider.isUsernameAvailable(this.username);
          this.isNicknameAvailable = true;
          this.usernameChecking = false;
        } catch {
          GlobalEventBus.$emit('alert', {
            text: 'Questo username non è disponibile',
            disposeTimeoutMs: 5000
          });
          this.isNicknameAvailable = false;
          this.usernameChecking = false;
        }
      }, 500);
    }, 25);
  }

  created() {
    if (process.env.VUE_APP_SKIP_REAL_ONBOARDING != null && process.env.VUE_APP_SKIP_REAL_ONBOARDING === 'true') {
      console.log('VUE_APP_SKIP_REAL_ONBOARDING');
      this.$store.dispatch("onLoginSuccessful", new Player({}));
      return;
    }

    if (
      this.$route.query["step"] != null &&
      this.$route.query["step"][0] != null &&
      !isNaN(parseInt(this.$route.query["step"][0]))
    )
      this.currentStep = parseInt(this.$route.query["step"][0]) || 1;

    // evitare che uno possa fare back, pulisco lo storico per evitare di tornare a landing page SSO dalla quale arrivo e che mi redirige qui
    var clean_uri = location.protocol + "//" + location.host + location.pathname;
    window.history.replaceState({}, document.title, clean_uri);
  }

  beforeDestroy() {
    if (this.videoPlayer != null) {
      // @ts-ignore
      this.videoPlayer.dispose();
    }
  }

  goToUsername() {
    this.currentStep = 2;
  }

  async goToTutorial() {
    try {
      await this.saveUsername();
      this.currentStep = 3;
    } catch (e) {
      // non passare a step 3
    }
  }

  goToSex() {
    this.currentStep = 4;
    GlobalEventBus.$emit('quattro', { eventKey: 'enter-avatar' });
  }

  async goToAvatar() {
    try {
      if (process.env.VUE_APP_SKIP_REAL_ONBOARDING == null || process.env.VUE_APP_SKIP_REAL_ONBOARDING !== 'true') {
        await this.dataProvider.setUserSex(this.selectedSex);
        this.$store.dispatch('onSetPlayerSex', this.selectedSex);

        // permette a server di refreshare le info cookies e mandare testi con sesso corretto
        const loggedUser = await this.dataProvider.login();
        this.$store.dispatch("onLoginSuccessful", loggedUser);
      }
    
      this.currentStep = 5;
      
      // GlobalEventBus.$emit('quattro', { eventKey: 'enter-menu' });   // x testing coda messaggi Quattro
    } catch (error) {
      // console.log(error);
      GlobalEventBus.$emit('alert', { text: "Errore nel salvataggio sesso", error });
    }
  }

  async saveUsername() {
    if (process.env.VUE_APP_SKIP_REAL_ONBOARDING != null && process.env.VUE_APP_SKIP_REAL_ONBOARDING === 'true') {
      return;
    }

    try {
      const registerPayload: UsernameRegistrationPayload = {
        username: this.username,
      };
      await this.dataProvider.registerUsername(registerPayload);

      const loggedUser = await this.dataProvider.login();
      this.$store.dispatch("onLoginSuccessful", loggedUser);
    } catch (error) {
      // console.log(error);
      GlobalEventBus.$emit('alert', { text: "Errore nel salvataggio username", error });
      throw error;
    }
  }

  onSexChange(input: string) {
    this.selectedSex = input;
  }

  onOnboardingCompleted() {
    if (process.env.VUE_APP_SKIP_REAL_ONBOARDING != null && process.env.VUE_APP_SKIP_REAL_ONBOARDING === 'true') {
      return;
    } else {
      location.reload();
    }
  }
}
