



























import { Component, Prop } from 'vue-property-decorator';

import CampaignCoreApi from '@/services/CampaignCoreApi';
import BaseComponent from './BaseComponent';

@Component
export default class CampaignComponent extends BaseComponent {
  
  @Prop() readonly campaignUrl: string;

  created() {
    window["apiRefClass"] = CampaignCoreApi;
  }

  onFrameLoad() {
    // console.log('iFrame loaded')
  }
}
