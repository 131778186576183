















import { Badge } from '@/assets/js/profile/models/Badge';
import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';

@Component
export default class BadgeStepComponent extends Vue {
  @Prop() readonly badges: Badge[];
}
