export interface IUserPrize {
  id: number;
  url: string;
  title: string;
  description: string;
  timestamp: string;
}

export class Prize {
  id: number;
  url: string;
  title: string;
  description: string;
  timestamp: string;

  get formattedTimestamp(): string {
    if (this.timestamp == null) return '';

    try {
      const date = new Date(Date.parse(this.timestamp));
      const stringIt = date.toLocaleDateString('it-IT', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
      
      return stringIt.split(' ').map(x => x[0].toUpperCase() + x.substring(1)).join(' ');
    } catch {
      return '';
    }
  }

  constructor(data: IUserPrize) {
    this.id = data.id;
    this.url = data.url;
    this.title = data.title;
    this.description = data.description;
    this.timestamp = data.timestamp;
  }
}
