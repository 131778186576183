var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c("div", { staticClass: "ranking" }, [
          _c("div", { staticClass: "header ranking__header" }, [
            _c(
              "div",
              { staticClass: "header__wrapper header__wrapper--right" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sound",
                        rawName: "v-sound",
                        value: "selezione_icona",
                        expression: "'selezione_icona'"
                      }
                    ],
                    staticClass: "menu_button",
                    on: {
                      click: function($event) {
                        return _vm.onDispose()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "header__title" }, [
              _vm._v(" Classifica ")
            ]),
            _vm.profile
              ? _c("div", { staticClass: "ranking__sections--bar" }, [
                  _c(
                    "ul",
                    { ref: "navigation", staticClass: "ranking_sections" },
                    _vm._l(_vm.categories, function(category, index) {
                      return _c(
                        "li",
                        {
                          key: "category-" + category.id,
                          staticClass: "ranking_section",
                          attrs: { "data-index": index },
                          on: {
                            click: function($event) {
                              return _vm.onCategorySelected(category, $event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(category.name) + " ")]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ]),
          _vm.visible
            ? _c(
                "div",
                { staticClass: "ranking__wrapper" },
                [
                  _vm._l(_vm.rankings, function(item) {
                    return _c(
                      "div",
                      {
                        key: "ranking-" + item.rank,
                        staticClass: "ranking__card",
                        class: {
                          "ranking__card--highlighted":
                            item.profileName === _vm.profile.username
                        }
                      },
                      [
                        _c("div", { staticClass: "ranking__card--rank" }, [
                          _vm._v(" " + _vm._s(item.rank) + "° ")
                        ]),
                        _c("div", {
                          staticClass: "ranking__card--pic",
                          style: _vm.imgStyle(item.profileImage)
                        }),
                        _c("div", { staticClass: "ranking__card--text" }, [
                          _c("div", { staticClass: "ranking__card--infos" }, [
                            _c("div", { staticClass: "ranking__card--name" }, [
                              _vm._v(_vm._s(item.profileName))
                            ]),
                            item.isDigitalAmbassador
                              ? _c("div", {
                                  staticClass: "ranking__card--ambassador",
                                  style: {
                                    "background-image":
                                      "url(" +
                                      require("@/assets/img/digitala.png") +
                                      ")"
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "ranking__card--score" }, [
                            _vm._v(" " + _vm._s(item.profileScore))
                          ])
                        ])
                      ]
                    )
                  }),
                  _c("div", { staticClass: "ranking__buttons" }, [
                    _c(
                      "i",
                      {
                        staticClass:
                          "ranking__button ranking__button--prev button",
                        class: {
                          "ranking__button--inactive": !_vm.isPrevButtonEnabled
                        },
                        attrs: {
                          disabled: !_vm.isPrevButtonEnabled || _vm.isLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.prevPage()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              height: "24",
                              viewBox: "0 0 24 24",
                              width: "24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: { d: "M0 0h24v24H0V0z", fill: "none" }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "i",
                      {
                        staticClass:
                          "ranking__button ranking__button--next button",
                        class: {
                          "ranking__button--inactive": !_vm.isNextButtonEnabled
                        },
                        attrs: {
                          disabled: !_vm.isNextButtonEnabled || _vm.isLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              height: "24",
                              viewBox: "0 0 24 24",
                              width: "24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: { d: "M0 0h24v24H0V0z", fill: "none" }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }