import humanizeDuration from 'humanize-duration';
import moment from 'moment';

export enum NotificationType {
  Mixed = "mixed",
  Broadcast = "broadcast",
  Challenge = "challenge",
  ChallengeEnded = "challenge-ended",
  Credits = "credits",
  Experience = "experience",
  Badge = "badge",
  Prize = "prize",
  Helpdesk = "helpdesk",
}

export abstract class BaseNotification {
  id: number;
  type: NotificationType;
  text: string;
  timestamp: string;
  image: string;
  metadata: any;

  get tipo(): string {
    switch (this.type) {
      case NotificationType.Badge:
        return "Badge";
      case NotificationType.Prize:
        return "Premio";
      case NotificationType.Credits:
        return "Bonus";
      case NotificationType.Experience:
        return "Bonus";
      case NotificationType.Challenge:
        return "Sfida";
      case NotificationType.ChallengeEnded:
        return "Sfida";
      case NotificationType.Helpdesk:
        return "Helpdesk";
      default:
        return "Generale";
    }
  }

  get interpolatedText(): string {
    try {
      return this.text.interpolate(this.metadata);
    } catch (e) {
      console.log("Error during notification text interpolation");
      return this.text;
    }
  }

  get relativeDate(): string {
    return humanizeDuration(Date.now() - moment(this.timestamp).valueOf(), { language: "it", largest: 1, round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm'] }) + ' fa';
  }

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.text = data.text;
    this.timestamp = data.timestamp;
    this.metadata = data.metadata;
  }
}

export class MixedNotification extends BaseNotification {
  constructor(data: any) {
    super(data);
  }
}

export class BroadcastNotification extends BaseNotification {
  constructor(data: any) {
    super(data);

    this.image = (data.metadata && data.metadata.image) || 'images/notifications/broadcast.png';
  }
}

export class CreditsNotification extends BaseNotification {
  constructor(data: any) {
    super(data);

    this.image = (data.metadata && data.metadata.image) || 'images/notifications/credits.png';
  }
}

export class ExperienceNotification extends BaseNotification {
  constructor(data: any) {
    super(data);

    this.image = (data.metadata && data.metadata.image) || 'images/notifications/experience.png';
  }
}

export class ChallengeEndedNotification extends BaseNotification {
  constructor(data: any) {
    super(data);

    this.image = data.metadata.image;
  }
}

export class ChallengeNotification extends BaseNotification {
  campaignId: number;
  challengeTimeout: string;

  constructor(data: any) {
    super(data);

    this.image = data.metadata.image;
    this.campaignId = data.metadata.relatedId;
    this.challengeTimeout = data.metadata.challenge_timeout != null ?
      data.metadata.challenge_timeout :
      moment(this.timestamp).add(1, 'day').toISOString();
  }
}

export class BadgeNotification extends BaseNotification {
  badgeId: number;

  constructor(data: any) {
    super(data);

    this.image = data.metadata.image;
    this.badgeId = data.metadata.relatedId;
  }
}

export class PrizeNotification extends BaseNotification {
  prizeId: number;

  constructor(data: any) {
    super(data);

    this.image = data.metadata.image;
    this.prizeId = data.metadata.relatedId;
  }
}

export class NotificationFactory {
  static create(data: any): BaseNotification | null {
    switch (data.type as NotificationType) {
      case NotificationType.Mixed:
        return new MixedNotification(data);
      case NotificationType.Broadcast:
        return new BroadcastNotification(data);
      case NotificationType.Challenge:
        return new ChallengeNotification(data);
      case NotificationType.ChallengeEnded:
        return new ChallengeEndedNotification(data);
      case NotificationType.Credits:
        return new CreditsNotification(data);
      case NotificationType.Experience:
        return new ExperienceNotification(data);
      case NotificationType.Badge:
        return new BadgeNotification(data);
      case NotificationType.Prize:
        return new PrizeNotification(data);
      default:
        console.warn(`No discriminator found for input notification ${data.id}`);
        return null;
    }
  }
}
