export class AvatarAsset {
  id: number;
  type: string;
  type_name: string;
  level: number;
  color: string;
  variation: string;
  url: string;
  dependsOnAssets: number[];
  isSpecial: boolean;

  get isUniqueOnItsOwnType(): boolean {
    return ![''].includes(this.type);
  }

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.type_name = data.type_name;
    this.level = data.level;
    this.color = data.color;
    this.variation = data.variation;
    this.url = data.url;
    this.dependsOnAssets = data.dependsOnAssets;
    this.isSpecial = data.isSpecial;
  }

  canDeriveFrom(asset: AvatarAsset): boolean {
    return this.dependsOnAssets.length === 0 || this.dependsOnAssets.includes(asset.id);
  }

  dependsOn(asset: AvatarAsset): boolean {
    return this.dependsOnAssets.includes(asset.id);
  }
}
