

































































































































































import { Component, Prop } from 'vue-property-decorator';

import RankingComponent from '@/components/Ranking.vue';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { GlobalEventBus } from "@/services/GlobalEventBus";
import { CampaignRanking } from '@/assets/js/RestApiPayloads';
import BaseComponent from '../BaseComponent';

@Component({
  components: {
    RankingComponent,
  }
})

export default class ProfileStatsComponent extends BaseComponent {
  musicOn = this.$store.getters.audio;
  userRankings: CampaignRanking[] = [];

  @Prop() readonly profile: UserProfile;
  @Prop() readonly isLoggedPlayerProfile: boolean;

  async mounted() {
    if (this.isLoggedPlayerProfile) {
      this.userRankings = await this.wrapApiCall(() => this.dataProvider.getUserRankings(), "lettura ranking campagne utente") as CampaignRanking[];
    }
  }

  toggleAudio() {
    this.musicOn = !this.musicOn;
    GlobalEventBus.$emit('toggle-audio', this.musicOn);
    this.$store.dispatch('onAudioToggle', this.musicOn);
  }

  openRanking() {
    GlobalEventBus.$emit('ranking');
  }

  get avatar(): string {
    let image = (this.profile != null && this.profile.imageFace != null) ?
      this.profile.imageFace :
      require('@/assets/img/new_alpha/characters/user.png');

      if(location.hostname.includes('localhost')) {
        image = 'https://ucity-devel.coreconsulting.it' + image
      }
    return image;
  }

  takePhoto() {
    GlobalEventBus.$emit('take-photo')
  }

  goToAvatar() {
    this.$emit('show-avatar');
  }

  dispose() {
    this.$emit('dispose');
  }
}

