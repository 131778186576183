import Vue from 'vue';
import Component from 'vue-class-component';

import { IRestApiProvider } from '@/services/RestApiProviderFactory';
import { GlobalEventBus } from "@/services/GlobalEventBus";
import sanitizeHTML from 'sanitize-html';

@Component
export default class BaseComponent extends Vue {
  get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  get isOffline(): boolean {
    return this.$store.getters.isOffline;
  }
  get dataProvider(): IRestApiProvider {
    return this.$store.getters.dataProvider;
  }

  sanitize(dirtyHtml: string) {
    return sanitizeHTML(dirtyHtml, {
      allowedTags: sanitizeHTML.defaults.allowedTags.concat([ 'img' ])
    })
  }

  async wrapApiCall(apiCall: any, methodName: string) {
    try {
      return await apiCall();
    } catch (e) {
      const alertToEmit = {
        text: `Errore in ${methodName}`,
        error: e,
      };
      
      GlobalEventBus.$emit('alert', alertToEmit);
      // console.error('API ERROR', e);
      // throw e;
    }
  }
}
