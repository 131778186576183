var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "notice", class: { "notice--active": _vm.visible } },
          [
            _c("div", { staticClass: "notice__wrapper" }, [
              _c("div", { staticClass: "notice__wrapper--text" }, [
                _vm._v(" " + _vm._s(_vm.currentNotice) + " ")
              ]),
              _c(
                "div",
                {
                  staticClass: "notice__wrapper--video",
                  class: { "video--active": _vm.showVideo }
                },
                [
                  _c("video", {
                    staticClass: "videoPlayerUcity",
                    attrs: { id: "videoPlayerEnd", nocontrols: "" }
                  })
                ]
              ),
              _c("div", { staticClass: "notice__wrapper--button" }, [
                !_vm.showVideo
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.dispose()
                          }
                        }
                      },
                      [_vm._v("Ok!")]
                    )
                  : _vm._e(),
                _vm.showVideo && _vm.hasVideoEnded
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.dispose()
                          }
                        }
                      },
                      [_vm._v("Continua")]
                    )
                  : _vm._e()
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }