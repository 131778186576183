































































import { Component, Watch } from 'vue-property-decorator';

import BaseComponent from './BaseComponent';
import { GlobalEventBus } from "@/services/GlobalEventBus";

@Component
export default class HeaderComponent extends BaseComponent {
  creditsAnimation = false;
  notificationBadgeCount = 0;
  showHeaderFull = true;

  get showCloseButton(): boolean {
    return this.$store.getters.header.isCloseButtonVisible;
  }
  get showNotificationsButton(): boolean {
    return this.$store.getters.header.isNotificationButtonVisible;
  }
  get showStats(): boolean {
    return this.$store.getters.header.isStatsVisible;
  }
  get isDotShowing(): boolean {
    return this.notificationBadgeCount > 0;
  }
  
  created() {
    GlobalEventBus.$on('set-header-visibility', (visible: boolean) => {
      this.showHeaderFull = visible;
    });
  }

  @Watch('$store.getters.notificationCount')
  onNotificationCountChanged(val: number) {
    this.notificationBadgeCount = val;
  }

  get avatar(): string {
    const image = (this.$store.getters.player != null && this.$store.getters.player.imageFace != null) ?
      this.$store.getters.player.imageFace :
      require('@/assets/img/new_alpha/characters/user.png');

    return `background-image:url('${image}')`;
  }

  @Watch('$store.getters.credits')
  onCreditsChanged() {
    this.creditsAnimation = true;
    setTimeout(() => {
      this.creditsAnimation = false;
    }, 1000);
  }

  formatCash(n : number) : string {
    if (n < 1e3) return n + "";
    if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
    return ''
  }

  get displayedCredits(): string {
    return this.formatCash(this.$store.getters.credits) ;
  }

  get displayedExperience(): string {
    return this.formatCash(this.$store.getters.experience);
  }

  onCloseButtonClick() {
    GlobalEventBus.$emit('header-close-button-clicked');
  }

  onNotificationsButtonClick() {
    GlobalEventBus.$emit('header-notifications-button-clicked');
  }

  onStatsClick() {
    GlobalEventBus.$emit('header-stats-clicked');
  }

}
