






























































import { Component, Prop } from 'vue-property-decorator';

import CampaignComponent from './Campaign.vue';
// import RankingComponent from './Ranking.vue';
import HeaderComponent from '@/components/Header.vue';
import { Campaign, CampaignObject } from '@/assets/js/campaign/models/Campaign.js';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { AlertEventArgs, GlobalEventBus } from "@/services/GlobalEventBus";
import BaseComponent from './BaseComponent';

@Component({
  components: {
    CampaignComponent,
    HeaderComponent,
    // RankingComponent,
  }
})
export default class MultiplayerComponent extends BaseComponent {
  name = "Multiplayer";
  campaignUrl = "";
  dialogHtml = "";
  showCampaign = false;
  showDialog = false;
  campaigns: Campaign[] = [];
  animatedCredits = 0;
  // @ts-ignore
  profile: UserProfile = null;

  @Prop()
  readonly gameStatus: GameStatus;

  get displayName(): string {
    return this.$store.getters.player.username;
  }

  get avatar(): string {
    const image = (this.$store.getters.player != null && this.$store.getters.player.imageFace != null) ?
      this.$store.getters.player.imageFace :
      require('@/assets/img/new_alpha/characters/user.png');

    return `background-image:url('${image}')`;
  }

  async created() {
    try {
      const campaigns = await this.wrapApiCall(() => this.dataProvider.getCampaigns(), "lettura campagne") as Campaign[];
      if (campaigns != null) this.campaigns = campaigns;

      this.profile = await this.wrapApiCall(() => this.dataProvider.getProfile(0), "lettura profilo utente") as UserProfile;
      
      setTimeout(() => {
        if (this.$store.getters.campaignToOpen != null && this.$store.getters.campaignToOpen > 0) {
          const campaignToOpen = this.campaigns.find(x => x.id === this.$store.getters.campaignToOpen);
          if (campaignToOpen != null) {
            this.onOpenCampaign(campaignToOpen);
            this.$store.dispatch('onSetCampaignToOpen', 0);
          }
        }
      }, 100);
    } catch (e) {
      // console.log('unable to get campaigns');
      // console.log(e);
    }
  }

  mounted() {
    GlobalEventBus.$emit('quattro', { eventKey: 'enter-multiplayer' });
    GlobalEventBus.$emit('set-header-visibility', true);  

    GlobalEventBus.$on('go-to-campaign', (campaignId: number) => {
      const campaignToOpen = this.campaigns.find(x => x.id === campaignId);
      if (campaignToOpen != null) {
        this.onOpenCampaign(campaignToOpen);
        this.$store.dispatch('onSetCampaignToOpen', 0);
      }
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off('go-to-campaign');
  }

  // openRanking() {
  //   GlobalEventBus.$emit('ranking');
  // }

  onOpenCampaign(campaign: Campaign) {
    GlobalEventBus.$emit('start-loader', {
      text: 'Stai andando alla campagna',
      scene: '',
      image: 'menu'
    });

    setTimeout(() => {
      this.campaignUrl = `${campaign.url}?campaignId=${campaign.id}`;
      this.showCampaign = true;
      this.showDialog = false;
      this.$emit('show-campaign');

      this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
      this.$store.dispatch('onSetHeaderNotificationButtonVisibility', false);
      this.$store.dispatch('onSetHeaderStatsVisibility', false);

      GlobalEventBus.$emit('notificator', { polling: false });
    }, 500);
  }

  // chiamato da javascript API iframe
  async initCampaign(campaignId: number): Promise<CampaignObject | null> {
    // console.log('initing campaign id', campaignId);

    const campaign = this.campaigns.find(x => x.id === campaignId);
    if (campaign == null) {
      return null;
    }

    return {
      user: {
        id: this.$store.getters.player.id,
        displayName: this.$store.getters.player.displayName,
        categoryId: this.profile.category.id,
        categoryName: this.profile.category.name,
        experience: this.profile.statistics.experience,
        imageFace: this.profile.imageFace,
      },
      campaign: {
        name: campaign.name,
        description: campaign.description,
        image: campaign.image,
        color: campaign.color,
        start: campaign.start,
        end: campaign.end || '',
        enrolled: campaign.enrolled,
      }
    }
  }

  // chiamato da javascript API iframe
  disposeCampaign(target: string): void {
    this.campaignUrl = '';
    this.showCampaign = false;
    this.showDialog = false;
    this.$emit('hide-campaign');

    if (target === 'mappa') {
      // TO DO @frescogusto 
      GlobalEventBus.$emit('header-close-button-clicked');
      
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
      this.$store.dispatch('onSetHeaderStatsVisibility', true);
    } else {
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
      this.$store.dispatch('onSetHeaderStatsVisibility', false);
    }

    this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);

    GlobalEventBus.$emit('notificator', { polling: true });
  }

  // chiamato da javascript API iframe
  showProfile(userId: number): void {
    GlobalEventBus.$emit('show-profile', { userId });
  }

  // chiamato da javascript API iframe
  showAlert(args: AlertEventArgs): void {
    GlobalEventBus.$emit('alert', args);
  }

  // chiamato da javascript API iframe
  async updateUsersStatus(): Promise<void> {
    this.profile = await this.wrapApiCall(() => this.dataProvider.getProfile(0), "lettura profilo utente") as UserProfile;
  }
}
