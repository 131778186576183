var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lobby lobby__lobby-container" },
    [
      _vm.profile
        ? _c("div", {
            staticClass: "lobby__heading",
            class: _vm.headingExtraClass
          })
        : _vm._e(),
      _c("div", { staticClass: "lobby__sections--bar" }, [
        _c(
          "ul",
          { ref: "navigation", staticClass: "lobby_sections" },
          _vm._l(_vm.sections, function(section, index) {
            return _c(
              "li",
              {
                key: "step-" + section.name,
                staticClass: "lobby_section",
                attrs: { "data-index": index },
                on: {
                  click: function($event) {
                    return _vm.onSectionSelected(section, $event)
                  }
                }
              },
              [_vm._v(" " + _vm._s(section.name) + " ")]
            )
          }),
          0
        )
      ]),
      _c("canvas", {
        attrs: { id: "lobbyCanvas", width: "1024", height: "1024" }
      }),
      _vm.currentSection == "privacy" ? _c("PrivacyStepComponent") : _vm._e(),
      _vm.currentSection == "helpdesk" ? _c("HelpdeskStepComponent") : _vm._e(),
      _vm.currentSection == "stats" && _vm.profile != null
        ? _c("ProfileStatsComponent", {
            attrs: { profile: _vm.profile, isLoggedPlayerProfile: true },
            on: { "show-avatar": _vm.onShowAvatar }
          })
        : _vm._e(),
      _vm.currentSection == "badge"
        ? _c("BadgeStepComponent", { attrs: { badges: _vm.badges } })
        : _vm._e(),
      _vm.currentSection == "account"
        ? _c("AccountStepComponent", { on: { logout: _vm.logout } })
        : _vm._e(),
      _c("FaqComponent"),
      _c("RegolamentoComponent")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }