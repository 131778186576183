/* eslint-disable @typescript-eslint/no-explicit-any */

import { BlockType } from './Enums';

export class UserBlockProgress {
  blockKey: string;
  blockType: BlockType;

  constructor(data: any) {
    this.blockKey = data.blockKey;
    this.blockType = data.blockType;
  }
}
