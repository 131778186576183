





















import {
  Component,
} from "vue-property-decorator";
import { GlobalEventBus } from '@/services/GlobalEventBus';
import BaseComponent from './BaseComponent';


@Component
export default class RegolamentoComponent extends BaseComponent {
  content = '';
  visible = false;

  created() {
    GlobalEventBus.$on('open-regolamento', () => {
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
      this.visible = true
    });
  }

  async mounted() {
    try {
      const staticPage = await this.dataProvider.getStaticPage('rules');
      this.content = this.sanitize(staticPage.content);
      this.content += '<br><br>'
    } catch (e) {
      GlobalEventBus.$emit('alert', {
        text: 'Impossibile recuperare il regolamento. Contattare il supporto tecnico',
      });
    }
  }

  beforeDestroy() {
    GlobalEventBus.$off('open-regolamento');
  }

  onDispose() {
    this.visible = false;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
  }
}
