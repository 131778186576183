import Vue from 'vue';
export const GlobalEventBus = new Vue();

export interface QuattroEventArgs {
  eventKey: string;
}

export interface QuattroRaisedEventArgs {
  eventKey: string;
  wasShown: boolean;
}

export interface AlertEventArgs {
  text: string;
  error?: any;
  disposeTimeoutMs: number;
  code?: number;
  isUniqueInQueue?: boolean;
  isDisposable?: boolean;
}

export interface HintEventArgs {
  text: string;
  disposeTimeoutMs: number;
  code?: number;
}

export interface NoticeEventArgs {
  text: string;
  disposeTimeoutMs: number;
  code?: number;
  video?: boolean;
}


export interface NetworkStatusChangeEventArgs {
  isOnline: boolean;
}

export interface NotificatorEventArgs {
  polling: boolean;
}

export interface ShowProfileEventArgs {
  userId: number;
}

export interface ShowProfileRaisedEventArgs {
  // empty
}

export interface NotificationsEventArgs {
  // empty
}

export interface NotificationsRaisedEventArgs {
  // empty
}

export interface RankingEventArgs {
  // empty
}

export interface RankingRaisedEventArgs {
  // empty
}

export interface HeaderCloseButtonClickedEventArgs {
  // empty
}

export interface HeaderNotificationsButtonClickedEventArgs {
  // empty
}

export interface HeaderStatsClickedEventArgs {
  // empty
}
