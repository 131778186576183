export interface IUserBadge
{
  badge: IBadge;
  ownedTimestamp?: Date;
}

export interface IBadge
{
  id: number;
  url: string;
  title: string;          // indizio su come prendere quel badge
  description: string;    // quando l'hai preso è la desc di cosa ha fatto l'utente x prenderlo
}

export class Badge {
  id: number;
  url: string;
  title: string;
  description: string;
  ownedTimestamp?: Date;

  get isOwned(): boolean {
    return this.ownedTimestamp != null;
  }

  constructor(data: IUserBadge) {
    this.id = data.badge.id;
    this.url = data.badge.url;
    this.title = data.badge.title;
    this.description = data.badge.description;
    this.ownedTimestamp = data.ownedTimestamp;
  }
}
