








import { Component } from "vue-property-decorator";

import { GlobalEventBus, NetworkStatusChangeEventArgs } from "@/services/GlobalEventBus";
import BaseComponent from './BaseComponent';

@Component
export default class OfflineComponent extends BaseComponent {
  visible = false;

  created() {
    GlobalEventBus.$on("network-status-change", async (args: NetworkStatusChangeEventArgs) => {
      this.visible = !args.isOnline;
    });
  }
}
