










import { Component } from 'vue-property-decorator';

import BaseComponent from './BaseComponent';
import ProfileStatsComponent from './Common/ProfileStats.vue';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { GlobalEventBus, ShowProfileEventArgs } from '@/services/GlobalEventBus';

@Component({
  components: {
    ProfileStatsComponent,
  }
})
export default class ProfileComponent extends BaseComponent {
  // @ts-ignore
  profile: UserProfile = null;
  visible = false;

  created() {
    GlobalEventBus.$on('show-profile', async (args: ShowProfileEventArgs) => {
      // console.log('Show profile:', args.userId);

      const profile = await this.wrapApiCall(() => this.dataProvider.getProfile(args.userId), "lettura profilo") as UserProfile;
      if (profile != null) {
        this.profile = profile;
        this.visible = true;
        GlobalEventBus.$emit('show-profile-raised');
      }
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off('show-profile');
  }

  dispose() {  
    this.visible = false;
  }
}
