export interface UserCategory {
  id: number;
  name: string;
  image: string;
  rank: number;
}

export interface UserStatistics {
  experience: number;
  stars: number;
  credits: number;
}

export class UserProfile {
  displayName: string;
  username: string;
  sex: string;
  imageFace: string;
  imageBody: string;
  category: UserCategory;
  statistics: UserStatistics;
  isDigitalAmbassador: boolean;

  constructor(data: any) {
    this.displayName = data.displayName;
    this.username = data.username;
    this.sex = data.sex;
    this.imageFace = data.imageFace;
    this.imageBody = data.imageBody;
    this.category = data.category;
    this.statistics = data.statistics;
    this.isDigitalAmbassador = data.isDigitalAmbassador;
  }
}
