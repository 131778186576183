import { FrameQuestPayload } from '@/assets/js/world/models/Quest';
import { PlayerPayload } from '@/assets/js/RestApiPayloads';
import { QuestStarsOutcomes, QuestStatusNumberValueMap } from '@/assets/js/world/models/Enums';
import { JsApiError, JsApiErrorStatusCode } from './CustomErrors';

export default class QuestCoreApi {
  private readonly questId: number;
  private readonly token: string;

  private tokenForAddCredits: string | null = null;
  private tokenForAddExp: string | null = null;
  private currentStars: QuestStarsOutcomes = QuestStarsOutcomes.Zero;
  private currentStatus = 0;

  constructor({ questId, token }: { questId: number; token: string }) {
    if (questId == null || token == null)
      throw new JsApiError(JsApiErrorStatusCode.MissingParameters, "QuestCoreApi ctor", "Mandatory parameters are missing");

    // console.log('QuestCoreApi ctor. questId passed: ' + questId);
    this.questId = questId;
    this.token = token;
  }

  async init(): Promise<FrameQuestPayload> {
    try {
      const result: FrameQuestPayload = await (document.getElementById('home') as any).__vue__.initQuest(this.questId);
      if (result != null) {
        this.currentStars = result.state.stars;
        this.currentStatus = QuestStatusNumberValueMap[result.state.status];
        return result;
      }
      
      throw new JsApiError(JsApiErrorStatusCode.QuestNotFound, "QuestCoreApi.init", `Quest ${this.questId} not found, maybe there is a questId misuse in quest configuration`);
    } catch (e) {
      console.error(e);
      throw new JsApiError(JsApiErrorStatusCode.UnexpectedError, "QuestCoreApi.init", "Unexpected error", e);
    }
  }

  async addCredits(value: number): Promise<void> {
    // console.log('QuestCoreApi.addCredits', value);
    
    if (this.tokenForAddCredits != null && this.tokenForAddCredits === this.token) {
      console.warn("Forbidden operation, token misused");
      throw new JsApiError(JsApiErrorStatusCode.TokenAlreadyUsed, "QuestCoreApi.addCredits", "Forbidden operation, token misused");
    }

    try {
      await (document.getElementById('home') as any).__vue__.addOrSubtractCredits(value, this.questId);
      this.tokenForAddCredits = this.token;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async addExp(value: number): Promise<void> {
    // console.log('QuestCoreApi.addExp', value);

    if (this.tokenForAddExp != null && this.tokenForAddExp === this.token) {
      console.warn("Forbidden operation, token misused");
      throw new JsApiError(JsApiErrorStatusCode.TokenAlreadyUsed, "QuestCoreApi.addExp", "Forbidden operation, token misused");
    }

    try {
      await (document.getElementById('home') as any).__vue__.addExp(this.questId, value);
      this.tokenForAddExp = this.token;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async setStar(value: QuestStarsOutcomes): Promise<void> {
    // console.log('QuestCoreApi.setStar', value);

    if (this.currentStars == value) return;
    if (this.currentStars > value)
      throw new JsApiError(JsApiErrorStatusCode.ForbiddenValue, "QuestCoreApi.setStars", `Trying to store ${value}, but current stars value is ${this.currentStars}`);

    try {
      await (document.getElementById('home') as any).__vue__.setStars(this.questId, value);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async setStatus(value: string): Promise<void> {
    // console.log('QuestCoreApi.setStatus', value);

    const numberValue = QuestStatusNumberValueMap[value];
    if (this.currentStatus == numberValue) return;
    if (this.currentStatus > numberValue)
      throw new JsApiError(JsApiErrorStatusCode.ForbiddenValue, "QuestCoreApi.setStatus", `Trying to store ${numberValue}, but current status is more advanced (${this.currentStatus})`);

    try {
      await (document.getElementById('home') as any).__vue__.setStatus(this.questId, value);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async setData(value: string): Promise<void> {
    // console.log('QuestCoreApi.setData', value);
    
    try {
      await (document.getElementById('home') as any).__vue__.setSuspendData(this.questId, value);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getPlayer(): Promise<PlayerPayload> {
    // console.log('QuestCoreApi.getPlayer');
    
    try {
      const player = await (document.getElementById('home') as any).__vue__.getPlayer() as PlayerPayload;
      return player;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  dispose(): void {
    (document.getElementById('home') as any).__vue__.disposeQuest(this.questId);
  }
}
