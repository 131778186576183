







import { Component } from "vue-property-decorator";

import {
  GlobalEventBus,
  NotificatorEventArgs,
} from "@/services/GlobalEventBus";
import {
  BaseNotification,
  ChallengeEndedNotification,
  ChallengeNotification,
  CreditsNotification,
  ExperienceNotification,
  MixedNotification,
  NotificationType,
} from "@/assets/js/profile/models/Notifications";
import BaseComponent from './BaseComponent';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';

class PollingErrors {
  network: number;
  unauthorized: number;

  constructor() {
    this.network = 0;
    this.unauthorized = 0;
  }

  reset() {
    this.network = 0;
    this.unauthorized = 0;
  }

  addNetworkError() {
    this.network += 1;
    this.unauthorized = 0;
  }

  addUnauthorizedError() {
    this.network = 0;
    this.unauthorized += 1;
  }
}

@Component
export default class NotificatorComponent extends BaseComponent {
  queue: BaseNotification[] = [];

  // @ts-ignore
  intervalHandler: any = null;
  intervalInSeconds = 15;
  autoCloseTimeout = 3 * 1000;
  pollingErrors: PollingErrors = new PollingErrors();
  // consecutiveNetworkErrorCounter = 0;
  // consecutiveUnauthorizedErrorCounter = 0;
  MAX_CONSECUTIVE_NETWORK_ERROR_FAILURES_ACCEPTED = 3;
  MAX_CONSECUTIVE_UNAUTHORIZED_FAILURES_ACCEPTED = 5;

  get currentNotification(): BaseNotification | null {
    return this.queue != null ? this.queue[0] : null;
  }

  get message(): string {
    if (this.queue != null && this.queue.length > 0) return this.queue[0].interpolatedText;

    return this.currentNotification != null ? this.currentNotification.interpolatedText : "";
  }

  get visible(): boolean {
    return this.currentNotification != null;
  }

  created() {
    GlobalEventBus.$on("notificator", async (args: NotificatorEventArgs) => {
      // console.log("Notificator event:", args);
      args.polling ? this.startPolling() : this.pausePolling();
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off("notificator");
    this.pausePolling();
  }

  startPolling() {
    if (this.intervalHandler != null) {
      // sono già in polling, skippo
      return;
    }
    
    this.intervalHandler = setInterval(async () => {
      // console.log("polling from id", this.$store.getters.lastNotification);
      let notifications: BaseNotification[] = [];

      try {
        notifications = await this.dataProvider.getNotificationsAfterId(this.$store.getters.lastNotification);
        this.pollingErrors.reset();
      } catch (e) {
        this.handlePollingError(e);
      }
      
      if (notifications == null || notifications.length === 0) return;
      
      const lastNotification = notifications[notifications.length - 1].id;
      this.$store.dispatch(
        "onSetLastNotification",
        lastNotification
      );

      if (notifications.length > 1) {
        this.showMessage(this.createMixedNotification(notifications.length));
      } else {
        this.showMessage(notifications[0]);
      }

      if (notifications.some(x => x instanceof CreditsNotification ||
        x instanceof ExperienceNotification ||
        x instanceof ChallengeEndedNotification)) {
        // console.log('aggiorno stats profilo');
        await this.updatePlayerStats();
      }
    }, this.intervalInSeconds * 1000);
  }

  /**
   * Se è network error, mi concedo 3 possibili chiamate "fallite" di fila, perché magari server è in fase di deploy ed
   * è unresponsive per qualche decina di secondi.
   * Altrimenti, espongo sempre alert di errore.
   */
  handlePollingError(e: any) {
    let isNetworkError = false;
    try {
      isNetworkError = e.error != null && e.error.message != null &&
        ((e.error.message as string).toLowerCase().includes("network") || (e.error.message as string).toLowerCase().includes("502"));
    } catch {
      // empty
    }

    let isUnauthorizedError = false;
    try {
      isUnauthorizedError = e.axiosError != null && e.axiosError.responseErrorCode === 401;
    } catch {
      // empty
    }

    if (isNetworkError) {
      this.pollingErrors.addNetworkError();
      if (this.pollingErrors.network > this.MAX_CONSECUTIVE_NETWORK_ERROR_FAILURES_ACCEPTED) {
        this.pollingErrors.reset();
        this.emitAlertPollingFailed(e);
      }
    } else if (isUnauthorizedError) {
      this.pollingErrors.addUnauthorizedError();
      if (this.pollingErrors.unauthorized > this.MAX_CONSECUTIVE_UNAUTHORIZED_FAILURES_ACCEPTED) {
        this.pollingErrors.reset();
        this.emitAlertPollingFailed(e);
        this.pausePolling();
      }
    } else {
      this.pollingErrors.reset();
      this.emitAlertPollingFailed(e);
    }
  }

  emitAlertPollingFailed(e: any) {
    GlobalEventBus.$emit("alert", {
      text: "Errore in polling notitiche",
      isUniqueInQueue: true,
      error: e
    });
  }

  createMixedNotification(length: number): MixedNotification {
    return new MixedNotification({
      id: 0,
      text: `Hai ricevuto ${length} nuove notifiche`,
      type: NotificationType.Mixed,
      data: {},
      discriminator: "mixed",
    });
  }

  async updatePlayerStats() {
    const profile = await this.wrapApiCall(() => this.dataProvider.getProfile(0), "lettura profilo utente") as UserProfile;
    if (profile != null) {
      this.$store.dispatch('onSetCredits', profile.statistics.credits);
      this.$store.dispatch('onSetExperience', profile.statistics.experience);
    }
  }

  pausePolling() {
    // console.log("pause polling");
    try {
      clearInterval(this.intervalHandler);
      // @ts-ignore
      this.intervalHandler = null;
    } catch (e) {
      // empty
    }
  }

  showMessage(notification: BaseNotification) {
    this.queue.push(notification);

    GlobalEventBus.$emit('play-sound', 'notifica')

    setTimeout(() => {
      try {
        const indexToRemove = this.queue.findIndex(
          (x) => x.id === notification.id
        );
        this.queue.splice(indexToRemove, 1);
      } catch (e) {
        // console.log("già rimosso");
      }
    }, this.autoCloseTimeout);
  }

  onNotificationClicked() {
    try {
      if (this.currentNotification instanceof ChallengeNotification) {
        this.$emit('show-multiplayer', this.currentNotification.campaignId);
      }

      this.queue.splice(0, 1);
    } catch (e) {
      // console.log(e);
    }
  }
}
