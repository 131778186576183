var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lobby__body lobby__body--account" }, [
    _c("div", { staticClass: "lobby__full" }, [
      _vm._v("Ultimo Accesso: " + _vm._s(_vm.lastLogin)),
      _c("br"),
      _vm._v(" Versione " + _vm._s(_vm.clientVersion) + " ")
    ]),
    _vm._m(0),
    _c("div", { staticClass: "lobby__full account__logout" }, [
      _c("div", [_vm._v("Vuoi uscire da U-City?")]),
      _c(
        "div",
        {
          staticClass: "logout__button",
          on: {
            click: function($event) {
              return _vm.logout()
            }
          }
        },
        [_vm._v("Logout")]
      )
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lobby__full account__download" }, [
      _c("div", [
        _vm._v(
          " Chi conosce la App Mobile Banking ha grandi vantaggi su U-City!"
        ),
        _c("br"),
        _c("br"),
        _vm._v("Scaricala sul tuo smartphone")
      ]),
      _c("div", { staticClass: "account__download--images" }, [
        _c("div", { staticClass: "account__download--image" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://play.google.com/store/apps/details?id=com.unicredit",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/lobby/google.png"),
                  alt: ""
                }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "account__download--image" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://itunes.apple.com/it/app/unicredit/id396670008?mt=8",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/img/lobby/apple.png"), alt: "" }
              })
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lobby__full logo__core" }, [
      _c("div", [_vm._v("Sviluppato da")]),
      _c("img", {
        staticStyle: { height: "100%" },
        attrs: {
          src: require("@/assets/img/lobby/coreconsulting_logotype.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }