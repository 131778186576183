var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user__cta",
      class: _vm.dynamicClasses,
      attrs: { disabled: _vm.isSpinning },
      on: {
        click: function($event) {
          return _vm.onClick()
        }
      }
    },
    [
      !_vm.isSpinning
        ? _c("div", [_vm._v(_vm._s(_vm.text))])
        : _c("div", { staticClass: "dot-loader" }, [
            _c("div", { staticClass: "dot" }),
            _c("div", { staticClass: "dot" }),
            _c("div", { staticClass: "dot" })
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }