















































// eslint-disable @typescript-eslint/ban-ts-ignore

import { Component, Prop } from 'vue-property-decorator';
import Flickity from 'flickity';
import lodash from 'lodash';

import BaseComponent from './BaseComponent';
import { UserAvatarPayload } from '@/assets/js/RestApiPayloads';
import { AvatarAsset } from '@/assets/js/profile/models/Avatar';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { GlobalEventBus } from '@/services/GlobalEventBus';
import AsyncButtonComponent from '@/components/Common/AsyncButton.vue';

const MANDATORY_TYPES = ['face', 'eye', 'nose', 'mouth', 'eyebrows'];

interface AssetTypeModel {
  type: string;
  isSelected: boolean;
  hasUnderlyingSelection: boolean;
  isComplete: boolean;
  containsSpecialAssets: boolean;
}

interface AssetModel {
  asset: AvatarAsset;
  isSelected: boolean;
  isSelectable: boolean;
}

@Component({
  components: {
    AsyncButtonComponent,
  }
})
export default class AvatarComponent extends BaseComponent {
  isSaving = false;
  // @ts-ignore
  selectedType: AssetTypeModel = null;
  selectedAssets: AvatarAsset[] = [];
  flickity: Flickity;

  @Prop() readonly gameStatus: GameStatus;
  @Prop() readonly allAssets: AvatarAsset[];
  @Prop() readonly avatarAssets: number[];

  get types(): AssetTypeModel[] {
    return lodash.uniqBy(this.allAssets, 'type').map(x => {
      const selectedAssetForCurrentType = this.selectedAssets.find(y => y.type === x.type);
      return {
        type: x.type,
        displayType: x.type_name,
        isSelected: this.selectedType != null ? this.selectedType.type === x.type : false,
        hasUnderlyingSelection: selectedAssetForCurrentType != null,
        isComplete: !MANDATORY_TYPES.includes(x.type) || selectedAssetForCurrentType != null,
        containsSpecialAssets: this.allAssets.find(y => y.type === x.type && y.isSpecial) != null,
      }
    });
  }

  get assetsToShow(): AssetModel[] {
    return this.allAssets.filter(x => x.type === this.selectedType.type).map(x => {
      const idDependencies = this.allAssets.filter(a => x.dependsOnAssets.includes(a.id));
      const idCurrentSelectedDependencies = this.selectedAssets.filter(a => x.dependsOnAssets.includes(a.id));
      const typeDependencies = {};
      idDependencies.forEach(d => {
        if (typeDependencies[d.type]) {
          typeDependencies[d.type].push(d.id);
        } else {
          typeDependencies[d.type] = [d.id];
        }
      });
      const typeCurrentSelectedDependencies = {};
      idCurrentSelectedDependencies.forEach(d => {
        if (typeCurrentSelectedDependencies[d.type]) {
          typeCurrentSelectedDependencies[d.type].push(d.id);
        } else {
          typeCurrentSelectedDependencies[d.type] = [d.id];
        }
      });

      let canBeSelected = true;
      lodash.forOwn(typeDependencies, (value, key) => {
        if (!typeCurrentSelectedDependencies[key] || typeCurrentSelectedDependencies[key].length === 0 || lodash.intersection(typeCurrentSelectedDependencies[key], value).length === 0)
          canBeSelected = false;
      });
      return {
        asset: x,
        isSelected: this.selectedAssets.find(y => y.id === x.id) != null,
        isSelectable: canBeSelected,
      }
    });
    
  }

  get sortedAssetsToShow(): AssetModel[]  {
    const sorted = lodash.sortBy(this.assetsToShow, [
      function(x) { return x.asset.color; },
      function(x) { return x.asset.variation; }
    ]);
    return sorted
  }

  get assetsToSuperimpose(): AvatarAsset[] {
    return lodash.sortBy(this.selectedAssets, ['level', 'id']);
  }

  get assetsToShowAsSelected(): AvatarAsset | null {
    if (this.selectedType == null) return null;

    const selectedAssetForCurrentSelectedType = this.selectedAssets.find(x => x.type === this.selectedType.type);
    return selectedAssetForCurrentSelectedType != null ? selectedAssetForCurrentSelectedType : null;
  }

  get isComplete(): boolean {
    const selectedTypes = this.selectedAssets.map(x => x.type);
    return MANDATORY_TYPES.every(x => selectedTypes.includes(x));
  }

  setAssetsBodyHeight() {
    // console.log(this.$el);
    
    if (this.$el.querySelector('.lobby__heading')) {
      const el = this.$el.querySelector('.lobby__heading') as HTMLElement;
      const height = window.innerHeight - el.offsetHeight;
      const bodyContent = this.$el.querySelector('.lobby__body--content') as HTMLElement;
      bodyContent.style.height = height - 60 + 'px';
    }

  }

  created() {
    // console.log('allAssets', this.allAssets);
    // console.log(this.avatarAssets);
    this.selectedType = this.types[0];    
    
    if (this.avatarAssets != null && this.avatarAssets.length > 0) {
      this.selectedAssets = this.allAssets.filter(x => this.avatarAssets.includes(x.id));
      this.clearAvatarAssetsIfAnyDidNotMatch();
    }

    this.setFaceIfNonSelected();
  }

  mounted() {
    this.setAssetsBodyHeight();

     this.flickity = new Flickity((this.$refs.navigation as Element), {
      cellAlign: 'center',
      pageDots: false,
    });

    this.flickity.on('select', (index: any) => {
      this.onTypeSelected(this.types[index], null);
    });

  }

  clearAvatarAssetsIfAnyDidNotMatch() {
    // se le dimensioni non matchano, significa che l'avatar era composto da qualche pezzo che adesso il server
    // non mi restituisce più tra gli allAssets disponibili: significa che hanno fatto qualche porcata sugli assets,
    // noi per precauzione sbianchiamo tutto quello che aveva scelto l'utente, lo resettiamo
    if (this.selectedAssets.length !== this.avatarAssets.length) {
      this.selectedAssets = [];
    }
  }

  setFaceIfNonSelected() {
    if (this.selectedAssets.find(x => x.type === 'face') == null) {
      const firstFaceFound = this.allAssets.find(x => x.type === 'face');

      if (firstFaceFound != null) {
        this.selectedAssets.push(firstFaceFound);
      } else {
        GlobalEventBus.$emit('alert', { text: 'Mancano assets facciali, contattare il supporto tecnico' });
      }
    }
  }

  onTypeSelected(type: AssetTypeModel, index: any) {
    // console.log('onTypeSelected', type);
     if (index && this.flickity) {
      this.flickity.select(index)
    }

    this.selectedType = type;
  }

  onAssetSelected(asset: AssetModel) {
    if (!asset.isSelectable || this.isSaving) {
      // console.log('asset is not selectable!!!', asset);
      return;
    }

    if (this.selectedAssets.find(x => x.id === asset.asset.id) != null) {
      // console.log('asset already selected', asset);
      
      // you can deselect it if is not a mandatory type
      if (!MANDATORY_TYPES.includes(asset.asset.type)) {
        // console.log('deselect');
        const index = this.selectedAssets.indexOf(asset.asset);
        if (index > -1) {
          this.selectedAssets.splice(index, 1);
        } 
      }
      return;
    }

    if (asset.asset.isUniqueOnItsOwnType) {
      const oldSelectionOnSameType = this.selectedAssets.find(x => x.type === asset.asset.type);

      if (oldSelectionOnSameType != null) {
        // controlla che a cascata non ci sia qualche asset che dipendeva dalla old selection e che ora non può essere mantenuto
        const conflictingCascadeSelectedAssets = this.selectedAssets.filter(x => x.dependsOn(oldSelectionOnSameType));
        if (conflictingCascadeSelectedAssets != null && conflictingCascadeSelectedAssets.length > 0) {
          // console.log('Possible conflicting previously selected assets', conflictingCascadeSelectedAssets);
          const toKeep = conflictingCascadeSelectedAssets.filter(x => x.canDeriveFrom(asset.asset));
          // console.log('ToKeep assets', toKeep);
          this.selectedAssets = this.selectedAssets.filter(x => !conflictingCascadeSelectedAssets.map(c => c.id).includes(x.id) || toKeep.map(c => c.id).includes(x.id));
        }
        
        this.selectedAssets = this.selectedAssets.filter(x => x.type !== oldSelectionOnSameType.type);
      }
    }    

    this.selectedAssets.push(asset.asset);
  }

  async drawImagesInCanvasFull() {
    const canvas = document.getElementById('lobbyCanvasFull');
    const ctx = (canvas as HTMLCanvasElement).getContext('2d');
    const images = document.querySelectorAll('.avatar_image');

    const els = Array.from(images);
    for (let i = 0; i < els.length; i += 1) {

      const img = els[i] as HTMLImageElement;
      // console.log(img.src);
      
      let asd = await this.loadImageFromBlob(img.src) as HTMLImageElement

      // const img = els[i] as HTMLImageElement;
      ctx!.drawImage(asd, 0, 0, 1024, 1024);
    }

    const dataUrl = (canvas as HTMLCanvasElement).toDataURL();
    return dataUrl;
  }

  async drawImagesInCanvasFace() {
    const canvas = document.getElementById('lobbyCanvasFace');
    const ctx = (canvas as HTMLCanvasElement).getContext('2d');
    const images = document.querySelectorAll('.avatar_image');

    const els = Array.from(images);
    for (let i = 0; i < els.length; i += 1) {
      const img = els[i] as HTMLImageElement;
      let asd = await this.loadImageFromBlob(img.src) as HTMLImageElement
      ctx!.drawImage(asd, -192, 0, 1024, 1024);
    }

    const dataUrl = (canvas as HTMLCanvasElement).toDataURL();
    return dataUrl;
  }

  async loadImageFromBlob(url : any) {

    return new Promise((resolve, reject) => {
      window.fetch(url, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then(resp => resp.blob())
        .then(blob => {
          const urlFromBlob = window.URL.createObjectURL(blob);

          const image = new window.Image()
          image.src = urlFromBlob;
          image.crossOrigin = 'Anonymous';
         
          image.addEventListener('load', () => {
            resolve(image);
          })
          image.addEventListener('error', reject);

        })
        .catch(error => {
          GlobalEventBus.$emit('alert', { text: 'Errore nel caricamento immagini per salvataggio avatar', error });
          reject(error);
        })
    })
  }

  async saveConfiguration() {
    if (!this.isComplete) {
      GlobalEventBus.$emit('alert', {
        text: 'Avatar incompleto!',
        disposeTimeoutMs: 1500,
      });
      
      return;
    }

    if(this.isSaving) {
      return;
    }

    this.isSaving = true;
    
    let dataUrlFull = "";
    let dataUrlFace = "";
    try {
      dataUrlFull = await this.drawImagesInCanvasFull();
      dataUrlFace = await this.drawImagesInCanvasFace();
      // console.log(dataUrlFull);
    } catch {
      this.isSaving = false;
      return;
    }

    // GlobalEventBus.$emit('play-sound', 'tick');

    try {
      const data: UserAvatarPayload = {
        assets: this.selectedAssets.map(x => x.id),
        imageFace: dataUrlFace.replace("data:image/png;base64,", ""),
        imageBody: dataUrlFull.replace("data:image/png;base64,", ""),
      };

      const avatar = await this.dataProvider.setUserAvatar(data);
      this.$store.dispatch('onProfileImageFaceUpdate', avatar.imageFace);
      this.$emit('close-avatar');
    } catch (error) {
      GlobalEventBus.$emit('alert', { text: "Errore nel salvataggio avatar", error });
    } finally {
      this.isSaving = false;
    }
  }
}
