



























































import { Component } from "vue-property-decorator";
import Flickity from 'flickity';

import { GlobalEventBus } from '@/services/GlobalEventBus';
import { Category, Ranking } from '@/assets/js/campaign/models/Ranking';
import BaseComponent from './BaseComponent';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';

interface TabCategorySection {
  id: number,
  name: string,
  isSelected: boolean,
}

@Component
export default class RankingComponent extends BaseComponent {
  isLoading = true;
  pageIndex = 0;
  pageSize = 10;
  flickity: Flickity;
  categories: TabCategorySection[] = [];
  rankings: Ranking[] = [];
  visible = false;
  // @ts-ignore
  profile: UserProfile = null;

  get selectedCategory(): number {
    if (this.categories == null || this.categories.length === 0) return 0;

    return this.categories.findIndex(x => x.isSelected) + 1;
  }

  get isPrevButtonEnabled(): boolean {
    return this.pageIndex > 0;
  }

  get isNextButtonEnabled(): boolean {
    return this.rankings != null && this.rankings.length === this.pageSize;
  }

  async created() {
    GlobalEventBus.$on('ranking', async () => {
      GlobalEventBus.$emit('ranking-raised');
      this.visible = true;

      await this.loadData();

      this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
    });
  }

  async loadData() {
    try {
      this.profile = await this.wrapApiCall(() => this.dataProvider.getProfile(0), "lettura profilo utente") as UserProfile;

      const categories = await this.wrapApiCall(() => this.dataProvider.getCategories(), "lettura categorie") as Category[];
      if (categories != null) {
        this.categories = categories.map((x: any) => {
          return {
            id: x.id,
            name: x.name,
            isSelected: false,
          }
        });
      }
      
      setTimeout(() => {
        this.flickity = new Flickity((this.$refs.navigation as Element), {
          cellAlign: 'center',
          pageDots: false,
        });
        this.flickity.on('select', async (index: any) => {        
          await this.onCategorySelected(this.categories[index], null);
        });
        // devo selezionare la category dove sono io attualmente this.profile.category.id
        this.flickity.select(this.categories.findIndex(x => x.id === this.profile.category.id));
      }, 25);

      setTimeout(async () => {
        this.pageIndex = Math.floor(this.profile.category.rank / 10);
        await this.fetchRankings();
      }, 250);
    } catch (error) {
      // console.log(error);
    }
  }

  beforeDestroy() {
    GlobalEventBus.$off('ranking');
  }

  imgStyle(profileImage: string) {
    return {
      'background-image': `url(${profileImage})`
    };
  }

  async fetchRankings() {
    this.isLoading = true;
    const rankings = await this.wrapApiCall(() => this.dataProvider.getRanking(this.selectedCategory, this.pageIndex, this.pageSize), "lettura rankings") as Ranking[];
    this.isLoading = false;

    if (rankings != null) this.rankings = rankings;
  }

  async onCategorySelected(section: TabCategorySection, event: any) {
    // console.log('onCategorySelected', section, event);
    this.pageIndex = 0;

    const selected = this.categories.find(x => x.isSelected);
    if (selected != null) selected.isSelected = false;

    if (event && this.flickity) {
      // console.log(event);
      this.flickity.select(parseInt(event.target.dataset.index, 10));
    }

    this.categories.find(x => x.id === section.id)!.isSelected = true;
    await this.fetchRankings();
  }

  async nextPage() {
    if (this.isNextButtonEnabled) {
      this.pageIndex += 1;
      await this.fetchRankings();
    }
  }

  async prevPage() {
    if (this.pageIndex > 0) {
      this.pageIndex -= 1;
      await this.fetchRankings();
    }
  }

  onDispose() {
    this.visible = false;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
  }

  onShowProfile(userId: number) {
    GlobalEventBus.$emit('show-profile', { userId });
  }
}
