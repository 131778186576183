var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "game" } }, [
    _vm._m(0),
    _vm.sceneType == 1 && _vm.showFooter
      ? _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "footer__wrapper" }, [
            _c("div", { staticClass: "footer__left" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass: "quests__button",
                  class: { "icon--active": _vm.selectorView == 0 },
                  on: {
                    click: function($event) {
                      return _vm.switchView(0)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "uni-icons" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 240 240",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          staticClass: "fill",
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M83.5185 24.6634C123.605 4.51069 172.453 20.7205 192.606 60.8069C204.654 84.6835 205.092 111.408 192.825 133.751C182.968 151.932 145.072 199.247 128.205 220.057C123.824 225.314 115.938 225.314 111.776 220.057C94.9091 199.247 56.5751 151.932 47.375 133.751C27.8794 95.417 43.4321 44.8161 83.5185 24.6634ZM120.25 137.333C141.789 137.333 159.25 119.872 159.25 98.3333C159.25 76.7942 141.789 59.3333 120.25 59.3333C98.7109 59.3333 81.25 76.7942 81.25 98.3333C81.25 119.872 98.7109 137.333 120.25 137.333Z",
                            fill: "black"
                          }
                        })
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass: "upgrades__button",
                  class: {
                    "icon--active": _vm.selectorView == 1,
                    "upgrades__button--active": _vm.upgradesbuttonactive == 1
                  },
                  on: {
                    click: function($event) {
                      return _vm.switchView(1)
                    }
                  }
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("build")])]
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "sound",
                    rawName: "v-sound",
                    value: "selezione_icona",
                    expression: "'selezione_icona'"
                  }
                ],
                staticClass: "stats__button",
                on: {
                  click: function($event) {
                    return _vm.toggleStats()
                  }
                }
              },
              [
                _c("i", { staticClass: "uni-icons" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        viewBox: "0 0 240 240",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        staticClass: "fill",
                        attrs: {
                          d:
                            "M24 160C24 133.49 45.4903 112 72 112V112V168C72 194.51 50.5097 216 24 216V216V160Z",
                          fill: "black"
                        }
                      }),
                      _c("path", {
                        staticClass: "fill",
                        attrs: {
                          d:
                            "M96 72C96 45.4903 117.49 24 144 24V24V168C144 194.51 122.51 216 96 216V216V72Z",
                          fill: "black"
                        }
                      }),
                      _c("path", {
                        staticClass: "fill",
                        attrs: {
                          d:
                            "M168 128C168 101.49 189.49 80 216 80V80V168C216 194.51 194.51 216 168 216V216V128Z",
                          fill: "black"
                        }
                      })
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "upgrades",
        class: { "upgrades--active": _vm.showUpgrades && _vm.sceneType == 1 }
      },
      [
        _c("div", { staticClass: "upgrades__wrapper" }, [
          _vm.showUpgrades
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass: "upgrades__close",
                  on: {
                    click: function($event) {
                      return _vm.closeUpgradeDialog()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("keyboard_arrow_down")
                  ])
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "upgrades__user" }, [
            _c("div", {
              staticClass: "user__pic",
              style: {
                "background-image":
                  "url(" +
                  require("@/assets/img/new_alpha/characters/architetto.png") +
                  ")"
              }
            }),
            _c("div", { staticClass: "user__name" }, [_vm._v(" Architetto ")]),
            _vm.blockToUpgradeInfo
              ? _c("div", { staticClass: "upgrades__tip" }, [
                  _vm.blockToUpgradeInfo.class == 1
                    ? _c("div", [
                        _c("div", { staticClass: "tip__message" }, [
                          _vm._v('"Bonifica la zona"')
                        ])
                      ])
                    : _c("div", [
                        _vm.blockToUpgradeUpgradeDefinitions.length >= 2
                          ? _c("div", { staticClass: "tip__message" }, [
                              _vm._v('"Scegli uno dei miglioramenti"')
                            ])
                          : _c("div", { staticClass: "tip__message" }, [
                              _vm._v('"Migliora il blocco"')
                            ])
                      ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "upgrades__choice" },
            _vm._l(_vm.blockToUpgradeUpgradeDefinitions, function(item) {
              return _c(
                "div",
                {
                  key: item.type,
                  staticClass: "upgrade",
                  class: {
                    "upgrade--2": item.totalSize == 2,
                    "upgrade--4": item.totalSize == 4
                  }
                },
                [
                  _c("img", {
                    staticClass: "upgrade_image",
                    class: { "upgrade_image--active": item.purchasable },
                    attrs: { src: item.imageUrl }
                  }),
                  _c("div", { staticClass: "upgrade__title" }, [
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ]),
                  _vm.blockToUpgradeInfo
                    ? _c("AsyncButtonComponent", {
                        attrs: {
                          classes: {
                            upgrade__button: true,
                            "upgrade__button--active": item.purchasable,
                            "upgrade__button--macerie":
                              _vm.blockToUpgradeInfo.class == 1
                          },
                          isSpinning: _vm.isUpgradingBlockApi,
                          text:
                            (_vm.blockToUpgradeInfo.class == 1
                              ? "Guadagna "
                              : "Costa ") +
                            item.price +
                            " Cr."
                        },
                        on: {
                          click: function($event) {
                            return _vm.upgradeCurrentBlock(item.type)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "stats",
        class: { "stats--active": _vm.showStats && _vm.sceneType == 1 }
      },
      [
        _c("div", { staticClass: "stats__wrapper" }, [
          _vm.showStats
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass: "stats__close",
                  on: {
                    click: function($event) {
                      return _vm.toggleStats()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("keyboard_arrow_down")
                  ])
                ]
              )
            : _vm._e(),
          _vm.statsArea
            ? _c("div", { staticClass: "stats__area" }, [
                _c("div", { staticClass: "area__name" }, [
                  _vm._v(" " + _vm._s(_vm.statsArea.scene) + " ")
                ])
              ])
            : _vm._e(),
          _vm.gameStatus
            ? _c("div", { staticClass: "stats__upgrade" }, [
                _c("div", { staticClass: "stats__bar" }, [
                  _c("div", {
                    staticClass: "bar__progress",
                    style: {
                      width: _vm.gameStatus.currentDistrictPercentage + "%"
                    }
                  })
                ]),
                _c("div", { staticClass: "stats__percentage" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.gameStatus.currentDistrictPercentage) +
                      "% "
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "stats__quests" },
            [
              _vm._l(_vm.stats, function(item, i) {
                return _c(
                  "div",
                  { key: "item" + i, staticClass: "stats__quest" },
                  [
                    _c(
                      "div",
                      { staticClass: "quest__stars" },
                      _vm._l(item.stars, function(star, j) {
                        return _c(
                          "div",
                          {
                            key: "star-" + i + "-" + j,
                            staticClass: "star",
                            class: { "star--active": star == 1 }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("grade")
                            ])
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "quest__location" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]
                )
              }),
              _vm.bestNetData != null
                ? _c(
                    "div",
                    { key: "item-final-status", staticClass: "stats__quest" },
                    [
                      _c(
                        "div",
                        { staticClass: "quest__stars" },
                        _vm._l(_vm.bestNetData.stars, function(star, j) {
                          return _c(
                            "div",
                            {
                              key: "star-final-" + j,
                              staticClass: "star",
                              class: { "star--active": star == 1 }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("grade")
                              ])
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "quest__location" }, [
                        _vm._v(_vm._s(_vm.bestNetData.name))
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]
    ),
    _vm.sceneType == 0
      ? _c(
          "div",
          {
            staticClass: "stats",
            class: {
              "stats--active": _vm.showSelectedDistrict && _vm.sceneType == 0
            }
          },
          [
            _c("div", { staticClass: "stats__wrapper" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass: "stats__close",
                  on: {
                    click: function($event) {
                      return _vm.deselectDistrict()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("keyboard_arrow_down")
                  ])
                ]
              ),
              _vm.statsArea
                ? _c("div", { staticClass: "stats__area" }, [
                    _vm.selectedDistrict
                      ? _c("div", { staticClass: "area__name" }, [
                          _vm._v(
                            " Quartiere " +
                              _vm._s(_vm.selectedDistrict.name.toLowerCase()) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.gameStatus && _vm.selectedDistrict
                ? _c("div", { staticClass: "stats__upgrade" }, [
                    _c("div", { staticClass: "stats__bar" }, [
                      _c("div", {
                        staticClass: "bar__progress",
                        style: {
                          width: _vm.selectedDistrict.completionPercentage + "%"
                        }
                      })
                    ]),
                    _c("div", { staticClass: "stats__percentage" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedDistrict.completionPercentage) +
                          "% "
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "selezione_icona",
                      expression: "'selezione_icona'"
                    }
                  ],
                  staticClass:
                    "loader_play__button loader_play__button--active",
                  on: {
                    click: function($event) {
                      return _vm.gotoDistrict()
                    }
                  }
                },
                [_vm._v(" Vai al quartiere ")]
              )
            ])
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "chat",
        class: { "chat--active": _vm.showChat && _vm.sceneType == 1 }
      },
      [
        _c(
          "div",
          {
            staticClass: "chat__wrapper",
            class: {
              "chat__wrapper--active": _vm.showChat && _vm.sceneType == 1
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "sound",
                    rawName: "v-sound",
                    value: "selezione_icona",
                    expression: "'selezione_icona'"
                  }
                ],
                staticClass: "upgrades__close",
                on: {
                  click: function($event) {
                    return _vm.toggleChat(false)
                  }
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("keyboard_arrow_down")
                ])
              ]
            ),
            _vm.currentQuest
              ? _c("div", { staticClass: "chat__user" }, [
                  _c("div", {
                    staticClass: "user__pic",
                    style: {
                      "background-image":
                        "url(" + _vm.currentQuestCharacterKey + ")"
                    }
                  }),
                  _c("div", { staticClass: "user__name" }, [
                    _vm._v(" " + _vm._s(_vm.currentQuest.characterName) + " ")
                  ]),
                  _c("div", { staticClass: "quest__block_name" }, [
                    _vm._v(" " + _vm._s(_vm.questBlockName) + " ")
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "chat__items" },
              _vm._l(_vm.chatContent.slice(0, _vm.chatIndex + 1), function(
                item,
                i
              ) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "chat__item",
                    class: {
                      "chat__item--user":
                        _vm.chatContent.length > 0 && item.type == 1
                    }
                  },
                  [
                    item.type == 0
                      ? _c("div", {
                          staticClass: "item__pic",
                          style: {
                            "background-image":
                              "url(" + _vm.currentQuestCharacterKey + ")"
                          }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "item__content" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(item.content) }
                      })
                    ]),
                    item.type == 1
                      ? _c("div", {
                          staticClass: "item__pic",
                          style: _vm.avatar
                        })
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _vm.currentQuest
              ? _c("div", { staticClass: "chat__answers" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "risposta_positiva",
                          expression: "'risposta_positiva'"
                        }
                      ],
                      staticClass: "chat__answer chat__answer--positive",
                      class: {
                        "chat__answer--active": _vm.isEndOfConversation
                      },
                      on: {
                        click: function($event) {
                          return _vm.startQuest()
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.currentQuest.acceptLabel))])]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "risposta_negativa",
                          expression: "'risposta_negativa'"
                        }
                      ],
                      staticClass: "chat__answer chat__answer--negative",
                      class: {
                        "chat__answer--active": _vm.isEndOfConversation
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleChat(false)
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.currentQuest.denyLabel))])]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "sound",
                          rawName: "v-sound",
                          value: "avanzamento_chat",
                          expression: "'avanzamento_chat'"
                        }
                      ],
                      staticClass: "chat__skip",
                      class: {
                        "chat__skip--active": _vm.isEndOfConversation == false
                      },
                      on: {
                        click: function($event) {
                          return _vm.goToNextChatItem()
                        }
                      }
                    },
                    [_c("span", [_vm._v("Continua")])]
                  )
                ])
              : _vm._e()
          ]
        )
      ]
    ),
    _vm.showFakeQuest
      ? _c("div", { staticClass: "fake_quest" }, [
          _c(
            "div",
            {
              staticClass: "quest__button",
              on: {
                click: function($event) {
                  return _vm.giveFakeResultFromQuest()
                }
              }
            },
            [_vm._v(" Complete quest ")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game__game-container" }, [
      _c("div", { attrs: { id: "game_container" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }