var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notificator",
      class: { "notificator--active": _vm.visible },
      on: {
        click: function($event) {
          return _vm.onNotificationClicked()
        }
      }
    },
    [
      _c("div", { staticClass: "notificator__title" }, [
        _vm._v("Centro Notifiche ")
      ]),
      _c("div", { staticClass: "notificator__wrapper" }, [
        _c("div", { staticClass: "notificator__dot" }),
        _vm._v(_vm._s(_vm.message))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }