



























import { Component, Prop } from 'vue-property-decorator';

import QuestCoreApi from '@/services/QuestCoreApi'
import BaseComponent from './BaseComponent';

@Component
export default class QuestComponent extends BaseComponent {
  @Prop() readonly questUrl: string;

  created() {
    window["apiRefClass"] = QuestCoreApi;
  }

  mounted() {
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
    this.$store.dispatch('onSetHeaderNotificationButtonVisibility', false);
    this.$store.dispatch('onSetHeaderStatsVisibility', false);
  }

  onFrameLoad() {
    // empty
  }
}
