




























import {
  Component,
} from 'vue-property-decorator';
import { saveAs } from 'file-saver';
import Flickity from 'flickity';

import BaseComponent from './BaseComponent';
import ProfileStatsComponent from './Common/ProfileStats.vue';
import BadgeStepComponent from './LobbySteps/BadgeStep.vue';
// import PrizeStepComponent from './LobbySteps/PrizeStep.vue';
import PrivacyStepComponent from './LobbySteps/PrivacyStep.vue';
import HelpdeskStepComponent from './LobbySteps/HelpdeskStep.vue';
import AccountStepComponent from './LobbySteps/AccountStep.vue';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { Badge } from '@/assets/js/profile/models/Badge';

import FaqComponent from '@/components/Faq.vue';
import RegolamentoComponent from '@/components/Regolamento.vue';
import { GlobalEventBus } from '@/services/GlobalEventBus';
import { PlayerStatistics } from '@/assets/js/RestApiPayloads';

interface TabSelection {
  key: string,
  name: string,
  isSelected: boolean,
}

@Component({
  components: {
    ProfileStatsComponent,
    BadgeStepComponent,
    // PrizeStepComponent,
    PrivacyStepComponent,
    HelpdeskStepComponent,
    AccountStepComponent,
    FaqComponent,
    RegolamentoComponent
  }
})
export default class LobbyComponent extends BaseComponent {
  name = 'Lobby';
  sections: TabSelection[] = [
    { key: "privacy", name: "Privacy", isSelected: false },
    { key: "helpdesk", name: "Assistenza", isSelected: false },
    { key: "stats", name: "Stats", isSelected: false },
    { key: "badge", name: "Badge", isSelected: false },
    // { key: "prize", name: "Premi", isSelected: false },
    { key: "account", name: "Account", isSelected: false },
  ];
  // @ts-ignore
  profile: UserProfile = null;
  badges: Badge[] = [];
  flickity: Flickity;
  headingExtraClass = '';
  isDragging = false;

  get currentSection(): string {
    const selected = this.sections.find(x => x.isSelected);
    
    return selected != null ? this.sections.find(x => x.isSelected)!.key : this.sections[0].key;
  }

  async created() {
    this.onSectionSelected(this.sections[1], null);
    this.profile = await this.wrapApiCall(() => this.dataProvider.getProfile(0), "lettura profilo utente") as UserProfile;

    if (this.profile) {
      this.updateStoreWithFreshDataAboutPlayer(this.profile.statistics);
    }

    GlobalEventBus.$on('take-photo', this.takePhoto);
  }

  updateStoreWithFreshDataAboutPlayer(stats: PlayerStatistics) {
    this.$store.dispatch('onSetCredits', stats.credits);
    this.$store.dispatch('onSetExperience', stats.experience);
    this.$store.dispatch('onSetStars', stats.stars);
  }

  beforeDestroy() {
    GlobalEventBus.$off('take-photo');
  }

  async mounted() {
    const badges = await this.wrapApiCall(() => this.dataProvider.getBadges(), "lettura badges") as Badge[];
    if (badges != null) this.badges = badges;

    setTimeout(() => {
      this.flickity = new Flickity((this.$refs.navigation as Element), {
        cellAlign: 'center',
        pageDots: false,
      });
      this.flickity.on('select', (index: any) => {
        this.onSectionSelected(this.sections[index], null);
      });
      this.flickity.select(2)
    }, 400);
  }

  get avatar(): string {
    let image = (this.profile != null && this.profile.imageFace != null) ?
      this.profile.imageFace :
      require('@/assets/img/new_alpha/characters/user.png');
    return image;
  }

  onShowAvatar() {
    this.$emit('show-avatar');
  }

  scrollStart() {
    this.isDragging = true;
  }

  scrollEnd() {
    this.isDragging = false;
  }

  onSectionSelected(section: TabSelection, event: any) {
    const selected = this.sections.find(x => x.isSelected);
    if (selected != null) selected.isSelected = false;
    if (event && this.flickity) {
      // console.log(event);      
      this.flickity.select(parseInt(event.target.dataset.index, 10))
    }

    this.sections.find(x => x.key === section.key)!.isSelected = true;

    if (section.key === 'stats') {
      this.headingExtraClass = 'no-rounded-corners';
    } else {
      this.headingExtraClass = '';
    }
  }

  takePhoto() {
    
    const canvas = document.getElementById('lobbyCanvas');
    const ctx = (canvas as HTMLCanvasElement).getContext('2d');
    ctx!.clearRect(0, 0, 1024, 1024);
    
    const bg = require('@/assets/img/new_alpha/misc/cornice.jpg');
    const prof = this.profile
    const avat = this.avatar

    const img = new Image();
    img.onload = function() {
      ctx!.drawImage(img, 0, 0, 1024, 1024);

      const img2 = new Image();
      img2.onload = function() {
        ctx!.drawImage(img2,  192, 142, 640, 640);

        ctx!.font = "80px Unicredit";
        ctx!.textAlign = "center"; 
        ctx!.fillText(prof.username, 512, 1024 - 100);

        const canv = canvas as HTMLCanvasElement;
        // @ts-ignore
        if (canv.msToBlob) {
          //IE11 & Edge
          // @ts-ignore
          const blob = canv.msToBlob();
          window.navigator.msSaveBlob(blob, 'avatar_photo_ie.png');
        }
        else {
          // OTHERS
          const dataUrl = (canvas as HTMLCanvasElement).toDataURL();
          // console.log(dataUrl);

          saveAs(dataUrl, 'avatar_photo.png');
        }
      }
      img2.src = avat;
    }
    img.src = bg;
  }

  async logout() {
    GlobalEventBus.$emit('notificator', { polling: false });
    await this.dataProvider.logout();
    location.replace(process.env.VUE_APP_LOGIN_PLATFORM_URL || "https://uclearningplatform.isapiens.net/login/");
  }
}
