export enum JsApiErrorStatusCode {
  MissingParameters = 1001,
  TokenAlreadyUsed = 1010,
  ForbiddenValue = 1020,
  BackendFalsyResult = 1030,
  QuestNotFound = 1040,
  CampaignNotFound = 2040,
  OperationNotAllowed = 401,
  UnexpectedError = 500,
}

export class JsApiError {
  status: JsApiErrorStatusCode;
  operation: string;
  message: string;
  stack: Error | null;

  constructor(status: number, operation: string, message: string, stack?: Error) {
    this.status = status;
    this.operation = operation;
    this.message = message;
    this.stack = stack ? stack : null;
  }
}
