var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "hint",
            class: { "hint--active": _vm.visible },
            on: {
              click: function($event) {
                return _vm.dispose()
              }
            }
          },
          [
            _c("div", {
              staticClass: "hint__wrapper",
              domProps: { innerHTML: _vm._s(_vm.currentHint) }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }