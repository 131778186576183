





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { GlobalEventBus } from '@/services/GlobalEventBus';

import QuattroComponent from "@/components/Quattro.vue";
import AlertComponent from "@/components/Alert.vue";
import HintComponent from "@/components/Hint.vue";
import NoticeComponent from "@/components/Notice.vue";
import ProfileComponent from "@/components/Profile.vue";
import OfflineComponent from "@/components/Offline.vue";

@Component({
  components: {
    QuattroComponent,
    AlertComponent,
    ProfileComponent,
    OfflineComponent,
    HintComponent,
    NoticeComponent
  },
})
export default class App extends Vue {

  _keyListener : any;
  _wheelListener: any;

  created() {
    this.$store.dispatch('onWindowResize', window.innerWidth < 960);
    this.$store.dispatch('onNetworkStatusChange', !navigator.onLine);

    window.addEventListener('resize', () => {
      this.$store.dispatch('onWindowResize', window.innerWidth < 960);
    });
    window.addEventListener('online', () => {
      this.$store.dispatch('onNetworkStatusChange', false);
    GlobalEventBus.$emit('network-status-change', { isOnline: true });
    });
    window.addEventListener('offline', () => {
      this.$store.dispatch('onNetworkStatusChange', true);
    GlobalEventBus.$emit('network-status-change', { isOnline: false });
    });
  }

  mounted() {
    this._keyListener = function(e: any) {
        if (e.key === "+" && (e.ctrlKey || e.metaKey)) {
          this.preventZoom(e);
        }
        if (e.key === "-" && (e.ctrlKey || e.metaKey)) {
          this.preventZoom(e);
        }
    };

    this._wheelListener = function(e: any) {
      if ( e.ctrlKey || e.metaKey ) {
        this.preventZoom(e);
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
    document.addEventListener('wheel', this._wheelListener.bind(this),  { passive: false });
    // document.querySelector('.app')!.addEventListener('wheel', ev  => {
    //   const e = ev as WheelEvent;
    //   if (e.ctrlKey || e.metaKey) {
    //     this.preventZoom(e);
    //   }
    // }, true)
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
    document.removeEventListener('wheel', this._wheelListener);
  }

  preventZoom (e: any) {
    e.preventDefault();
  }
}

Vue.directive('sound', {
  bind(el, sound) {
    el.addEventListener('click', function() {
      // console.log('SOUND ', sound.value);
      GlobalEventBus.$emit('play-sound', sound.value);
    });
  }
})

