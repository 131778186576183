



















import { Component } from "vue-property-decorator";

import { GlobalEventBus, AlertEventArgs } from "@/services/GlobalEventBus";
import BaseComponent from './BaseComponent';

interface IAlertMessage {
  text: string;
  error: any;
  index: number;
  isDisposable: boolean;
}

@Component
export default class AlertComponent extends BaseComponent {
  queue: IAlertMessage[] = [];
  isDetailOpen = false;

  get currentAlert(): IAlertMessage | null {
    if (this.queue != null && this.queue.length > 0)
      return this.queue[this.queue.length - 1];

    return null;
  }

  get visible(): boolean {
    return this.queue != null && this.queue.length > 0;
  }

  get triggerDetailVisibilityButtonLabel(): string {
    return !this.isDetailOpen ? "keyboard_arrow_down" : "keyboard_arrow_up";
  }

  created() {
    GlobalEventBus.$on('alert', async (args: AlertEventArgs) => {
      // console.log("Alert event:", args);
      if (args.isUniqueInQueue !== true || this.queue.find(x => x.text === args.text) == null) {
        this.queue.push({
          text: args.text,
          error: args.error != null && args.error.error != null ? args.error.error.message : args.error,
          index: this.queue.length + 1,
          isDisposable: args.isDisposable != null ? args.isDisposable : true,
        });
      }

      if (args.disposeTimeoutMs != null && args.disposeTimeoutMs > 0) {
        setTimeout(() => {
          this.dispose();
        }, args.disposeTimeoutMs);
      }
    });
  }

  triggerDetailVisibility() {
    this.isDetailOpen = !this.isDetailOpen;
  }

  onActionButtonClicked() {
    if (this.currentAlert == null) return;

    this.currentAlert.isDisposable ? this.dispose() : location.reload();
  }

  dispose() {
    if (this.currentAlert == null || !this.currentAlert.isDisposable) {
      console.log("Undisposable alert message. Page needs to be reloaded.");
      return;
    }

    try {
      this.queue.pop();
    } catch (e) {
      // console.log(e);
    }
  }
}
