/* eslint-disable */

const dataBlockDefinitions = require('@/assets/js/world/data/dataBlockDefinitions.json');
const dataWorld = require('@/assets/js/world/data/dataWorld.json');
const dataQuests = require('./restQuests.json');
const dataPlayers = require('./restPlayers.json');
const dataProgresses = require('./restUserProgress.json');
const dataCampaigns = require('./restCampaigns.json');
const dataUserProfiles = require('./restUserProfiles.json');
const dataAvatarAssets = require('./restAvatarAssets.json');
const dataUserAvatars = require('./restUserAvatars.json');
const dataQuattroEventMessages = require('./restQuattroEventMessages.json');
const dataBadges = require('./restBadges.json');
const dataPrizes = require('./restPrizes.json');
const dataNotifications = require('./restNotifications.json');
const dataCategories = require('./restCategories.json');
const dataRanking = require('./restRanking.json');
import lodash from 'lodash';

import { JsApiError, JsApiErrorStatusCode } from '../CustomErrors';
import { IRestApiProvider } from '../RestApiProviderFactory';
import BaseRestApiProvider from '../BaseRestApiProvider';
import { Player } from '@/assets/js/world/models/Player';
import { UserBlockProgress } from '@/assets/js/world/models/UserBlockProgress';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { PlayerCreditsPayload, PlayerExpPayload, QuestStarsPayload, QuestStatusPayload,
  QuestSuspendDataPayload, PlayerStatistics, UserAvatarPayload, UsernameRegistrationPayload,
  OnboardingContents, StaticPage, StaticPageContent, EndingContents, CampaignRanking } from '@/assets/js/RestApiPayloads';
import { Campaign } from '@/assets/js/campaign/models/Campaign';
import { UserProfile } from '@/assets/js/profile/models/UserProfile';
import { AvatarAsset } from '@/assets/js/profile/models/Avatar';
import { ConversationMessage } from '@/assets/js/world/models/Conversation';
import { Badge } from '@/assets/js/profile/models/Badge';
import { Prize } from '@/assets/js/profile/models/Prize';
import { BaseNotification, BroadcastNotification, NotificationFactory } from '@/assets/js/profile/models/Notifications';
import { Category, Ranking } from '@/assets/js/campaign/models/Ranking';

export default class MockApiProvider extends BaseRestApiProvider implements IRestApiProvider {
  currentUsername: string;
  currentUserCredits: number;
  currentUserExp: number;
  currentUserStars: number;
  addNewNotification = false;

  constructor(doCryptation: boolean, doSignature: boolean) {
    super();

    this.doCryptation = doCryptation;
    this.doSignature = doSignature;

    setTimeout(() => {
      this.addNewNotification = true;
    }, 45 * 1000);
  }

  /**
   * Va attivato prima il middleware di firma, poi quello di offuscamento.
   * Lato server, la prima operazione è il de-offuscamento, poi il controllo sulla firma.
   * @param username username
   * @param password password
   */
  async login(username?: string, password?: string): Promise<Player> {
    const pem =
`-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAw/zwk6YHTVlAKAeay34o
INUHqkIjmXHjExr4jFqghcCIKd5gCEsDVnady5cWk6YJbzpJwX3wmjbxj5cuGkP/
Y6/8o14kshymb0usNyn2R/sG1y+lowiUtb/ffxSkmlNwCZgk4MNEDM4pEgIVRrpl
/Rv4TbmbPHCsZg1pcbsCTA/3JJfu/JLPJoQwBBdY1LcBxDYlZsqH8Yp/6HRoM+AD
xvuGYLruUbarOir8lr4yHpXVbyQz9lpl8yL5+1gg3BaBpoxluC+SCit7SmvZLJ5L
Jmf35lZM8XE53+fmpEhagEqbiEMQUb1j9vpGRpAUR9xqZPpU9lfJU4IcFSMxYtge
EN0UHBBLMtmZwXYtuC10WJmsHqgBiHy+Y0n1siaVqhVWE2X0SmKDIVkWfWxzUlsJ
bZsvwxWF68hsBLu0gMg8quv8YHFbN+cC/cb/vGkP/00JeNq22zqUzgZ6cvzLcYXt
nx4dFWAtSwbSD0s4KCOVhWq2sKx+3RnP9BVnQvb8YA0Qg5XcXl0Gs5Pge/lN8i1C
FlVKNnLq8EGL5bybQF9IL/hHRRW62UddnfhIdK+ksVnkcSq4c+TXpVewPpZL2OPO
SwSwKrBxM9tCUN6wKx/H0QQ7QrYcV/VHqx8LbsxWaSwZz8aRk9hK1SRp73s+cUk6
c9AxPJwJKUiokLkWTLSVEHMCAwEAAQ==
-----END PUBLIC KEY-----`;
    // console.log("Public key mocked", pem);

    if (pem != null && pem.length > 0) {
      await this.importPublicRsaKey(pem);
      // const exportedKey = "8Xa/jwjE9EhJUypjNezRpkxVO6Cq6QY0i+9rDqmkVSQ=";
      // const test = await this.obfuscateString(JSON.stringify({"id": 1, "value": 60}));
      // console.log(test);

      if (this.doCryptation) {
        this.addObfuscationMiddleware();
      } else {
        console.log("STATO CRYPTATION NON ATTIVO");
      }
    } else {
      console.log("CRYPTATION NON ATTIVABILE, PUBLIC KEY NON RICEVUTA");
    }
    
    const nonce = 0;
    // console.log("Nonce received", nonce);
    if (nonce != null) {
      this.importNonce(nonce);

      if (this.doSignature) {
        this.addSignatureMiddleware();
      } else {
        console.log("STATO SIGNATURE NON ATTIVO");
      }
    } else {
      console.log("SIGNATURE NON ATTIVABILE, NONCE NON RICEVUTO");
    }

    const p = dataPlayers.map((x: any) => new Player(x))[0] as Player;
    this.currentUsername = p.username;
    this.currentUserCredits = p.credits;
    this.currentUserExp = p.experience;
    this.currentUserStars = p.stars;
    return p;
  }

  logout(): void {
    console.log("logout noop");
    return;
  }

  async signUp(displayName: string, username: string, password: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  async getGameStatus(player: Player): Promise<GameStatus> {
    const dataUserProgress = dataProgresses.filter((x: any) => x.username === this.currentUsername);
    return new GameStatus(player, dataWorld, dataQuests, dataBlockDefinitions, dataUserProgress);
  }

  async setPlayerCredits(payload: PlayerCreditsPayload): Promise<PlayerStatistics> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.currentUserCredits += payload.value;
        resolve({
          credits: this.currentUserCredits,
          experience: this.currentUserExp,
          stars: this.currentUserStars,
        });
      }, 300);
    });
  }

  async setPlayerExperience(payload: PlayerExpPayload): Promise<PlayerStatistics> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.currentUserExp += payload.value;
        resolve({
          credits: this.currentUserCredits,
          experience: this.currentUserExp,
          stars: this.currentUserStars,
        });
      }, 300);
    });
  }

  async setQuestStars(payload: QuestStarsPayload): Promise<PlayerStatistics> {
    throw new JsApiError(JsApiErrorStatusCode.OperationNotAllowed, "setQuestStars", "Not allowed");
    return new Promise((resolve) => {
      setTimeout(() => {
        this.currentUserStars += payload.value;   // è sbagliato, dovrei vedere il valore precedente e sommare il delta, ma tanto usiamo le API vere 
        resolve({
          credits: this.currentUserCredits,
          experience: this.currentUserExp,
          stars: this.currentUserStars,
        });
      }, 300);
    });
  }

  async setQuestStatus(payload: QuestStatusPayload): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  async setQuestSuspendData(payload: QuestSuspendDataPayload): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  async createUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(payload);
      }, 300);
    });
  }

  async updateUserBlockProgress(payload: UserBlockProgress): Promise<UserBlockProgress> {
    throw new JsApiError(JsApiErrorStatusCode.UnexpectedError, "updateUserBlockProgress", "Server error");
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(payload);
      }, 300);
    });
  }

  async getCampaigns(): Promise<Campaign[]> {
    return dataCampaigns.map((x: any) => new Campaign(x));
  }

  async getCategories(): Promise<Category[]> {
    return dataCategories.map((x: any) => new Category(x));
  }

  async getRanking(categoryId: number, pageIndex: number, pageSize: number): Promise<Ranking[]> {
    return dataRanking
      .map((x: any) => new Ranking(x))
      .slice(pageIndex * pageSize, ((pageIndex + 1) * pageSize));
  }

  async getProfile(userId: number): Promise<UserProfile> {
    return new UserProfile(dataUserProfiles.filter((x: any) => x.id === 1)[0]);
  }

  async getAvatarAssets(sex: string): Promise<AvatarAsset[]> {
    return dataAvatarAssets.filter((x: any) => x.sex == sex).map((x: any) => new AvatarAsset(x));
  }

  async getUserAvatar(): Promise<number[]> {
    const userAvatar = dataUserAvatars.find((x: any) => x.userId === 1);
    return userAvatar.assets;
  }

  async setUserAvatar(payload: UserAvatarPayload): Promise<UserAvatarPayload> {
    // throw this.composeCustomError(new Error("Impossibile salvare l'avatar, riprova tra qualche secondo"));
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(payload);
      }, 300);
    });
  }

  async getOnboardingContents(): Promise<OnboardingContents> {
    return new Promise((resolve) => {
      resolve({
        "video": {
          "image":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.jpeg",
          "mp4":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.mp4",
          "m3u8":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.m3u8"
        }
      });
    });
  }

  async getEndingContents(): Promise<EndingContents> {
    return new Promise((resolve) => {
      resolve({
        "video": {
          "image":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.jpeg",
          "mp4":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.mp4",
          "m3u8":"https://unicredit-ucity-development.s3.amazonaws.com/onboarding/video.m3u8"
        }
      });
    });
  }

  async isUsernameAvailable(username: string): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  async registerUsername(payload: UsernameRegistrationPayload): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  async setUserSex(sex: string): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  async askForQuattroDetails(eventKey: string, player: Player): Promise<ConversationMessage[]> {
    const relatedQuattroResponse = dataQuattroEventMessages.find((x: any) => x.eventKey === eventKey);
    if (relatedQuattroResponse != null) {
      return relatedQuattroResponse.response.map((x: any) => new ConversationMessage(x));
    }
    return [];
  }

  async getBadges(): Promise<Badge[]> {
    throw this.composeCustomError(new Error("Impossibile recuperare i badge"));
    return dataBadges.map((x: any) => new Badge(x));
  }

  async getPrizes(pageIndex: number, itemsPerPage: number = 20): Promise<Prize[]> {
    return dataPrizes.map((x: any) => new Prize(x));
  }

  async setLastNotificationViewed(notificationId: number): Promise<void> {
    // throw this.composeCustomError(new Error("Impossibile salvare ultima notifica visualizzata"));
    return;
  }

  async getNotificationsAfterId(notificationId: number): Promise<BaseNotification[]> {
    const allNotifications: BaseNotification[] = dataNotifications.map((x: any) => NotificationFactory.create(x));
    
    if (this.addNewNotification) {
      allNotifications.push(new BroadcastNotification({
        id: 20,
        type: 'broadcast',
        text: 'Finta notifica!',
        timestamp: new Date(Date.now()),
      }));
    }

    return allNotifications.filter(x => x.id > notificationId);
  }

  async getNotificationsBackwardPaged(notificationId: number, itemsPerPage: number): Promise<BaseNotification[]> {
    // throw this.composeCustomError(new Error("Impossibile recuperare le notifiche"));
    const notifications: BaseNotification[] = dataNotifications.map((x: any) => NotificationFactory.create(x));
    
    if (this.addNewNotification) {
      notifications.push(new BroadcastNotification({
        id: 20,
        type: 'broadcast',
        text: 'Finta notifica!',
        timestamp: new Date(Date.now()),
      }));
    }

    return lodash.orderBy(notifications.filter(x => x.id <= notificationId), ['id'], ['desc']).slice(0, itemsPerPage);
  }

  async getStaticPage(pageKey: string): Promise<StaticPage> {
    return {
      key: pageKey,
      content: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus. Consequatur quam, unde laboriosam illo natus ducimus mollitia non aliquam iste obcaecati corrupti perspiciatis ut. Blanditiis eaque debitis neque.`
    };
  }

  async getStaticPageContent(pageKey: string): Promise<StaticPageContent[]> {
    return [{
      title: 'FAQ 1',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, autem minus',
    },{
      title: 'FAQ 2',
      content: '<b>Lorem ipsum</b> <i>dolor sit amet consectetur adipisicing elit. Deserunt, autem minus</i>',
    },{
      title: 'FAQ 3',
      content: '<b>Lorem IIIIIII</b> <i>dolor sit amet consectetur adipisicing elit. Deserunt, autem minus</i>',
    }];
  }

  async getUserRankings(): Promise<CampaignRanking[]> {
    try {
      return [{
        id: 1,
        title: "Campagna",
        description: "Tutti contro tutti",
        points: 1512,
        rank: 7,
        image: "/campaigns_images/campaign_1_1608627907548.png"
      }];
    } catch (e) {
      console.error(e);
      throw this.composeCustomError(e);
    }
  }
}
