/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ConversationMessageType {
  MainCharacter = 0,
  Player = 1,
}

export interface IConversationMessage {
  type: ConversationMessageType;
}

export class ConversationMessage implements IConversationMessage {
  readonly type: ConversationMessageType;
  readonly content: string;

  constructor(data: any) {
    this.type = data.type;
    this.content = data.content;
  }
}

export class ConversationMessageResponse extends ConversationMessage {
  readonly affermative: string;
  readonly affermativeRecap: string;
  readonly negative: string;
  readonly negativeRecap: string;

  constructor(data: any) {
    super(data);

    this.affermative = data.affermative;
    this.affermativeRecap = data.affermativeRecap;
    this.negative = data.negative;
    this.negativeRecap = data.negativeRecap;
  }
}

export class ConversationMessageFactory {
  static create(data: IConversationMessage): ConversationMessage {
    return new ConversationMessage(data);
  }
}
