declare const PIXI: any;
const imagesData = require('@/../scripts/imagesData.json');

export default function loadResources(callbacks: any) {	
	PIXI.Loader.shared.reset()

	imagesData.forEach((image: any) => {
		PIXI.Loader.shared.add(image.name, require('@/assets/img/' + image.path) )
	});

  PIXI.Loader.shared
    // .add('menu', require('@/assets/img/new_alpha/maps/menu.png'))
    .add('map_residenziale', require('@/assets/img/new_alpha/maps/map_quartiere_residenziale.png'))
    .add('map_commerciale', require('@/assets/img/new_alpha/maps/map_quartiere_commerciale.png'))
    .add('map_industriale', require('@/assets/img/new_alpha/maps/map_quartiere_industriale.png'))
    .add('map_finanziario', require('@/assets/img/new_alpha/maps/map_quartiere_finanziario.png'))
    .add('ponte-sud_ovest', require('@/assets/img/new_alpha/misc/ponte-sud_ovest.png'))
    .add('ponte-nord_est', require('@/assets/img/new_alpha/misc/ponte-nord_est.png'))
    .add('ponte-2', require('@/assets/img/new_alpha/misc/ponte-2.png'))
    .add('rampa_lancio', require('@/assets/img/new_alpha/misc/rampa_lancio.png'))

    .add('aeroplano', require('@/assets/img/new_alpha/misc/aeroplano.png'))
    .add('mongolfiera', require('@/assets/img/new_alpha/misc/mongolfiera.png'))
    
    .add('isola', require('@/assets/img/new_alpha/menu/isola.png'))
    .add('residenziale_idle', require('@/assets/img/new_alpha/menu/buttons/residenziale_idle.png'))
    .add('residenziale_pressed', require('@/assets/img/new_alpha/menu/buttons/residenziale_pressed.png'))
    .add('finanziario_idle', require('@/assets/img/new_alpha/menu/buttons/finanziario_idle.png'))
    .add('finanziario_pressed', require('@/assets/img/new_alpha/menu/buttons/finanziario_pressed.png'))
    .add('industriale_idle', require('@/assets/img/new_alpha/menu/buttons/industriale_idle.png'))
    .add('industriale_pressed', require('@/assets/img/new_alpha/menu/buttons/industriale_pressed.png'))
    .add('commerciale_idle', require('@/assets/img/new_alpha/menu/buttons/commerciale_idle.png'))
    .add('commerciale_pressed', require('@/assets/img/new_alpha/menu/buttons/commerciale_pressed.png'))
    .add('multiplayer_idle', require('@/assets/img/new_alpha/menu/buttons/multiplayer_idle.png'))
    .add('multiplayer_pressed', require('@/assets/img/new_alpha/menu/buttons/multiplayer_pressed.png'))
    
		.add('upgrade_selector', require('@/assets/img/new_alpha/icons/upgrade_selector.png'))
		.add('quest_selector', require('@/assets/img/new_alpha/icons/quest_selector.png'))
		.add('quest_selector_2', require('@/assets/img/new_alpha/icons/quest_selector_2.png'))
		.add('quest_selector_passed', require('@/assets/img/new_alpha/icons/quest_selector_passed.png'))
		.add('quest_selector_disabled', require('@/assets/img/new_alpha/icons/quest_selector_disabled.png'))
		.add('quest_selector_highlight', require('@/assets/img/new_alpha/icons/quest_selector_highlight.png'))
		.add('upgrade_selector_highlight', require('@/assets/img/new_alpha/icons/upgrade_2_selector_highlight.png'))
		.add('recycle_selector', require('@/assets/img/new_alpha/icons/recycle_selector.png'))
		.add('lock_selector', require('@/assets/img/new_alpha/icons/lock_selector.png'))

		.add('water', require('@/assets/img/new_alpha/misc/water.png'))
		.add('water_2', require('@/assets/img/new_alpha/misc/waterguy12.png'))
		.add('circle', require('@/assets/img/new_alpha/misc/circle.png'))
		.add('square', require('@/assets/img/new_alpha/misc/square.png'))
    .add('particle', require('@/assets/img/new_alpha/misc/particle.png'))
    .add('particle_2', require('@/assets/img/new_alpha/misc/particle_2.png'))

		.add('cloud_0', require('@/assets/img/new_alpha/nuvole/256/nuvola_0.png'))
		.add('cloud_1', require('@/assets/img/new_alpha/nuvole/256/nuvola_1.png'))
		.add('cloud_2', require('@/assets/img/new_alpha/nuvole/256/nuvola_2.png'))
		.add('cloud_3', require('@/assets/img/new_alpha/nuvole/256/nuvola_3.png'))
		.add('cloud_shadow', require('@/assets/img/new_alpha/nuvole/ombra_nuvola.png'))

	PIXI.Loader.shared.load((loader: any, resources: any) => {
      for (const cb of callbacks) {
        cb(resources);
      }
		})
}
