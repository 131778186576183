var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lobby__body lobby__body--ff" },
    [
      _vm._m(0),
      _vm._l(_vm.badges, function(badge) {
        return _c(
          "div",
          {
            key: badge.id,
            staticClass: "lobby__badge",
            class: { "lobby__badge--notowned": !badge.isOwned }
          },
          [
            _c("div", {
              staticClass: "lobby__badge--image",
              style: { "background-image": "url(" + badge.url + ")" }
            }),
            _c("div", { staticClass: "lobby__badge--text" }, [
              _c("div", { staticClass: "lobby__badge--title" }, [
                _vm._v(_vm._s(badge.title))
              ]),
              badge.isOwned
                ? _c("div", { staticClass: "lobby__badge--description" }, [
                    _vm._v(" " + _vm._s(badge.description))
                  ])
                : _vm._e()
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lobby__badge lobby__badge--hint" }, [
      _c("div", { staticClass: "lobby__badge--text" }, [
        _c("div", { staticClass: "lobby__badge--title" }, [
          _vm._v("In questa lista puoi trovare tutti i tuoi badges")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }