var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidein" } }, [
    _vm.visible
      ? _c("div", { staticClass: "quattro" }, [
          _c("div", { staticClass: "quattro__wrapper" }, [
            _c("div", { staticClass: "quattro__heading" }, [
              _c("div", { staticClass: "chat__user" }, [
                _c("div", {
                  staticClass: "user__pic",
                  style: {
                    "background-image":
                      "url(" +
                      require("@/assets/img/new_alpha/characters/quattro.png") +
                      ")"
                  }
                }),
                _c("div", { staticClass: "user__name" }, [_vm._v(" Quattro ")]),
                _c("div", { staticClass: "user__title" }, [_vm._v(" Best4 ")])
              ])
            ])
          ]),
          _c("div", { staticClass: "chat__wrapper" }, [
            _c(
              "div",
              { staticClass: "chat__items" },
              _vm._l(
                _vm.currentConversation.slice(0, _vm.chatIndex + 1),
                function(item, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "chat__item",
                      class: {
                        "chat__item--user":
                          _vm.currentConversation.length > 0 && item.type === 1
                      }
                    },
                    [
                      item.type === 0
                        ? _c("div", {
                            staticClass: "item__pic",
                            style: {
                              "background-image":
                                "url(" +
                                require("@/assets/img/new_alpha/characters/quattro.png") +
                                ")"
                            }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "item__content" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.content) }
                        })
                      ]),
                      item.type === 1
                        ? _c("div", {
                            staticClass: "item__pic",
                            style: _vm.avatar
                          })
                        : _vm._e()
                    ]
                  )
                }
              ),
              0
            ),
            _c("div", { staticClass: "chat__answers" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "avanzamento_chat",
                      expression: "'avanzamento_chat'"
                    }
                  ],
                  staticClass: "chat__answer chat__answer--positive",
                  class: { "chat__answer--active": _vm.isEndOfConversation },
                  on: {
                    click: function($event) {
                      return _vm.closeCurrentConversation()
                    }
                  }
                },
                [_c("span", [_vm._v("Avanti")])]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sound",
                      rawName: "v-sound",
                      value: "avanzamento_chat",
                      expression: "'avanzamento_chat'"
                    }
                  ],
                  staticClass: "chat__skip",
                  class: { "chat__skip--active": !_vm.isEndOfConversation },
                  on: {
                    click: function($event) {
                      return _vm.goToNextChatItem()
                    }
                  }
                },
                [_c("span", [_vm._v("Continua")])]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }