export interface CampaignObject {
  user: {
    id: number;
    displayName: string;
    categoryId: number;
    categoryName: string;
    experience: number;
    imageFace: string;
  };
  campaign: {
    name: string;
    description: string;
    image: string;
    color: string;
    start: string;
    end: string;
    enrolled: boolean;
  };
}  

export class Campaign {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly url: string;
  readonly image: string;
  readonly color: string;
  readonly start: string;
  readonly end?: string;
  enrolled: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.url = data.url;
    this.image = data.image;
    this.color = data.color;
    this.start = data.start;
    this.end = data.end;
    this.enrolled = data.enrolled;
  }  
}
