var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lobby__body lobby__body--assistenza" }, [
      _c("div", { staticClass: "lobby__full privacy" }, [
        _c("div", [
          _c("div", [
            _vm._v(" L’accesso utente per partecipare al game U-City ("),
            _c(
              "a",
              { attrs: { href: "https://ucitygame.coreconsulting.it" } },
              [_vm._v("https://ucitygame.coreconsulting.it")]
            ),
            _vm._v(") può avvenire solo tramite l'indirizzo "),
            _c(
              "a",
              {
                attrs: { href: "https://uclearningplatform.isapiens.net/login" }
              },
              [_vm._v("https://uclearningplatform.isapiens.net/login")]
            ),
            _vm._v(" e ti è consentito solo dopo registrazione all'indirizzo "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://uclearningplatform.isapiens.net/register"
                }
              },
              [_vm._v("https://uclearningplatform.isapiens.net/register")]
            ),
            _vm._v(
              ". U-City non tratta e non registra i tuoi dati personali. Elemento di riconoscimento all'interno di U-City sarà il tuo nickname. Abbi cura di sceglierlo in modo da tutelare la tua privacy, scegli quindi un nickname che non sia riconducibile in modo esplicito e diretto alla tua persona. "
            )
          ]),
          _c("div", [
            _vm._v(
              " U-City utilizza un sistema di tracciamento delle attività utente all'interno del game per l'attribuzione di crediti e punti esperienza sulla base delle attività svolte. "
            )
          ]),
          _c("div", [
            _vm._v(
              " L'identificativo dell'utente nel game non è riconducibile alla persona fisica sulla base delle informazioni disponibili nel game. "
            )
          ]),
          _c("div", [
            _vm._v(
              " Coreconsulting S.p.a, gestore del servizio U-City fornirà periodicamente a iSapiens S.r.l, titolare del trattamento dei dati personali, report in forma pseudonima sull'andamento del gioco e sulle relative classifiche. Sarà iSapiens a ricollegare gli identificativi utente e i nickname alle persone fisiche registrate e fornire ad UniCredit i dati in forma correlata ed esplicita. "
            )
          ]),
          _c("div", [
            _vm._v(
              " La privacy policy di Best Academy è disponibile a questo "
            ),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://uclearningplatform-cdn.isapiens.net/assets/privacy/privacyPolicy.pdf"
                }
              },
              [_vm._v("link")]
            ),
            _vm._v(". ")
          ]),
          _c("div", [
            _vm._v(
              " Per consentire la navigazione e la corretta gestione delle sessioni utente U-City fa uso di cookies tecnici. "
            )
          ]),
          _c("div", [
            _vm._v(
              " I cookies tecnici sono file di piccole dimensioni utilizzati durante la navigazione e memorizzati dall’applicazione nella memoria del browser allo scopo di dare continuità alle attività dell'utente durante la navigazione e per tutta la durata della sua sessione. "
            )
          ]),
          _c("div", [
            _vm._v(
              " I cookies tecnici di sessione vengono cancellati all'uscita dal browser o allo spegnimento del computer e sono indispensabili per il funzionamento del game. "
            )
          ]),
          _c("div", [
            _vm._v(
              " La loro disattivazione, sempre possibile con modalità diverse a seconda del browser utilizzato, rende impossibile l'accesso e la navigazione nel game. "
            )
          ]),
          _c("div", { staticClass: "firma" }, [_vm._v("@Coreconsulting")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }