import 'whatwg-fetch';
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'promise-polyfill/src/polyfill';
import 'webcrypto-shim';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'flickity/dist/flickity.min.css';
import 'video.js/dist/video-js.min.css';
import './extensions';

Vue.config.productionTip = false;

import VueFriendlyIframe from 'vue-friendly-iframe';
Vue.use(VueFriendlyIframe);

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { 
  slots: {
    noResults: '',
    noMore: '',
  }
});

import VueDragscroll from 'vue-dragscroll';
Vue.use(VueDragscroll);

import sanitizeHTML from 'sanitize-html';
Vue.prototype.$sanitize = sanitizeHTML;

import VTooltip from 'v-tooltip';
Vue.use(VTooltip)

if (typeof window['TextEncoder'] !== 'function') {
  const TextEncodingPolyfill = require('text-encoding');
  window['TextEncoder'] = TextEncodingPolyfill.TextEncoder;
  window['TextDecoder'] = TextEncodingPolyfill.TextDecoder;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
