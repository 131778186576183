
































































































// eslint-disable @typescript-eslint/ban-ts-ignore

import { Component } from 'vue-property-decorator';

import BaseVue from './BaseVue';
import GameComponent from '@/components/Game.vue';
import MultiplayerComponent from '@/components/Multiplayer.vue';
import HeaderComponent from '@/components/Header.vue';
import LoginErrorComponent from '@/components/LoginError.vue';
import QuestComponent from '@/components/Quest.vue';
import LobbyComponent from '@/components/Lobby.vue';
import AvatarComponent from '@/components/Avatar.vue';
import NotificatorComponent from '@/components/Notificator.vue';
import NotificationsComponent from '@/components/Notifications.vue';
import { GameStatus } from '@/assets/js/world/models/GameStatus';
import { QuestStarsOutcomes, QuestStatus, BlockType } from '@/assets/js/world/models/Enums';
import { UserBlockProgress } from '@/assets/js/world/models/UserBlockProgress';
import { Block } from '@/assets/js/world/models/World';
import { FrameQuestPayload } from '@/assets/js/world/models/Quest';
import { PlayerCreditsPayload, PlayerExpPayload, PlayerPayload, QuestStarsPayload, QuestStatusPayload,
  QuestSuspendDataPayload } from '@/assets/js/RestApiPayloads';
import { AvatarAsset } from '@/assets/js/profile/models/Avatar';
import { GlobalEventBus } from '@/services/GlobalEventBus';


export interface LoadingInfo {
  text: string;
  scene: string;
  image: string;
}

@Component({
  components: {
    GameComponent,
    QuestComponent,
    MultiplayerComponent,
    LobbyComponent,
    AvatarComponent,
    HeaderComponent,
    NotificatorComponent,
    NotificationsComponent,
    LoginErrorComponent,
  }
})
export default class Home extends BaseVue {
  showLoader = false;
  showLoginErrorComponent = false;

  showLoaderPlay = true;
  gameLoaded = false;

  // @ts-ignore
  loaderInfo: LoadingInfo = null;

  showMultiplayer = false;
  showCampaign = false;
  districtStarted = false;
  isMap = false;

  questUrl = "";
  showQuest = false;
  showLobbyStats = false;

  allAvatarAssets: AvatarAsset[] = [];
  avatarAssets: number[];
  showAvatar = false;

  // @ts-ignore
  gameStatus: GameStatus = null;
  isUpgradingBlockApi = false;

  get gameClass(): string {
    return this.showQuest || this.showMultiplayer ? "hidden" : "";
  }

  get avatar(): string {
    return this.$store.getters.avatarBackgroundImageStyle;
  }

  startGame() {
    // console.log("start");
    this.showLoaderPlay = false;

    // TODO fare il check se suono deve essere mutato, e mutare suono eventualmente
    // GlobalEventBus.$emit('play-sound', 'feedback_positivo');
    GlobalEventBus.$emit('notificator', { polling: true });
  }

  onHeaderCloseClicked() {
    const mvdcsf = (document.getElementById('game') as any)?.__vue__ || null;
    if (mvdcsf == null) return;

    setTimeout(() => {

      if (this.showLobbyStats) {
        this.showLobbyStats = false;
        this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
        this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
        this.$store.dispatch('onSetHeaderStatsVisibility', true);
      }
      if (this.showMultiplayer) {
        this.showMultiplayer = false;
        // console.log("IVIA");
        this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
        this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
        this.$store.dispatch('onSetHeaderStatsVisibility', true);
      }

    }, 300);

    mvdcsf.goToMenu();
  }

  onShowDistrict() {
    // console.log("SHOW DIST");

    this.districtStarted = true;
  }

  onIsMap() {
    this.isMap = true;
  }

  onHeaderStatsClicked() {
    // console.log("header stats clicked");
    if (!this.showLobbyStats || !this.showAvatar) {

      setTimeout(() => {
        this.onShowLobbyStats();
      }, 300);

      GlobalEventBus.$emit('stop-sound', 'music');

      this.executeLoadingAnimation({
        text: 'Stai andando alla',
        scene: "lobby",
        image: "menu",
      });
    }
  }

  onHeaderNotificationsClicked() {
    GlobalEventBus.$emit('notifications');
  }

  created() {
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
    this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
    this.$store.dispatch('onSetHeaderStatsVisibility', true);

    GlobalEventBus.$on('header-close-button-clicked', () => {
      this.onHeaderCloseClicked();
    });
    GlobalEventBus.$on('header-notifications-button-clicked', () => {
      this.onHeaderNotificationsClicked();
    });
    GlobalEventBus.$on('header-stats-clicked', () => {
      this.onHeaderStatsClicked();
    });
    GlobalEventBus.$on('start-loader', (info: any) => {
      this.executeLoadingAnimation(info)      
    });
  }

  isLocalhost() {
    return location.hostname.includes('localhost') || location.hostname.includes('127.0.0.1') || location.hostname.includes('0.0.0.0');
  }

  async mounted() {
    try {
      let loggedUser = null;
      if (this.$route.query.username != null && this.$route.query.password != null && this.isLocalhost()) {
        // @ts-ignore
        loggedUser = await this.dataProvider.login(this.$route.query.username, this.$route.query.password);
      } else {
        loggedUser = await this.dataProvider.login();
      }
      
      this.$store.dispatch('onLoginSuccessful', loggedUser);
      
      if (loggedUser.needsToCompleteOnboarding) {
        const step = loggedUser.sex == null ? 4 : 5;
        const url = `/onboarding?step=${step}`;
        // console.log('User needs to complete onboarding, redirected to', url);
        this.$router.replace(url);
        return;
      }
    } catch (e) {
      // console.log(e);
      if (e.status == 404) {
        // console.log('User not found, starting onboarding');
        this.$router.replace('/onboarding');
        return;
      } else if (e.status == 401) {
        this.showLoginErrorComponent = true;
        return;
      }

      GlobalEventBus.$emit('alert', {
        text: 'Login fallita; riprova tra qualche secondo. Se il problema persiste, probabilmente stai usando un link errato',
        error: e,
      });
      return;
    }

    try {
      this.gameStatus = await this.dataProvider.getGameStatus(this.$store.getters.player);

      GlobalEventBus.$on('loaded', () => {
        this.gameLoaded = true;
      });
    } catch (e) {
      // console.log(e);
      GlobalEventBus.$emit('alert', {
        text: 'Errore nel caricamento dello stato del gioco. Contattare il supporto tecnico',
        error: e,
      });
    }
  }

  onGameSetLoading(loading: boolean, loaderInfo: LoadingInfo) {
    loading ? this.startLoadingAnimation(loaderInfo) : this.endLoadingAnimation();
  }

  executeLoadingAnimation(loadingInfo: LoadingInfo, autoClose = true, duration = 1000) {
    this.startLoadingAnimation(loadingInfo);

    if (autoClose) {
      setTimeout(() => {
        this.endLoadingAnimation();
      }, duration);
    }
  }

  startLoadingAnimation(loadingInfo: LoadingInfo) {
    this.loaderInfo = loadingInfo;
    this.showLoader = true;
    // console.log("PLAY SOUND");

  }

  endLoadingAnimation() {
    this.showLoader = false;
    // @ts-ignore
    this.loaderInfo = null;
  }

  onOpenQuest(url: string) {
    this.questUrl = url;
    this.showQuest = true;
    GlobalEventBus.$emit('notificator', { polling: false });
  }

  onCloseDialog() {
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
    this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
    this.$store.dispatch('onSetHeaderStatsVisibility', true);
  }

  disposeQuest(questId: number): void {
    this.showQuest = false;
    this.gameStatus!.emitQuestCoreComplete(questId);
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
    GlobalEventBus.$emit('notificator', { polling: true });
  }

  async initQuest(questId: number): Promise<FrameQuestPayload | null> {
    const quest = this.gameStatus.getQuest(questId);
    return (quest != null) ? quest.toFrameQuestPayload() : null;
  }

  raiseAlert(error: any, methodName: string) {
    const errorToBeRaised = {
      text: `Errore in ${methodName}`,
      error,
      isDisposable: true,
    };

    if (error.response != null && error.response.status === 403) {
      errorToBeRaised.isDisposable = false;
      errorToBeRaised.text = "Questa sessione è stata interrotta dal sistema di protezione anti-cheating. Ricarica la pagina.";
      errorToBeRaised.error = "Non puoi giocare contemporaneamente su più tab di uno stesso browser oppure da browser diversi.";
    }

    GlobalEventBus.$emit('alert', errorToBeRaised);
  }

  /**
   * Viene chiamato dalle quest, iframes, ma anche da Pixi (upgrade block)
   */
  async addOrSubtractCredits(deltaCredits: number, questId?: number, block?: Block): Promise<number> {
    const payload: PlayerCreditsPayload = {
      value: deltaCredits,
    };

    if (questId) {
      payload.questId = questId;
    } else if (block) {
      payload.blockKey = block.key;
      payload.zoneKey = block.zone.uniqueKey;
    }

    try {
      const playerStats = await this.dataProvider.setPlayerCredits(payload);
      this.gameStatus.setPlayerCredits(playerStats.credits);
      this.$store.dispatch('onSetCredits', playerStats.credits);
      this.gameStatus.setPlayerExp(playerStats.experience);
      this.$store.dispatch('onSetExperience', playerStats.experience);
      return playerStats.credits;
    } catch (error) {
      this.raiseAlert(error, "salvataggio crediti utente");
      throw error;                    // devo ri-tirarlo perché deve arrivare agli iframe delle quest
    }
  }

  /**
   * Viene chiamato dalle quest, iframes
   */
  async addExp(questId: number, deltaExp: number): Promise<number> {
    const payload: PlayerExpPayload = {
      value: deltaExp,
      questId,
    }

    try {
      const playerStats = await this.dataProvider.setPlayerExperience(payload);
      this.gameStatus.setPlayerCredits(playerStats.credits);
      this.$store.dispatch('onSetCredits', playerStats.credits);
      this.gameStatus.setPlayerExp(playerStats.experience);
      this.$store.dispatch('onSetExperience', playerStats.experience);
      return playerStats.experience;
    } catch (error) {
      this.raiseAlert(error, "salvataggio esperienza utente");
      throw error;                    // devo ri-tirarlo perché deve arrivare agli iframe delle quest
    }
  }

  /**
   * Viene chiamato dalle quest, iframes
   */
  async setStars(questId: number, value: QuestStarsOutcomes): Promise<void> {
    const payload: QuestStarsPayload = {
      value,
      questId,
    }

    try {
      const playerStats = await this.dataProvider.setQuestStars(payload);
      this.gameStatus.setPlayerCredits(playerStats.credits);
      this.$store.dispatch('onSetCredits', playerStats.credits);
      this.gameStatus.setPlayerExp(playerStats.experience);
      this.$store.dispatch('onSetExperience', playerStats.experience);
      this.gameStatus.setQuestStars(questId, value);
      this.$store.dispatch('onSetStars', playerStats.stars);
    } catch (error) {
      this.raiseAlert(error, "salvataggio stelle utente");
      throw error;                    // devo ri-tirarlo perché deve arrivare agli iframe delle quest
    }
  }

  /**
   * Viene chiamato dalle quest, iframes
   */
  async setStatus(questId: number, value: QuestStatus): Promise<void> {
    const payload: QuestStatusPayload = {
      value,
      questId,
    }

    try {
      await this.dataProvider.setQuestStatus(payload);
      this.gameStatus.setQuestStatus(questId, value);
    } catch (error) {
      this.raiseAlert(error, "salvataggio stato quest");
      throw error;                    // devo ri-tirarlo perché deve arrivare agli iframe delle quest
    }
  }

  /**
   * Viene chiamato dalle quest, iframes
   */
  async setSuspendData(questId: number, value: string): Promise<void> {
    const payload: QuestSuspendDataPayload = {
      value,
      questId,
    }

    try {
      await this.dataProvider.setQuestSuspendData(payload);
      this.gameStatus.setQuestSuspendData(questId, value);
    } catch (error) {
      this.raiseAlert(error, "salvataggio dati quest");
      throw error;                    // devo ri-tirarlo perché deve arrivare agli iframe delle quest
    }
  }

  /**
   * Viene chiamato dalle quest, iframes
   */
  async getPlayer(): Promise<PlayerPayload> {
    return new Promise(resolve => {
      resolve({
        id: this.$store.getters.player.id,
        username: this.$store.getters.player.username,
        displayName: this.$store.getters.player.displayName,
        sex: this.$store.getters.player.sex,
        imageFace: this.$store.getters.player.imageFace,
        imageBody: this.$store.getters.player.imageBody,
      });
    });
  }

  /**
   * Viene chiamato da Pixi
   */
  async onUpgradeBlock(block: Block, upgradedBlockType: BlockType, deltaCredits: number): Promise<void> {
    this.isUpgradingBlockApi = true;

    const userProgress = new UserBlockProgress({
      blockKey: block.key,
      blockType: upgradedBlockType,
    });

    try {
      const payload = {
        value: deltaCredits,
        blockKey: block.key,
        zoneKey: block.zone.uniqueKey,
      }
      const playerStats = await this.dataProvider.setPlayerCredits(payload);

      // console.log('onUpgradeBlock: block.hasNeverBeenUpgraded', block.hasNeverBeenUpgraded);

      const userProgressStored = (block.hasNeverBeenUpgraded) ?
        await this.dataProvider.createUserBlockProgress(userProgress) :
        await this.dataProvider.updateUserBlockProgress(userProgress);

      this.gameStatus.upgradeBlock(block, userProgressStored.blockType);
      this.gameStatus.player.credits = playerStats.credits;
      this.$store.dispatch('onSetCredits', playerStats.credits);
      (this.$refs.gameVue as GameComponent).refreshBlockUI(block);
    } catch (error) {
      this.raiseAlert(error, "salvataggio upgrade");
      throw error;
    } finally {
      this.isUpgradingBlockApi = false;
    }
  }

  onShowCampaign() {
    this.showCampaign = true;
  }

  onHideCampaign() {
    this.showCampaign = false;
  }

  async onShowMultiplayer(campaignId?: number) {
    if (this.showMultiplayer) {
      // può capitare questo caso se sei già nel MP, apri notifiche e clicchi su notifica di una campagna
      if (campaignId != null) {
        GlobalEventBus.$emit('go-to-campaign', campaignId);
      }
      return;
    }

    if (campaignId != null && !isNaN(campaignId)) {
      const availableCampaigns = await this.dataProvider.getCampaigns();
      if (availableCampaigns.find(x => x.id === campaignId) != null) {
        this.$store.dispatch('onSetCampaignToOpen', campaignId);
      } else {
        GlobalEventBus.$emit('alert', { text: 'La campagna non è più disponibile' });
        return;
      }
    } else {
      this.executeLoadingAnimation({
        text: "Stai andando all'arena",
        scene: "",
        image: "menu",
      });
    }

    setTimeout(() => {
      this.showMultiplayer = true;
      this.$store.dispatch('onSetHeaderStatsVisibility', false);
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
    }, 500);
  }

  onShowLobbyStats() {
    this.showLobbyStats = true;
    this.showAvatar = false;
    this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
    this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
    this.$store.dispatch('onSetHeaderStatsVisibility', false);
  }

  async onShowAvatar() {
    try {
      const results = await Promise.all([
        this.dataProvider.getAvatarAssets(this.$store.getters.player.sex),
        this.dataProvider.getUserAvatar(),
      ]);

      if (results[0] == null || results[0].length === 0) {
        GlobalEventBus.$emit('alert', { text: "Ora non è possibile proseguire con la personalizzazione dell'avatar, riprova più avanti", error: new Error("Ricevuti 0 assets da server") });
        return;
      }

      this.allAvatarAssets = results[0];
      this.avatarAssets = results[1];

      GlobalEventBus.$emit('notificator', { polling: false });
      
      setTimeout(() => {
        this.showLobbyStats = false;
        this.showAvatar = true;
        this.$store.dispatch('onSetHeaderCloseButtonVisibility', false);
        this.$store.dispatch('onSetHeaderNotificationButtonVisibility', false);
        this.$store.dispatch('onSetHeaderStatsVisibility', false);
      }, 300);

      this.executeLoadingAnimation({
        text: 'Personalizzazione avatar',
        scene: "",
        image: "menu",
      });
    } catch (error) {
      GlobalEventBus.$emit('alert', { text: "Errore in lettura dati avatar", error });
      // console.error('API ERROR', error);
    }
  }

  onCloseAvatarConfigurator() {
    GlobalEventBus.$emit('notificator', { polling: true });

    setTimeout(() => {
      this.onShowLobbyStats();
      this.$store.dispatch('onSetHeaderCloseButtonVisibility', true);
      this.$store.dispatch('onSetHeaderNotificationButtonVisibility', true);
      this.$store.dispatch('onSetHeaderStatsVisibility', false);
    }, 300);

    this.executeLoadingAnimation({
      text: 'Stai tornando alla Lobby',
      scene: "",
      image: "menu",
    });
  }
}
