export class Category {
  id: number;
  name: number;
  image: number;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.image = data.image || require('@/assets/img/coppa.png');
  }
}

export class Ranking {
  rank: number;
  profileId: number;
  profileName: string;
  profileImage: string;
  profileScore: string;
  isDigitalAmbassador: boolean;

  constructor(data: any) {
    this.rank = data.rank;
    this.profileId = data.id;
    this.profileName = data.username;
    this.isDigitalAmbassador = data.isDigitalAmbassador;
    this.profileScore = data.score || 0;
    this.profileImage = data.imageFace || require('@/assets/img/new_alpha/characters/user.png');
  }
}
