








import { Component } from "vue-property-decorator";

import { GlobalEventBus, HintEventArgs } from "@/services/GlobalEventBus";
import BaseComponent from './BaseComponent';

@Component
export default class HintComponent extends BaseComponent {
  queue: string[] = [];

  get currentHint(): string {
    if (this.queue != null && this.queue.length > 0)
      return this.queue[0];

    return '';
  }

  get visible(): boolean {
    return this.queue != null && this.queue.length > 0;
  }

  created() {
    GlobalEventBus.$on("hint", async (args: HintEventArgs) => {
      // console.log("hint event:", args);
      this.queue.push(args.text);

      if (args.disposeTimeoutMs != null && args.disposeTimeoutMs > 0) {
        setTimeout(() => {
          this.dispose();
        }, args.disposeTimeoutMs);
      }
    });

    GlobalEventBus.$on("close-hint", async () => {
      this.dispose()
      // console.log("Close hint");
    });
  }

  beforeDestroy() {
    GlobalEventBus.$off('hint');
    GlobalEventBus.$off('close-hint');
  }

  dispose() {
    try {
      this.queue.splice(0, 1);
    } catch (e) {
      // console.log(e);
    }
  }
}
